import React from "react";

import { keyBy } from "lodash";
import PropTypes from "prop-types";

import { OperatorContext } from "../OperatorContext/OperatorContext";
import { sendGetPropertiesRequest } from "./api";

const INITIAL_STATE = {
  properties: {},
  isFetching: false,
  hasFetched: false,
  operatorId: null,
};

export const PropertiesContext = React.createContext({
  ...INITIAL_STATE,
  fetchProperties: () => {},
  resetProperties: () => {},
});

export class PropertiesContextProvider extends React.PureComponent {
  static contextType = OperatorContext;
  state = INITIAL_STATE;

  componentDidMount() {
    const operatorId = this.getOperatorId();
    if (operatorId) {
      this.fetchProperties();
    }
  }
  componentDidUpdate() {
    const operatorId = this.getOperatorId();
    const { isFetching, hasFetched } = this.state;

    // Return early if the component is already fetching properties or if the operator id is not set yet
    if (isFetching || !operatorId) return;

    const hasOperatorChanged = operatorId !== this.state.operatorId;
    if (!hasFetched || hasOperatorChanged) {
      this.fetchProperties();
    }
  }

  getOperatorId = () => this.context.operator._id;

  fetchProperties = async () => {
    this.setState({ isFetching: true });
    const operatorId = this.getOperatorId();
    const propertiesArray = (await sendGetPropertiesRequest(operatorId)) || [];

    const propertiesByKey = keyBy(propertiesArray, "_id");
    this.setState({
      properties: propertiesByKey,
      hasFetched: true,
      isFetching: false,
      operatorId,
    });
  };

  resetProperties = () => this.setState(INITIAL_STATE);

  render() {
    const contextValue = {
      ...this.state,
      resetProperties: this.resetProperties,
    };

    return (
      <PropertiesContext.Provider value={contextValue}>
        {this.props.children}
      </PropertiesContext.Provider>
    );
  }
}

//
// Prop Types
//

PropertiesContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
