import React from "react";

import { toNumber } from "lodash";
import PropTypes from "prop-types";
import { Container, Form, Input } from "semantic-ui-react";

import { createInputChangeEventToUpdateHandler } from "../../../helpers/forms";
import { DefaultTooltipIcon, Tooltip } from "../../../viewComponents/tooltip";

const TOOLTIP = (
  <Container text>
    The prepayment ratio is the share of the total booking price the customer
    has to transfer in order to secure the booking. Enter 0% if there is no
    prepayment to be transfered when booking your properties.
  </Container>
);

/**
 * Renders a Semantic UI Form.Field allowing the user to input the default
 * prepayment ratio on his properties. The value has to be between 0 and 100%.
 */
export const PrepaymentRatioInput = ({ value, onChange, error }) => {
  return (
    <Form.Field error={error}>
      <label>
        Prepayment Ratio{" "}
        <Tooltip tooltip={TOOLTIP}>
          <DefaultTooltipIcon />
        </Tooltip>
      </label>
      <Input
        name="prepaymentRatio"
        value={value || 0}
        label="%"
        labelPosition="right"
        onChange={createInputChangeEventToUpdateHandler(onChange)}
        type="number"
        min={0}
        max={100}
      />
    </Form.Field>
  );
};

//
// Validation
//

PrepaymentRatioInput.validate = (value) => {
  if (!value) return {};

  const valueNumber = toNumber(value);

  if (!isFinite(valueNumber))
    return { prepaymentRatio: "Value must be a number" };

  if (valueNumber < 0 || valueNumber > 100)
    return { prepaymentRatio: "PrepaymentRatio must be between 0 and 100%" };

  return {};
};

//
// Prop Types
//

PrepaymentRatioInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  error: PropTypes.string,
};
