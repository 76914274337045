import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Card, Icon, Image } from "semantic-ui-react";

import "./PropertyCard.css";

/** Renders a single Semantic UI card component representing the given property. */
export const PropertyCard = ({ property = {}, fluid }) => {
  const linkToProperty = `/myProperties/${property._id}`;
  const linkToAvailabilities = linkToProperty + "/availabilities";
  const linkToEdit = linkToProperty + "/edit";
  return (
    <Card
      className="propertyCard"
      fluid={fluid}
      link
      as={Link}
      to={linkToProperty}
    >
      <Image src={property.thumbnail} />
      <Card.Content>
        <Card.Header>{property.name}</Card.Header>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button
            className="availabilities"
            as={Link}
            to={linkToAvailabilities}
          >
            <Icon name="calendar alternate outline" />
            Availabilities
          </Button>
          <Button basic color="grey" as={Link} to={linkToEdit}>
            <Icon name="edit" />
            Edit
          </Button>
        </div>
      </Card.Content>
    </Card>
  );
};

//
// Prop Types
//

PropertyCard.propTypes = {
  property: PropTypes.shape({
    thumbnail: PropTypes.string,
  }),

  /** @type {boolean} - If set, the card will be displayed fluid. */
  fluid: PropTypes.bool,
};
