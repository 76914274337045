import PropTypes from "prop-types";

/**
 * Option object for a single button in the DropdownButton.
 *
 * @typedef {{
 *   text: string;
 *   id: string;
 *   onClick: function;
 *   icon: string;
 *   key: any;
 *   linkTo: string | any;
 *   tooltip: any;
 * }} ButtonOption
 */

export const DropdownButtonItemType = PropTypes.shape({
  text: PropTypes.string.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  key: PropTypes.any,
  linkTo: PropTypes.any,
  tooltip: PropTypes.tooltip,
});
