import React, { useContext } from "react";

import { Grid } from "semantic-ui-react";

import { useIsMobile } from "../helpers/useIsMobile";
import { ImageGallery } from "../viewComponents/ImageGallery";
import { DetailsIconRow } from "./components/DetailsIconRow";
import { PropertyAccordion } from "./components/PropertyAccordion";
import { PropertyTabMenu } from "./components/PropertyTabMenu";
import { PropertyContext } from "./PropertyContext";

export const PropertyDetailsView = () => {
  const { property } = useContext(PropertyContext);
  const isMobile = useIsMobile();

  return (
    <Grid padded>
      <Grid.Row>
        <h1>{property.name}</h1>
      </Grid.Row>
      <Grid.Row>
        <DetailsIconRow />
      </Grid.Row>
      <Grid.Row>
        <ImageGallery
          images={property.images}
          className="propertyImageGallery"
        />
      </Grid.Row>
      <Grid.Row>
        {isMobile ? <PropertyAccordion /> : <PropertyTabMenu />}
      </Grid.Row>
    </Grid>
  );
};
