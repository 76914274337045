import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import { Container, Icon, Menu } from "semantic-ui-react";

import { HelpSidebar } from "../HelpSidebar";
import { MyBusinessDropdown } from "./MyBusinessDropdown";
import { MyPropertiesDropdown } from "./MyPropertiesDropdown";
import { UserItem } from "./UserItem";

import "./DesktopMenu.css";

/**
 * This component is the entry point for the desktop menu which renders a top
 * menu bar. It contains links to different pages of the OperatorAccess.
 */
export const DesktopMenu = () => {
  const [isHelpSidebarOpen, setIsHelpSidebarOpen] = useState(false);

  const toggleHelpSidebarVisibility = () =>
    setIsHelpSidebarOpen(!isHelpSidebarOpen);

  return (
    <>
      <Menu className="desktopMenu">
        <Container>
          <Menu.Item as={NavLink} exact to="/">
            Home
          </Menu.Item>
          <MyPropertiesDropdown />
          <MyBusinessDropdown />
          <UserItem />
          <Menu.Item onClick={toggleHelpSidebarVisibility}>
            <Icon name="question circle" />
          </Menu.Item>
        </Container>
      </Menu>
      <HelpSidebar
        isVisible={isHelpSidebarOpen}
        onHide={toggleHelpSidebarVisibility}
      />
    </>
  );
};
