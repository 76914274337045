import React from "react";

import PropTypes from "prop-types";
import { Form, Input } from "semantic-ui-react";

/**
 * Renders one input row within the PaymentTermsInput component.
 *
 * @augments {React.PureComponent<State, Props>}
 */
export class PaymentTermRowView extends React.PureComponent {
  /**
   * Takes the change and forwards it to the `PaymentTermsInput` component.
   * Forwards null if the edited payment term is empty.
   */
  handleTermChange = ({ target: { name, value } }) => {
    const termBefore = this.props.paymentTerm || {};
    const newTerm = { ...termBefore, [name]: value };

    const isTermEmpty =
      newTerm.daysBeforeCheckIn === "" && newTerm.paymentRatio === "";
    if (isTermEmpty) {
      this.props.onChange(this.props.index, null);
    } else {
      this.props.onChange(this.props.index, newTerm);
    }
  };

  render() {
    const paymentTerm = this.props.paymentTerm || {};
    return (
      <Form.Group widths="5">
        <Form.Field>
          <label>Days before check in</label>
          <Input
            name="daysBeforeCheckIn"
            value={paymentTerm.daysBeforeCheckIn}
            onChange={this.handleTermChange}
            type="number"
            max={365}
            label="Days"
            labelPosition="right"
          />
        </Form.Field>

        <Form.Field>
          <label>Payment ratio</label>
          <Input
            name="paymentRatio"
            value={paymentTerm.paymentRatio}
            onChange={this.handleTermChange}
            type="number"
            min={0}
            max={100}
            label="%"
            labelPosition="right"
          />
        </Form.Field>
      </Form.Group>
    );
  }
}

PaymentTermRowView.propTypes = {
  onChange: PropTypes.func.isRequired,

  /** The index of the current term row. */
  index: PropTypes.number.isRequired,

  /** The current payment term of this row. */
  paymentTerm: PropTypes.shape({
    daysBeforeCheckIn: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    paymentRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};
