import React, { useContext } from "react";

import { PropertyContext } from "../../PropertyContext";
import { Floorplans } from "../Floorplans";
import { GeneralInformation } from "../GeneralInformation";
import { Services } from "../Services";

export const GeneralFloorplansServicesAndLocation = () => {
  const { property } = useContext(PropertyContext);
  return (
    <>
      <GeneralInformation property={property} />
      <Floorplans floorplans={property.floorplans} />
      <Services servicesIncluded={property.servicesIncluded} />
    </>
  );
};
