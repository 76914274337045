import React from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { useOperatorContext } from "../../context/OperatorContext";

/**
 * Render this view when the user has not filled in a dedicated arrivalContact
 * for the current property. It shows the operator's default arrivalContact and
 * a button the user can click to render the input form.
 */
export const ArrivalContactIsOperatorDefaultView = ({
  onSetArrivalContactForPropertyClick,
}) => {
  const { operator } = useOperatorContext();
  const { arrivalContact } = operator;

  if (!arrivalContact) return <NoOperatorDefaultContactSetView />;

  const informationArray = [];
  if (arrivalContact.lastName) {
    informationArray.push(arrivalContact.lastName);
  }

  if (arrivalContact.firstName) {
    informationArray.push(arrivalContact.firstName);
  }
  if (arrivalContact.emailAddress) {
    informationArray.push(arrivalContact.emailAddress);
  }
  if (arrivalContact.phone) {
    informationArray.push(arrivalContact.phone);
  }

  if (isEmpty(informationArray)) {
    return <NoOperatorDefaultContactSetView />;
  }

  return (
    <p>
      Arrival contact is currently set to operator-wide default:{" "}
      {informationArray.join(", ")}.
      <br />
      <span
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={onSetArrivalContactForPropertyClick}
      >
        Click here
      </span>{" "}
      to set a dedicated contact for this property.
    </p>
  );
};

const NoOperatorDefaultContactSetView = () => (
  <p>
    Please start by setting the default arrival contact{" "}
    <Link
      to="/myBusiness/settings"
      style={{ textDecoration: "underline", cursor: "pointer" }}
    >
      here
    </Link>{" "}
    under Customer Arrival.
  </p>
);

//
// Prop Types
//

ArrivalContactIsOperatorDefaultView.propTypes = {
  /** Called when the user desires to set a dedicated arrivalContact for this property. */
  onSetArrivalContactForPropertyClick: PropTypes.func.isRequired,
};
