import React from "react";

import PropTypes from "prop-types";
import WithSizes from "react-sizes";

import { DesktopMenu } from "./DesktopMenu";
import { MobileMenu } from "./MobileMenu";

const MenuContainer = ({ isMobile }) => {
  return isMobile ? <MobileMenu /> : <DesktopMenu />;
};

//
// React Sizes
//

const mapSizeToProps = ({ width }) => ({ isMobile: width < 600 });

export default WithSizes(mapSizeToProps)(MenuContainer);

//
// Prop Types
//

MenuContainer.propTypes = {
  // From React Sizes
  isMobile: PropTypes.bool.isRequired,
};
