import React from "react";

import PropTypes from "prop-types";
import { Form, Input } from "semantic-ui-react";

/**
 * Renders one input row within the CancellationTermsInput component.
 *
 * @augments {React.PureComponent<State, Props>}
 */
export class CancellationTermRowView extends React.PureComponent {
  /**
   * Takes the change and forwards it to the {CancellationTermsInput} component.
   * Forwards null if the edited cancellation term is empty.
   */
  handleTermChange = ({ target: { name, value } }) => {
    const termBefore = this.props.cancellationTerm || {};
    const newTerm = { ...termBefore, [name]: value };

    const isTermEmpty =
      newTerm.daysBeforeCheckIn === "" && newTerm.refundRatio === "";
    if (isTermEmpty) {
      this.props.onChange(this.props.index, null);
    } else {
      this.props.onChange(this.props.index, newTerm);
    }
  };

  render() {
    const cancellationTerm = this.props.cancellationTerm || {};
    return (
      <Form.Group widths="5">
        <Form.Field>
          <label>Days before check in</label>
          <Input
            name="daysBeforeCheckIn"
            value={cancellationTerm.daysBeforeCheckIn}
            onChange={this.handleTermChange}
            type="number"
            max={365}
            label="Days"
            labelPosition="right"
          />
        </Form.Field>

        <Form.Field>
          <label>Refund ratio</label>
          <Input
            name="refundRatio"
            value={cancellationTerm.refundRatio}
            onChange={this.handleTermChange}
            type="number"
            min={0}
            max={100}
            label="%"
            labelPosition="right"
          />
        </Form.Field>
      </Form.Group>
    );
  }
}

//
// Prop Types
//

CancellationTermRowView.propTypes = {
  onChange: PropTypes.func.isRequired,

  /** The index of the current term row. */
  index: PropTypes.number.isRequired,

  /** The current cancellation term of this row. */
  cancellationTerm: PropTypes.shape({
    daysBeforeCheckIn: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    refundRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};
