import React, { useState } from "react";

import { Button } from "semantic-ui-react";

import { AddCimalpesPaymentLinkModal } from "./AddCimalpesPaymentLinkModal";

export const AddCimalpesPaymentLinkButton = ({ booking, onCreated }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen((wasOpen) => !wasOpen);

  const handleCreatedLink = (object) => {
    toggleModal();
    onCreated(object);
  };

  return (
    <>
      <Button onClick={toggleModal}>Add payment link</Button>
      {isModalOpen && (
        <AddCimalpesPaymentLinkModal
          onClose={toggleModal}
          booking={booking}
          onCreated={handleCreatedLink}
        />
      )}
    </>
  );
};
