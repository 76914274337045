import React from "react";

import { get, last } from "lodash";
import { Icon } from "semantic-ui-react";

export const InterestStatusIcon = ({ interest = {}, onClick }) => {
  const negotiation = get(interest, "property.negotiation");

  const hasPendingPriceRequest =
    negotiation &&
    last(negotiation.events).type === "request" &&
    !negotiation.isClosedByOperator;

  if (hasPendingPriceRequest) {
    return <Icon name="attention" onClick={onClick} link />;
  }

  return <Icon name="circle" color="grey" onClick={onClick} link />;
};
