import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { createInputChangeEventToUpdateHandler } from "../../../helpers/forms";

export const CreateStaysInSeasonFormView = ({
  values,
  onChange,
  season = {},
  onSubmit,
}) => {
  const changeHandler = createInputChangeEventToUpdateHandler(onChange);

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Input
          label="Start Date"
          name="startDate"
          type="date"
          min={season.begin}
          value={values.startDate}
          onChange={changeHandler}
          id="start-date-input"
        />
        <Form.Input
          label="End Date"
          name="endDate"
          type="date"
          value={values.endDate}
          onChange={changeHandler}
          min={values.startDate}
          max={season.end}
          id="end-date-input"
        />
      </Form.Group>
      <p>
        <strong>Warning:</strong> Please set the start date cautiously because
        it determines the day of change in this season. So, for example, please
        choose a Saturday as a start date if your day of change is Saturday.
      </p>
      <Form.Button primary onClick={onSubmit}>
        Create Availabilities
      </Form.Button>
    </Form>
  );
};

//
// Prop Types
//

CreateStaysInSeasonFormView.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  season: PropTypes.shape({
    begin: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
};
