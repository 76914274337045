import React from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

import { leadStatus } from "../../context/MyLeadsContext";

/** Renders an icon displaying the current status of the booking. */
export const BookingStatusCell = ({ booking, onClick }) => {
  if (
    booking.property.status === leadStatus.CONFIRMED_BOOKING &&
    isEmpty(booking.property.activeChangeRequests)
  ) {
    return <Icon color="green" name="circle" />;
  }

  return (
    <Icon
      name="attention"
      onClick={() => onClick(booking)}
      style={{ cursor: "pointer" }}
    />
  );
};

//
// Prop Types
//

BookingStatusCell.propTypes = {
  booking: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
