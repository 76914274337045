import React, { useContext } from "react";

import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { Loader } from "semantic-ui-react";

import { AuthenticationContext } from "../context/AuthenticationContext";
import { userRoleTypes } from "./userRoleTypes";

const ALLOWED_USER_ROLES = [userRoleTypes.ADMIN, userRoleTypes.OPERATOR];

/**
 * Wraps a react-router `Route` component which only gets rendered when the user
 * logged in is either an admin or an operator.
 */
export const PrivateRoute = (props) => {
  const { isFetching, user } = useContext(AuthenticationContext);

  if (isFetching) {
    return <Loader active />;
  } else if (user.role && ALLOWED_USER_ROLES.includes(user.role)) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/login" />;
  }
};

//
// Prop Types
//

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  accessFor: PropTypes.array,
  accessType: PropTypes.string,
};
