import { compact } from "lodash";

/**
 * Returns a comma-separated string detailing the number of adults, children and infants.
 *
 * @example
 *   makeOccupancyString(1, 1, 1); // "1 Adult, 1 Child, 1 Infant"
 *   makeOccupancyString(2, 2); // "2 Adults, 2 Children"
 *
 * @param {number} [numAdults]
 * @param {number} [numChildren]
 * @param {number} [numInfants]
 * @returns {string}
 */
export const makeOccupancyString = (numAdults, numChildren, numInfants) => {
  const adultsString =
    numAdults && `${numAdults} Adult${numAdults > 1 ? "s" : ""}`;
  const childrenString =
    numChildren && `${numChildren} Child${numChildren > 1 ? "ren" : ""}`;
  const infantsString =
    numInfants && `${numInfants} Infant${numInfants > 1 ? "s" : ""}`;

  return compact([adultsString, childrenString, infantsString]).join(", ");
};
