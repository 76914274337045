import React from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Loader } from "semantic-ui-react";

import { OperatorContext } from "../context/OperatorContext";
import { PropertyChannelEditorView } from "./PropertyChannelEditorView";

/** @augments {React.PureComponent<State, Props>} */
export default class PropertyChannelEditorContainer extends React.PureComponent {
  static contextType = OperatorContext;

  state = { formValues: {} };

  componentDidMount() {
    this.setInitialFormValues();
  }

  componentDidUpdate() {
    if (isEmpty(this.state.formValues)) {
      this.setInitialFormValues();
    }
  }

  setInitialFormValues() {
    const { channels } = this.context;

    const channelToEdit = channels.find(
      (channel) => channel._property === this.props.propertyId
    );
    if (channelToEdit) {
      this.setState({ formValues: channelToEdit });
    }
  }

  handleFormValuesChange = (update) => {
    const formValuesBefore = this.state.formValues || {};
    const newFormValues = { ...formValuesBefore, ...update };
    this.setState({ formValues: newFormValues });
  };

  handleSubmit = () => {
    const newChannel = this.state.formValues;
    this.context.patchChannel(newChannel._id, newChannel);
  };

  render() {
    if (isEmpty(this.state.formValues)) {
      return <Loader active />;
    }
    return (
      <PropertyChannelEditorView
        values={this.state.formValues}
        onChange={this.handleFormValuesChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

//
// Prop Types
//

PropertyChannelEditorContainer.propTypes = {
  propertyId: PropTypes.string.isRequired,
};
