/**
 * Constructs stays for the given property in the given period. Does not persist anything.
 *
 * @param {string} startDate - The day to start with building stays.
 * @param {string} endDate - The day to end on or before.
 * @param {string} propertyId - Id of the property to create stays for.
 */
export const constructStays = (startDate, endDate, propertyId) => {
  const stays = [];

  let currentCheckInDate = new Date(startDate);
  let nextCheckOutDate;

  do {
    const checkIn = new Date(currentCheckInDate);
    const checkOut = new Date(checkIn);
    checkOut.setDate(checkIn.getDate() + 7);
    const available = true;
    const _property = propertyId;

    const stay = {
      checkIn,
      checkOut,
      available,
      _property,
    };
    stays.push(stay);

    nextCheckOutDate = new Date(checkOut);
    nextCheckOutDate.setDate(checkOut.getDate() + 7);
    currentCheckInDate = checkOut;
  } while (nextCheckOutDate <= new Date(endDate));

  return stays.map((stay) => ({
    ...stay,
    checkIn: stay.checkIn.toISOString(),
    checkOut: stay.checkOut.toISOString(),
  }));
};
