import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { createInputChangeEventToUpdateHandler } from "../../helpers/forms";

/** This component renders the form view of the LoginForm. */
export const LoginFormView = ({ onSubmit, onChange, values, isFetching }) => {
  const changeHandler = createInputChangeEventToUpdateHandler(onChange);

  return (
    <Form>
      <Form.Input
        name="username"
        label="Username"
        value={values.username}
        onChange={changeHandler}
      />
      <Form.Input
        name="password"
        type="password"
        label="Password"
        value={values.password}
        onChange={changeHandler}
      />
      <Form.Button
        primary
        fluid
        size="large"
        onClick={onSubmit}
        loading={isFetching}
      >
        Log In
      </Form.Button>
    </Form>
  );
};

//
// Prop Types
//

LoginFormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,

  values: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }),

  isFetching: PropTypes.bool,
};
