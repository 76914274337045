import React from "react";

import { isEmpty, toNumber } from "lodash";
import PropTypes from "prop-types";
import { Form, Input, Message } from "semantic-ui-react";

import { useSelectPropertyFromContextById } from "../../../../context/PropertiesContext";

export const StayRatesInput = ({ stay, onChange }) => {
  const propertyId = stay._property;
  const property = useSelectPropertyFromContextById(propertyId);

  if (isEmpty(property.serviceLevels)) {
    return (
      <Message error>
        Please specify service levels on this property before you continue to
        enter the prices.
      </Message>
    );
  }

  const ratesGroupChangeHandler = ({
    target: { name: serviceLevel, value },
  }) => {
    const ratesBefore = stay.rates || {};
    const newRates = { ...ratesBefore, [serviceLevel]: toNumber(value) };
    onChange({ rates: newRates });
  };

  return property.serviceLevels.map((serviceLevel) => (
    <Form.Group widths="2" key={serviceLevel}>
      <Form.Field key={serviceLevel}>
        <label>{`Price ${serviceLevel}`}</label>
        <Input
          type="number"
          label={property && property.currency}
          labelPosition="right"
          value={stay.rates && stay.rates[serviceLevel]}
          name={serviceLevel}
          onChange={ratesGroupChangeHandler}
        />
      </Form.Field>
    </Form.Group>
  ));
};

//
// Prop Types
//

StayRatesInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  stay: PropTypes.shape({
    rates: PropTypes.object,
  }),
};
