import React from "react";

import { DashboardView } from "../DashboardView";

import "./MyDashboardPage.css";

export const MyDashboardPage = () => {
  return (
    <div id="dashboardPage">
      <DashboardView />
    </div>
  );
};
