import React from "react";

import NumberFormat from "react-number-format";
import { Form, Input, Label } from "semantic-ui-react";

/**
 * @callback OnValueChange
 * @param {{ floatValue: number; value: string; formattedValue: string }} newValue
 */

/**
 * @param {object} props
 * @param {OnValueChange} [props.onValueChange]
 * @param {function} [props.onChangeEvent] Will be called with {target:
 *   {name,value}}. See `onUpdate`. Takes precedence over `onChangeEvent`
 * @param {function} [props.onUpdate] Will be called with {[name]: numberValue}.
 * @param {number} props.value
 * @param {string} props.currency The currency to display
 * @param {string} props.id Used for htmlFor tag in label
 * @param {string} props.name * @param {string} [props.error]
 * @param {string} [props.label] Semantic UI form input label
 * @param {string} [props.placeholder] Semantic UI form input placeholder
 */
export const PriceInput = ({
  currency,
  value,
  error,
  onChangeEvent,
  onUpdate,
  id,
  label,
  name,
  placeholder,
  onValueChange,
  ...restProps
}) => {
  const handleChange = ({ floatValue }) => {
    if (onUpdate) {
      onUpdate({ [name]: floatValue });
      return;
    }
    if (onChangeEvent) {
      onChangeEvent({ target: { name, value: floatValue } });
      return;
    }
  };

  return (
    <Form.Field error={!!error}>
      <label htmlFor={id}>{label}</label>
      <NumberFormat
        {...restProps}
        value={value || ""}
        name={name}
        onValueChange={onValueChange || handleChange}
        placeholder={placeholder}
        min={0}
        onFocus={(e) => e.target.select()}
        id={id}
        inputMode="decimal"
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        allowNegative={false}
        allowLeadingZeros={false}
        prefix={`${currency}  `}
        customInput={Input}
      />

      {error && <Label pointing prompt content={error} />}
    </Form.Field>
  );
};
