import React from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Dropdown } from "semantic-ui-react";

import { Tooltip } from "../tooltip";
import { DropdownMenuItem, getMenuItemKey } from "./DropdownMenuItem";
import { DropdownButtonItemType } from "./propTypes";

import "./DropdownButton.css";

/**
 * Renders a Semantic UI Button with a dropdown button on its right. When
 * clicked, a dropdown opens.
 *
 * @augments {React.PureComponent<State, Props>}
 */
export class DropdownButton extends React.PureComponent {
  render() {
    const [mainButtonOption, ...subButtonOptions] = this.props.options || [];

    const componentClassName = `dropdownButton ${this.props.className || ""}`;

    // If the main button is primary, make the dropdown button next to it primary as well
    const dropdownClassName = `button icon${
      mainButtonOption.primary ? " primary" : ""
    }`;

    return (
      <Button.Group
        negative={this.props.negative}
        positive={this.props.positive}
        className={componentClassName}
        floated={this.props.floated}
        id={this.props.id}
      >
        <MainButton
          buttonOptions={mainButtonOption}
          isSingle={isEmpty(subButtonOptions)}
        />

        {!isEmpty(subButtonOptions) && (
          <Dropdown className={dropdownClassName} floating trigger={<></>}>
            <Dropdown.Menu>
              {subButtonOptions.map((buttonOption) => (
                <DropdownMenuItem
                  itemOption={buttonOption}
                  key={getMenuItemKey(buttonOption)}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Button.Group>
    );
  }
}

/**
 * Renders the main button for a DropdownButton.
 *
 * @param {{ buttonOptions: import("./propTypes").ButtonOption }} param0
 */
const MainButton = ({ buttonOptions = {}, isSingle }) => {
  const className = isSingle ? "main single" : "main";

  // Strip properties from buttonOptions which should not be passed to the Semantic UI Button
  const uiButtonOptions = { ...buttonOptions };
  delete uiButtonOptions.linkTo;
  delete uiButtonOptions.tooltip;

  let mainButton = (
    <Button
      {...uiButtonOptions}
      content={buttonOptions.text}
      className={className}
    />
  );

  // Wrap in link if given
  if (buttonOptions.linkTo) {
    mainButton = <Link to={buttonOptions.linkTo}>{mainButton}</Link>;
  }

  // Wrap with tooltip if given
  if (buttonOptions.tooltip) {
    mainButton = (
      <Tooltip tooltip={buttonOptions.tooltip} delayShow={500}>
        {mainButton}
      </Tooltip>
    );
  }

  return mainButton;
};

//
// Prop Types
//

DropdownButton.propTypes = {
  /**
   * Options for the buttons to display. The first option will be displayed as
   * the main button.
   *
   * @type {import("./propTypes").ButtonOption[]}
   */
  options: PropTypes.arrayOf(DropdownButtonItemType),

  /** If set, the button will represent a positive action. */
  positive: PropTypes.bool,

  /** If set, the button will represent a negative action. */
  negative: PropTypes.bool,

  /** @type {"left" | "right"} */
  floated: PropTypes.string,

  className: PropTypes.string,
  id: PropTypes.string,
};

MainButton.propTypes = {
  buttonOptions: DropdownButtonItemType.isRequired,
};
