import React from "react";

import PropTypes from "prop-types";
import { Form, Header, Segment } from "semantic-ui-react";

import { useSelectPropertyFromContextById } from "../context/PropertiesContext";
import { ArrivalContactForm } from "./ArrivalContactForm";
import { ChannelCommissionForm } from "./ChannelCommissionForm";
import { SecurityDepositInput } from "./SecurityDepositInput";

import "./PropertyChannelEditor.css";

export const PropertyChannelEditorView = ({
  values = {},
  onChange,
  onSubmit,
}) => {
  const property = useSelectPropertyFromContextById(values._property);

  return (
    <Form className="propertyChannelEditor">
      <Header attached="top">Arrival Contact &mdash; {property.name}</Header>
      <Segment attached="bottom">
        <ArrivalContactForm
          values={values.arrivalContact}
          onChange={onChange}
        />
      </Segment>
      <Header attached="top">Commission Levels &mdash; {property.name}</Header>
      <Segment attached="bottom">
        <ChannelCommissionForm values={values} onChange={onChange} />
      </Segment>

      <Header attached="top">More Information &mdash; {property.name}</Header>
      <Segment attached="bottom">
        <SecurityDepositInput
          currency={property.currency}
          value={values.securityDeposit}
          onChange={onChange}
        />
      </Segment>

      <Form.Button primary onClick={onSubmit}>
        Submit
      </Form.Button>
    </Form>
  );
};

//
// Prop Types
//

PropertyChannelEditorView.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,

  /** If values are not fetched yet, show a loader or so */
  values: PropTypes.object.isRequired,
};
