import React from "react";

import PropTypes from "prop-types";

import { PropertyCard } from "./PropertyCard";

import "./PropertyGrid.css";

/** Renders the given properties in a grid view of Semantic UI cards. */
export const PropertyGridView = ({ properties = [] }) => {
  return (
    <div className="propertyGrid">
      {properties.map((p) => (
        <PropertyCard property={p} key={p._id} fluid />
      ))}
    </div>
  );
};

//
// Prop Types
//

PropertyGridView.propTypes = {
  properties: PropTypes.array,
};
