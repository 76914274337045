import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { createInputChangeEventToGroupUpdateHandler } from "../../../helpers/forms";

/** Renders a Semantic Ui form to edit the Operator's address. */
export const AddressForm = ({ values = {}, onChange, error = {} }) => {
  const groupChangeHandler = createInputChangeEventToGroupUpdateHandler(
    onChange,
    "address",
    values
  );

  return (
    <Form>
      <Form.Input
        label="Corporate Name"
        value={values.corporateName || ""}
        onChange={groupChangeHandler}
        name="corporateName"
        error={error.corporateName}
      />
      <Form.Group widths="equal">
        <Form.Input
          label="Street and Number"
          value={values.streetAndNumber || ""}
          onChange={groupChangeHandler}
          name="streetAndNumber"
          error={error.streetAndNumber}
        />
        <Form.Input
          label="City"
          value={values.city || ""}
          onChange={groupChangeHandler}
          name="city"
          error={error.city}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          label="Zip"
          value={values.zip || ""}
          onChange={groupChangeHandler}
          name="zip"
          error={error.zip}
        />
        <Form.Input
          label="Country"
          value={values.country || ""}
          onChange={groupChangeHandler}
          name="country"
          error={error.country}
        />
      </Form.Group>
    </Form>
  );
};

//
// Prop Types
//

AddressForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object,
  error: PropTypes.object,
};
