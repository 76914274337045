import React from "react";

import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { MyLeadsContext } from "../../../../context/MyLeadsContext/MyLeadsContext";
import { sendAcceptOptionRequest, sendDeclineOptionRequest } from "./api";
import { OptionResponseForm } from "./OptionResponseForm";

/**
 * Manages the OptionResponseForm where the user can accept or decline option
 * request. This component will not forward the user interactions to the parent
 * component but instead will handle the communication with the backend itself.
 *
 * @augments {React.PureComponent<State, Props>}
 */
export class OptionResponseFormContainer extends React.PureComponent {
  static contextType = MyLeadsContext;

  state = { isLoading: false };

  withLoadingAndFetchingLeads = (func) => async () => {
    this.setState({ isLoading: true });
    await func();
    this.context.fetchLeads();
    this.setState({ isLoading: false });
    this.props.onSubmit();
  };

  handleOnAcceptOptionClick = this.withLoadingAndFetchingLeads(() =>
    sendAcceptOptionRequest(this.props.option._id)
  );

  handleOnIsAlreadyBookedClick = this.withLoadingAndFetchingLeads(async () => {
    await sendDeclineOptionRequest(this.props.option._id);
    toast.info(
      "You declined the option. Please update your availabilities for this property."
    );
  });

  handleOnOtherOptionExistingClick = this.withLoadingAndFetchingLeads(() =>
    toast.info(
      "We let the option request open until the other option expired. A more advanced way to handle this case is under development",
      { autoClose: 8000 }
    )
  );

  handleOnNoOptionsIssuedClick = this.withLoadingAndFetchingLeads(() =>
    toast.info(
      "We are still developing ways to handle this case. Until then, just let the option request stay open.",
      { autoClose: 8000 }
    )
  );

  render() {
    return (
      <OptionResponseForm
        option={this.props.option}
        onAcceptOptionClick={this.handleOnAcceptOptionClick}
        onIsAlreadyBookedClick={this.handleOnIsAlreadyBookedClick}
        onNoOptionsIssuedClick={this.handleOnNoOptionsIssuedClick}
        onOtherOptionExistsClick={this.handleOnOtherOptionExistingClick}
      />
    );
  }
}

//
// Prop Types
//

OptionResponseFormContainer.propTypes = {
  option: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
