import React from "react";

export const OperatorSettingsHelp = () => (
  <>
    <h3>Operator Settings</h3>
    <p>
      On this page, you can manage every aspect that is important for your
      cooperation with Snowtrade Royale. Please fill in as much information as
      you can in order to support a clean workflow in the future.
    </p>
    <p>
      Many of the options you can set here, are called{" "}
      <span style={{ fontStyle: "italic" }}>operator-wide defaults</span>. They
      apply to all your properties as long as you do not set specific values on
      the property pages. For example, in the segment{" "}
      <span style={{ fontStyle: "italic" }}>Customer Arrival</span> you can set
      options about the arrival process of a customer. There you can fill in an
      arrival contact as an operator-wide default. Because it is a default
      value, you can set the arrival contact for each property independently. If
      you wish to, navigate to the page of the property you want to set specific
      options for.
    </p>
  </>
);
