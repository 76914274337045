import React from "react";

import PropTypes from "prop-types";

import { PropertyContext } from "./PropertyContext";
import { PropertyDetailsView } from "./PropertyDetailsView";

export const PropertyDetailsContainer = ({ property }) => {
  if (!property) return null;

  return (
    <PropertyContext.Provider value={{ property }}>
      <PropertyDetailsView />
    </PropertyContext.Provider>
  );
};

//
// Prop Types
//

PropertyDetailsContainer.propTypes = {
  property: PropTypes.object,
};
