import React from "react";

import PropType from "prop-types";
import { Button } from "semantic-ui-react";

import { DropdownButton } from "../../../../viewComponents/DropdownButton";
import { OptionPeriodAndClient } from "../OptionPeriodAndClient";

export const OptionResponseForm = ({
  option,
  onAcceptOptionClick,
  onIsAlreadyBookedClick,
  onOtherOptionExistsClick,
  onNoOptionsIssuedClick,
}) => {
  return (
    <div>
      <OptionPeriodAndClient option={option} />
      <p style={{ margin: "1em 0" }}>
        We received an option request for the property and period stated above.
        If you accept the option, we assume that no other party can book this
        property whithin the given period for the next 24 hours. We also assume
        that accepted options can be turned into valid bookings without further
        approval from your side. In the case the customer desires to actually
        book the property you will be notified.
      </p>
      <Button primary onClick={onAcceptOptionClick}>
        Accept Option
      </Button>
      <DropdownButton
        options={[
          {
            text: "Decline - Property is already booked",
            onClick: onIsAlreadyBookedClick,
          },
          {
            text: "Decline - There is another option on this property",
            onClick: onOtherOptionExistsClick,
          },
          {
            text: "Decline - Property is available, but no options are issued",
            onClick: onNoOptionsIssuedClick,
          },
        ]}
        style
      />
    </div>
  );
};

//
// Prop Types
//

OptionResponseForm.propTypes = {
  option: PropType.object.isRequired,
  onAcceptOptionClick: PropType.func.isRequired,
  onIsAlreadyBookedClick: PropType.func.isRequired,
  onOtherOptionExistsClick: PropType.func.isRequired,
  onNoOptionsIssuedClick: PropType.func.isRequired,
};
