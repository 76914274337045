import React from "react";

import PropTypes from "prop-types";
import ReactTable from "react-table";
import { Icon } from "semantic-ui-react";

import { toDateOnlyString } from "../../helpers/jsonDateConverter";
import { AvailableCell } from "./AvailableCell";

/** Renders a table displaying the given stays. */
export const MobileAvailabilitiesTable = ({
  stays = [],
  loading,
  onChange,
  onStayDetailsClick,
}) => {
  let columns = [
    {
      Header: "Check In",
      Cell: (row) => {
        return toDateOnlyString(row.original.checkIn);
      },
    },
    {
      Header: "Check Out",
      Cell: (row) => {
        return toDateOnlyString(row.original.checkOut);
      },
    },
    {
      Header: "Available?",
      Cell: (row) => <AvailableCell stay={row.original} onChange={onChange} />,
    },
    {
      Cell: (row) => (
        <Icon
          name="setting"
          style={{ cursor: "pointer" }}
          onClick={() => onStayDetailsClick(row.original)}
        />
      ),
      maxWidth: 38,
    },
  ];

  return (
    <ReactTable
      data={stays}
      columns={columns}
      showPagination={false}
      minRows={3}
      loading={loading}
      style={{ textAlign: "center", maxWidth: "600px" }}
      className="availabilities"
    />
  );
};

//
// Prop Types
//

MobileAvailabilitiesTable.propTypes = {
  /** Will be called with (<stay._id>, newStay) */
  onChange: PropTypes.func.isRequired,
  onStayDetailsClick: PropTypes.func.isRequired,
  stays: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};
