import React from "react";

import { Modal } from "semantic-ui-react";

import { CimalpesPaymentLinkCreator } from "./CimalpesPaymentLinkCreator";

export const AddCimalpesPaymentLinkModal = ({
  onClose,
  booking,
  onCreated,
}) => {
  return (
    <Modal open onClose={onClose} closeIcon>
      <Modal.Header>Add payment link</Modal.Header>
      <Modal.Content>
        <CimalpesPaymentLinkCreator booking={booking} onCreated={onCreated} />
      </Modal.Content>
    </Modal>
  );
};
