import React from "react";

import { HelpSidebar } from "../HelpSidebar";
import { MenuSidebar } from "./MenuSidebar";
import { TopMenuBar } from "./TopMenuBar";

import "./style.css";

/**
 * This component is the entry point for the navigation menu for mobile devices.
 * It renders a top menu bar and a sidebar menu which opens when the user clicks
 * on an icon in the top menu bar.
 */
export class MobileMenuContainer extends React.PureComponent {
  state = {
    isSideMenuVisible: false,
    isAnimatingSideMenu: false,
    isSideHelpVisible: false,
  };

  /**
   * Call this function when you want to initiate a change in the visibility of
   * the menu sidebar.
   */
  toggleSideMenuVisibility = () => {
    // Only accept the visibility change when there is no animation currently running.
    if (this.state.isAnimatingSideMenu) return;

    const update = {
      isSideMenuVisible: !this.state.isSideMenuVisible,
      isAnimatingSideMenu: true,
    };

    this.setState(update);
  };

  /**
   * Call this message when the sidebar completed its animation - either hiding
   * or appearing.
   */
  onSideMenuAnimationFinished = () =>
    this.setState({ isAnimatingSideMenu: false });

  toggleSideHelpVisibility = () =>
    this.setState({ isSideHelpVisible: !this.state.isSideHelpVisible });

  render() {
    return (
      <div className="mobileMenu">
        <TopMenuBar
          onSideMenuIconClick={this.toggleSideMenuVisibility}
          onHelpIconClick={this.toggleSideHelpVisibility}
        />
        <MenuSidebar
          isVisible={this.state.isSideMenuVisible}
          doHide={this.toggleSideMenuVisibility}
          onAnimationFinished={this.onSideMenuAnimationFinished}
        />
        <HelpSidebar
          isVisible={this.state.isSideHelpVisible}
          onHide={this.toggleSideHelpVisibility}
        />
      </div>
    );
  }
}
