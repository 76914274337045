import React from "react";

import PropTypes from "prop-types";
import { Button, Modal, Segment } from "semantic-ui-react";

import { CreateStaysInSeasonForm } from "./CreateStaysInSeasonForm.js/index.js";

/** @augments {React.PureComponent<State,Props} */
class NoStaysExistingViewContainer extends React.PureComponent {
  state = { showCreateStaysModal: false };

  toggleModalVisibility = () =>
    this.setState({ showCreateStaysModal: !this.state.showCreateStaysModal });

  render() {
    return (
      <Segment>
        <p>There are no availabilities yet. Do you want to create some?</p>
        <Button primary onClick={this.toggleModalVisibility}>
          Create Availabilities
        </Button>
        <Modal
          open={this.state.showCreateStaysModal}
          onClose={this.toggleModalVisibility}
        >
          <Modal.Content>
            <CreateStaysInSeasonForm
              season={this.props.season}
              onCreateNewStays={this.props.onCreateNewStays}
              propertyId={this.props.propertyId}
            />
          </Modal.Content>
        </Modal>
      </Segment>
    );
  }
}

export default NoStaysExistingViewContainer;

//
// Prop Types
//

NoStaysExistingViewContainer.propTypes = {
  /**
   * Id of the property that has no stays existing. Needed to create new stays
   * if desired by the user.
   */
  propertyId: PropTypes.string.isRequired,

  onCreateNewStays: PropTypes.func.isRequired,

  season: PropTypes.shape({
    begin: PropTypes.string,
  }),
};
