import React, { useContext } from "react";

import { flatten, compact } from "lodash";

import { AmexTabMenu } from "../../viewComponents/tab";
import { PropertyContext } from "../PropertyContext";
import { getDescriptionMarkdown } from "./DescriptionMarkdown";
import { getGeneralFloorplansServicesAndLocationTab } from "./GeneralFloorplansServicesAndLocation";
import { getSalesConditionsTab } from "./SalesConditions";

import "./PropertyTabMenu.css";

/** Renders the details of the given property in a tab menu style. */
const PropertyTabMenu = () => {
  const { property } = useContext(PropertyContext);

  // Determine the components to show in the tab menu
  let tabMenuProps = [
    getGeneralFloorplansServicesAndLocationTab(),
    getDescriptionMarkdown(property),
    getSalesConditionsTab(property),
  ];

  // flatten the tabMenuProps because some components might return an array of subComponents
  // compact the tabMenuProps because some components might return null
  tabMenuProps = compact(flatten(tabMenuProps));

  return <AmexTabMenu tabProps={tabMenuProps} id="propertyDetailsTabMenu" />;
};

export { PropertyTabMenu };
