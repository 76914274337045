import { snowtrade } from "../api/snowtrade";

export const sendLoginRequest = (userCredentials) => {
  const payload = { ...userCredentials, tokenExpiration: "never" };
  return snowtrade
    .post("/auth/local", payload)
    .then((response) => response.data);
};

export const fetchCurrentUser = () =>
  snowtrade
    .get("/api/current_user")
    .then((response) => response.data)
    .catch(() => null);

export const sendLogoutRequest = () => snowtrade.get("/api/logout");
