import React from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import { constructStays } from "./constructStays";
import { CreateStaysInSeasonFormView } from "./FormView";
import { validateCreateStaysInSeasonForm } from "./validation";

/** @augments {React.PureComponent<State, Props>} */
class CreateStaysInSeasonFormContainer extends React.PureComponent {
  state = {
    formValues: {
      startDate: "",
      endDate: "",
    },
  };

  handleChange = (update) => {
    const formValuesBefore = this.state.formValues;
    const newFormValues = { ...formValuesBefore, ...update };
    this.setState({ formValues: newFormValues });
  };

  handleSubmit = () => {
    validateCreateStaysInSeasonForm(this.state.formValues);

    const { startDate, endDate } = this.state.formValues;
    const staysToCreate = constructStays(
      startDate,
      endDate,
      this.props.propertyId
    );
    if (isEmpty(staysToCreate)) return;

    // Persist new stays
    this.props.onCreateNewStays(staysToCreate);
  };

  render() {
    return (
      <CreateStaysInSeasonFormView
        values={this.state.formValues}
        onChange={this.handleChange}
        season={this.props.season}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

//
// Redux Connect
//

export default CreateStaysInSeasonFormContainer;

//
// Prop Types
//

CreateStaysInSeasonFormContainer.propTypes = {
  /** The id of the property to create stays for. */
  propertyId: PropTypes.string.isRequired,

  onCreateNewStays: PropTypes.func.isRequired,

  season: PropTypes.shape({
    begin: PropTypes.string,
  }).isRequired,
};
