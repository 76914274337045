import React from "react";

export const DefaultView = () => (
  <>
    <h3>Help</h3>
    <p>
      In this sidebar you will find a lot of useful information about many pages
      on this website.
    </p>
    <p>
      The OperatorAccess connects your properties with our system and thus with
      our partner agencies. You can use this website to manage commission
      levels, security deposits, payment terms and much more for your
      properties. Most importantly, you can update the availabilities here in
      order to provide the best possible experience for your customers.
    </p>
  </>
);
