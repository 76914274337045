import React from "react";

import PropTypes from "prop-types";

export const ChannelCommissionIsOperatorDefaultView = ({
  commission,
  commissionB2b,
  onSetChannelCommissionClick,
}) => {
  return (
    <p>
      The commission is currently set to the operator-wide default of{" "}
      {commission}% for b2c bookings and {commissionB2b}% for b2b bookings.
      <br />
      <span
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={onSetChannelCommissionClick}
      >
        Click here
      </span>{" "}
      to set different commission levels for this property.
    </p>
  );
};

//
// Prop Types
//

ChannelCommissionIsOperatorDefaultView.propTypes = {
  /**
   * Is called when the user desires to set a commission level for this property
   * that is different from the operator standard.
   */
  onSetChannelCommissionClick: PropTypes.func.isRequired,

  commission: PropTypes.number.isRequired,
  commissionB2b: PropTypes.number,
};
