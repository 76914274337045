// @ts-check
import React from "react";

import { isEmpty } from "lodash";

import { Floorplans } from "./Floorplans";

/**
 * Creates a floorplans gallery.
 *
 * @param {Object} property
 */
export const getFloorplans = (property) => {
  const { floorplans } = property;
  if (!floorplans || isEmpty(floorplans)) {
    return null;
  }

  return {
    title: "Floorplans",
    content: <Floorplans floorplans={floorplans} />,
    key: "floorplansTab",
  };
};
