export const mapPaymentFormHtmlToObject = (html) => {
  const result = {};
  keys.forEach((name) => {
    result[name] = extractValueFromHtmlString(html, name);
  });

  const formAction = /action="(.*)"/.exec(html)[1];
  result.formAction = formAction;

  return result;
};

const extractValueFromHtmlString = (html, name) => {
  const regex = new RegExp(`${name}"\\s*value="(.*)"`, "gm");

  return regex.exec(html)[1];
};

const keys = [
  "PBX_SITE",
  "PBX_RANG",
  "PBX_IDENTIFIANT",
  "PBX_HASH",
  "PBX_TIME",
  "PBX_DEVISE",
  "PBX_TOTAL",
  "PBX_CMD",
  "PBX_LANGUE",
  "PBX_RETOUR",
  "PBX_EFFECTUE",
  "PBX_ANNULE",
  "PBX_REFUSE",
  "PBX_ATTENTE",
  "PBX_REPONDRE_A",
  "PBX_RUF1",
  "PBX_PORTEUR",
  "PBX_ARCHIVAGE",
  "PBX_HMAC",
];
