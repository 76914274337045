import React from "react";

import PropTypes from "prop-types";
import { Icon, Modal } from "semantic-ui-react";

import { toDateOnlyString } from "../../../helpers";
import { StayForm } from "./StayForm";

export const StayModal = ({ onClose, stay, patchStay }) => {
  if (!stay) return null;

  const handleSubmitStayForm = async (stayId, newStay) => {
    await patchStay(stayId, newStay, true);
    onClose();
  };

  return (
    <Modal open closeIcon onClose={onClose}>
      <Modal.Header>
        {toDateOnlyString(stay.checkIn)}{" "}
        <Icon name="arrow right" size="small" />{" "}
        {toDateOnlyString(stay.checkOut)}
      </Modal.Header>
      <Modal.Content>
        <StayForm stay={stay} onSubmit={handleSubmitStayForm} />
      </Modal.Content>
    </Modal>
  );
};

//
// Prop Types
//

StayModal.propTypes = {
  patchStay: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  stay: PropTypes.object,
};
