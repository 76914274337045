import React from "react";

import PropTypes from "prop-types";
import { Icon, Menu } from "semantic-ui-react";

/** Renders the Semantic UI Menu managed by the `MobileMenuContainer`. */
export const TopMenuBar = ({ onSideMenuIconClick, onHelpIconClick }) => (
  <Menu className="topMenuBar">
    <Menu.Item position="left" onClick={onSideMenuIconClick}>
      <Icon name="sidebar" size="large" />
    </Menu.Item>
    <Menu.Item position="right" onClick={onHelpIconClick}>
      <Icon name="question circle" />
    </Menu.Item>
  </Menu>
);

//
// Prop Types
//

TopMenuBar.propTypes = {
  /**
   * Called when the user desires to open the side menu showing more links than
   * the top bar.
   */
  onSideMenuIconClick: PropTypes.func.isRequired,

  /** Called when the user desires to see the help sidebar. */
  onHelpIconClick: PropTypes.func.isRequired,
};
