import React from "react";

import { Link, NavLink } from "react-router-dom";
import { Dropdown, Icon } from "semantic-ui-react";

import { useHasOpenEnquiries } from "../hooks";

import "./MyBusinessDropdown.css";

export const MyBusinessDropdown = () => {
  const hasOpenEnquiries = useHasOpenEnquiries();

  return (
    <NavLink className="ui dropdown item simple myBusiness" to="/myBusiness">
      My Business{" "}
      {hasOpenEnquiries && (
        <Icon name="circle" color="yellow" fitted size="small" />
      )}
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/myBusiness/dashboard">
          My Enquiries
          {hasOpenEnquiries && (
            <Icon name="circle" color="yellow" size="small" />
          )}
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/myBusiness/settings">
          Settings
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/myBusiness/myAccount">
          My Account
        </Dropdown.Item>
      </Dropdown.Menu>
    </NavLink>
  );
};
