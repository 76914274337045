import React, { useState } from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import "./Tab.css";

/** @todo Document me! */
const Tab = ({ tabProps = [], className = "", id }) => {
  const defaultActive = (tabProps[0] && tabProps[0].key) || 0;
  const [activeKey, setActive] = useState(defaultActive);
  if (isEmpty(tabProps)) return null;

  const onTitleClick = (index) => {
    const keyOfClickedSection = tabProps[index].key || index;
    setActive(keyOfClickedSection);
  };

  return (
    <div className={"tabMenuContainer " + className} id={id}>
      <div className="ui top attached tabular menu">
        {tabProps.map((section, index) => {
          const key = section.key || index;
          const isActive = activeKey === key ? "active" : "";
          return (
            <div
              key={key}
              className={`item ${isActive} ${section.className || ""}`}
              onClick={() => {
                onTitleClick(index);
              }}
            >
              {section.title}
            </div>
          );
        })}
      </div>

      {tabProps.map((section, index) => {
        const key = section.key || index;
        const isActive = activeKey === key ? "active" : "";
        const className = `ui bottom attached tab ${isActive} segment ${
          section.className || ""
        }`;
        return (
          <div key={key} className={className} id={section.id}>
            <div>{section.content}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Tab;

//
// Prop Types
//

const SectionType = PropTypes.shape({
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  key: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
});

Tab.propTypes = {
  tabProps: PropTypes.arrayOf(SectionType),
  className: PropTypes.string,
  id: PropTypes.string,
};
