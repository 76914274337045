import React from "react";

import { Route, Switch } from "react-router";

import { MyDashboardPage } from "./MyDashboardPage";

export const DashboardSwitch = () => {
  return (
    <Switch>
      <Route component={MyDashboardPage} />
    </Switch>
  );
};
