import React from "react";

import PropTypes from "prop-types";
import { Radio } from "semantic-ui-react";

/**
 * Cell for the ReactTable to display in the availability column. Renders a
 * RadioToggle element which enables the user to the the availability of the given stay.
 */
export const AvailableCell = ({ stay = {}, onChange }) => {
  const onAvailableChange = (e, { checked }) => {
    const stayBefore = stay || {};
    const newStay = { ...stayBefore, available: checked };
    onChange(stay._id, newStay);
  };

  return (
    <Radio
      toggle
      onChange={onAvailableChange}
      checked={stay.available}
      disabled={stay.isPatching}
    />
  );
};

//
// Prop Types
//

AvailableCell.propTypes = {
  /** Will be called with (<stay._id>, newStay) */
  onChange: PropTypes.func.isRequired,
  stay: PropTypes.object,
};
