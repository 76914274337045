import React from "react";

import PropTypes from "prop-types";
import MarkdownEditor from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

export const TermsAndConditionsForm = ({ value, onChange }) => {
  const handleChange = (newText) => onChange({ termsAndConditions: newText });

  return (
    <>
      <p>
        Please enter your terms and conditions here. By using our service you
        agree that the structured values (i.e. non-text) you set on this website
        take precedence over your terms and conditions. For example, if your
        terms and conditions requires the full booking balance to be paid not
        later than 60 days before check in, but you fill in 30 days into the
        payment terms forms on this website, then the 30 days will take
        precedence.
      </p>
      <p>
        The editor supports (but not requires) a format called Markdown. Click
        on the editor&apos;s question mark to see more information.
      </p>
      <p>
        If your terms and conditions are not visible, please click on the
        editor.
      </p>
      <MarkdownEditor
        onChange={handleChange}
        value={value || ""}
        options={{ spellChecker: false }}
      />
    </>
  );
};

//
// Prop Types
//

TermsAndConditionsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};
