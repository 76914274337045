import React from "react";

import PropTypes from "prop-types";

import { MyLeadsContext } from "../../../../context/MyLeadsContext/MyLeadsContext";
import {
  sendAcceptBookingRequestRequest,
  sendDeclineBookingRequestRequest,
} from "./api";
import { RequestedBookingResponseForm } from "./RequestedBookingResponseForm";

/**
 * This container is displayed when the user wants to act on a requested booking
 * by either accepting or declining it. When the user clicked on one of the
 * buttons, the request is sent to the backend. Then, the context refetches the
 * operator's leads and the provided onSubmit function is called.
 *
 * @augments {React.PureComponent<State, Props>}
 */
export class RequestedBookingResponseContainer extends React.PureComponent {
  static contextType = MyLeadsContext;

  state = { isLoading: false, validation: {} };

  withLoadingAndFetchingLeads = (func) => async () => {
    this.setState({ isLoading: true });
    await func();
    this.context.fetchLeads();
    this.setState({ isLoading: false });
    this.props.onSubmit();
  };

  handleOnAcceptBookingClick = this.withLoadingAndFetchingLeads(() => {
    sendAcceptBookingRequestRequest(this.props.booking._id);
  });

  handleOnDeclineBookingClick = this.withLoadingAndFetchingLeads(() =>
    sendDeclineBookingRequestRequest(this.props.booking._id)
  );

  render() {
    return (
      <RequestedBookingResponseForm
        onAcceptBookingClick={this.handleOnAcceptBookingClick}
        onDeclineBookingClick={this.handleOnDeclineBookingClick}
        loading={this.state.isLoading}
      />
    );
  }
}

//
// Prop Types
//

RequestedBookingResponseContainer.propTypes = {
  /** Called after the operator has decided on how to respond to the booking request. */
  onSubmit: PropTypes.func.isRequired,
  booking: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
};
