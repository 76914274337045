// @ts-check

import { DateTime } from "luxon";

/**
 * @typedef {Object} Season
 * @property {"summer" | "winter"} type
 * @property {string} begin - The begin date of the season in yyyy-mm-dd format
 * @property {string} end - The exclusive end date of the season in yyyy-mm-dd
 *   format === First day of next season!!
 * @property {string} name - The name of the season like `${type} 2022`
 */

/** Summer season starts in May. January is month zero. */
const START_MONTH_OF_SUMMER_SEASON = 4;

/** Summer season starts in November. January is month zero. */
const START_MONTH_OF_WINTER_SEASON = 10;

const SEASON_LENGTH_IN_MONTHS = 6;

/**
 * Determines the name of the given season.
 *
 * @param {string} seasonBegin - The begin of the season
 * @param {"winter" | "summer"} seasonType
 * @returns {string}
 */
const getSeasonName = (seasonBegin, seasonType) => {
  const seasonBeginYear = new Date(seasonBegin).getFullYear();

  if (seasonType === "summer") {
    return `Summer ${seasonBeginYear}`;
  }

  const seasonEndYear = seasonBeginYear + 1;
  return `Winter ${seasonBeginYear}/${seasonEndYear}`;
};

/**
 * Returns information about the current season.
 *
 * @returns {Season}
 */
const getCurrentSeason = () => {
  const currentMonth = DateTime.now().month;

  const isSummerSeason =
    currentMonth >= START_MONTH_OF_SUMMER_SEASON &&
    currentMonth < START_MONTH_OF_WINTER_SEASON;

  let beginDate = DateTime.now().toUTC().startOf("month");
  const month = isSummerSeason
    ? START_MONTH_OF_SUMMER_SEASON
    : START_MONTH_OF_WINTER_SEASON;
  beginDate = beginDate.set({ month });

  // If current date is already in the new year of the season, reset the year too
  if (currentMonth < START_MONTH_OF_SUMMER_SEASON) {
    beginDate = beginDate.minus({ years: 1 });
  }

  // Determine end date of season
  const endDate = beginDate.plus({ month: SEASON_LENGTH_IN_MONTHS });

  const type = isSummerSeason ? "summer" : "winter";
  const begin = beginDate.toISODate();
  const end = endDate.toISODate();
  const name = getSeasonName(begin, type);

  return {
    type,
    begin,
    end,
    name,
  };
};

const defaultOptions = {
  length: 3,
  winterOnly: false,
  summerOnly: false,
};

/**
 * Returns information about possible rental seasons.
 *
 * @returns {Season[]}
 */
export const getRentalSeasons = (options = defaultOptions) => {
  const mergedOptions = { ...defaultOptions, ...options };
  const currentSeason = getCurrentSeason();

  const rentalSeasons = [currentSeason];

  // Add option.length numbers of seasons
  for (let i = 1; i < mergedOptions.length; i++) {
    const seasonBefore = rentalSeasons[i - 1];

    // Define the type of the current season
    let isSummerSeason = seasonBefore.type === "winter";
    if (mergedOptions.summerOnly) {
      isSummerSeason = true;
    } else if (mergedOptions.winterOnly) {
      isSummerSeason = false;
    }

    // Determine the begin date of the current season
    const seasonLength =
      mergedOptions.winterOnly || mergedOptions.summerOnly
        ? 12
        : SEASON_LENGTH_IN_MONTHS;
    const beginDate = DateTime.fromISO(seasonBefore.begin).plus({
      months: seasonLength,
    });

    // Const determine the end of the current season
    const endDate = beginDate.plus({ months: SEASON_LENGTH_IN_MONTHS });

    const seasonBeginString = beginDate.toISODate();
    const seasonEndString = endDate.toISODate();
    const seasonTypeString = isSummerSeason ? "summer" : "winter";
    const seasonName = getSeasonName(seasonBeginString, seasonTypeString);

    // Build season object and push it to the result array
    rentalSeasons.push({
      type: seasonTypeString,
      begin: seasonBeginString,
      end: seasonEndString,
      name: seasonName,
    });
  }
  return rentalSeasons;
};
