import React from "react";

import { Form } from "semantic-ui-react";

import { useOperatorContext } from "../../../../context/OperatorContext/useOperatorContext";
import { PaymentMethod } from "../../../../types/PaymentMethods";
import { AcceptedPaymentMethodsDropdown } from "./AcceptedPaymentMethodsDropdown";
import { PreferredCreditCardTransferDropdown } from "./PreferredCreditCardTransferDropdown";

export const PaymentMethodsFormGroup = ({ values, onChange }) => {
  const isPreferredCreditCardTransferEnabled =
    useIsPreferredCreditCardTransferEnabled(values.acceptedPaymentMethods);

  return (
    <Form.Group widths="equal">
      <AcceptedPaymentMethodsDropdown
        acceptedPaymentMethods={values.acceptedPaymentMethods}
        onChange={onChange}
      />

      <PreferredCreditCardTransferDropdown
        disabled={!isPreferredCreditCardTransferEnabled}
        onChange={onChange}
        preferredCreditCardTransfer={values.preferredCreditCardTransfer}
      />
    </Form.Group>
  );
};

const useIsPreferredCreditCardTransferEnabled = (
  acceptedPaymentMethods = []
) => {
  const { operator } = useOperatorContext();

  const doesOperatorAcceptCreditCard = acceptedPaymentMethods.includes(
    PaymentMethod.CREDIT_CARD
  );
  const isCimalpes = operator.name && operator.name.match(/cimalpes/i);
  const isPreferredCreditCardTransferEnabled =
    doesOperatorAcceptCreditCard && !isCimalpes;
  return isPreferredCreditCardTransferEnabled;
};
