import React, { Suspense } from "react";

import PropTypes from "prop-types";

const LazyImport = React.lazy(() => import("react-markdown"));

/**
 * Wraps react-markdown. Pass the markdown to render as the single child to
 * this component.
 */
export const LazyMarkdown = ({ fallback = "Loading...", ...props }) => (
  <Suspense fallback={fallback}>
    <LazyImport {...props} />{" "}
  </Suspense>
);

//
// Prop Types
//

LazyMarkdown.propTypes = {
  children: PropTypes.string.isRequired,

  /**
   * This Markdown Component is lazy loading. With setting this prop you can
   * define what is displayed until the component is lazy loaded.
   */
  fallback: PropTypes.node,
};
