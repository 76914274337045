import React from "react";

import { LazyMarkdown } from "../../viewComponents/Markdown";

/**
 * Renders the description markdown of the given property.
 *
 * @param {Object} property - The property to render the description of.
 * @returns {{ title; content }}
 */
export const getDescriptionMarkdown = (property) => {
  const { descriptionMarkdown } = property;
  if (!descriptionMarkdown || descriptionMarkdown.trim().length === 0)
    return null;

  return {
    title: "Property Description",
    content: <LazyMarkdown>{descriptionMarkdown}</LazyMarkdown>,
    key: "description",
  };
};
