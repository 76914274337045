import React from "react";

import PropTypes from "prop-types";
import { Select } from "semantic-ui-react";

import { filterStaysInSeason } from "./filterStaysInSeason";
import { getRentalSeasons } from "./getRentalSeasons";

// Determine the rental seasons to choose from
const seasonOptions = getRentalSeasons().map((season) => ({
  key: season.name,
  text: season.name,
  value: season,
}));

/**
 * Semantic UI Select input to choose from multiple seasons. The possible
 * seasons are determined internally and consist of the next three summer or
 * winter seasons.
 */
export const SeasonSelector = ({ name, onChange, value }) => {
  const handleSeasonChange = (e, { value }) => onChange({ [name]: value });

  return (
    <Select
      onChange={handleSeasonChange}
      name={name}
      options={seasonOptions}
      value={value}
    />
  );
};

/**
 * The current season. You can use this information to manage your default form
 * state with this.
 */
SeasonSelector.currentSeason = seasonOptions[0].value;

SeasonSelector.filterStaysInSeason = filterStaysInSeason;

//
// Prop Types
//

SeasonSelector.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
};
