import React from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { PropertiesContext } from "../../context/PropertiesContext";
import { DesktopAvailabilitiesTable } from "./DesktopAvailabilitiesTableView";

/**
 * Renders a table displaying the given stays. In contrast to
 * `MobileAvailabilitiesTable`, this component does not save changes directly
 * but instead offers a submit button which triggers the PATCH request to the
 * backend. Moreover, this component tries to display all information within the
 * table while the `MobileAvailabilitiesTable` uses Modals to display the
 * content that does not fit into the table itself.
 *
 * @augments {React.PureComponent<State, Props>}
 */
class DesktopAvailabilitiesTableContainer extends React.PureComponent {
  static contextType = PropertiesContext;

  constructor(props) {
    super(props);

    this.state = {
      stays: props.stays,
      isPatching: false,
    };
  }

  componentDidUpdate(prevProps) {
    // Update internal stays if they were loading before
    if (isEmpty(prevProps.stays) && !isEmpty(this.props.stays)) {
      this.setState({ stays: this.props.stays });
    }
  }

  handleChange = (stayId, newStay) => {
    const staysBefore = this.state.stays || [];
    let newStays = [...staysBefore];

    const indexOfStayToEdit = newStays.findIndex((stay) => stay._id === stayId);

    newStays.splice(indexOfStayToEdit, 1, newStay);
    this.setState({ stays: newStays });
  };

  /**
   * Call this function when the user wants to submit his changes. It will
   * determine which stays have changed and will forward only those to the patch
   * function provided by the parent component.
   */
  handleSubmit = async () => {
    // Find edited stays
    const editedStays = this.state.stays.filter((localStay) => {
      // remoteStay has same id as localStay
      const remoteStay = this.props.stays.find(
        (stay) => stay._id === localStay._id
      );
      if (remoteStay && remoteStay !== localStay) {
        return true;
      }
    });

    this.setState({ isPatching: true });
    await Promise.all(
      editedStays.map((editedStay) =>
        this.props.patchStay(editedStay._id, editedStay)
      )
    );
    this.setState({ isPatching: false });
    toast.success("Saved successfully");
  };

  render() {
    const propertyId = (this.state.stays[0] || {})._property;
    const property = this.context.properties[propertyId];
    const serviceLevels = property && property.serviceLevels;

    const onDeleteStays = () => this.props.deleteStays(this.state.stays);

    return (
      <DesktopAvailabilitiesTable
        onChange={this.handleChange}
        loading={this.props.loading}
        stays={this.state.stays}
        onSubmit={this.handleSubmit}
        isPatching={this.state.isPatching}
        serviceLevels={serviceLevels}
        onDeleteStays={onDeleteStays}
      />
    );
  }
}

export default DesktopAvailabilitiesTableContainer;

//
// Prop Types
//

DesktopAvailabilitiesTableContainer.propTypes = {
  deleteStays: PropTypes.func.isRequired,
  patchStay: PropTypes.func.isRequired,
  stays: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};
