import React, { useState } from "react";

import { toast } from "react-toastify";

import { snowtrade } from "../../../../api";
import { CimalpesPaymentLinkForm } from "./CimalpesPaymentLinkForm";
import { mapPaymentFormHtmlToObject } from "./mapPaymentFormHtmlToObject";

export const CimalpesPaymentLinkCreator = ({ booking, onCreated }) => {
  const [isPosting, setIsPosting] = useState(false);

  const handleSubmit = (formState) => {
    setIsPosting(true);

    let paymentFormObject;
    try {
      paymentFormObject = mapPaymentFormHtmlToObject(formState.paymentFormHtml);
    } catch (error) {
      toast.error("We could not parse the form html... sorry");
      setIsPosting(false);
      return;
    }

    const paymentLink = {
      ...paymentFormObject,
      displayName: formState.name,
      amount: formState.amount,
      currency: formState.currency,
      propertyLeadId: booking._id,
    };

    snowtrade
      .post(
        `/api/sales/leads/${booking._id}/cimalpes-payment-links`,
        paymentLink
      )
      .then((response) => {
        toast.success("Saved payment link");
        onCreated(response.data);
      });
  };

  return (
    <CimalpesPaymentLinkForm onSubmit={handleSubmit} loading={isPosting} />
  );
};
