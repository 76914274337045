import _ from "lodash";

export const jsonDateConverter = (dateString) => {
  const ISOdate = new Date(dateString);
  const day = ISOdate.getDay();
  const date = ISOdate.getDate();
  const month = ISOdate.getMonth();
  const year = ISOdate.getFullYear();

  const weekdayArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dateArray = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
  ];
  const monthDay = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return {
    day: weekdayArray[day],
    date: _.toString(date < 10 ? dateArray[date] : date),
    month: monthDay[month],
    year,
  };
};

/**
 * @param {string} dateString
 * @returns {string}
 */
export const toDateOnlyString = (date) => {
  const intlFormat = new Intl.DateTimeFormat(undefined, { timeZone: "UTC" });

  try {
    return intlFormat.format(new Date(date));
  } catch (error) {
    date || "";
  }
};
