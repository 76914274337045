import React from "react";

import PropTypes from "prop-types";

import "./List.css";

/**
 * Renders an unordered list of the given items.
 *
 * @param {ListPropTypes} props
 */
const List = ({ listItems, className = "" }) => {
  const finalClassName = `customList ${className}`;
  return (
    <ul className={finalClassName}>
      {listItems && listItems.map((item, index) => <li key={index}>{item}</li>)}
    </ul>
  );
};

//
// Prop Types
//

/**
 * @typedef {object} ListPropTypes
 * @property {string[]} listItems
 * @property {string} [className=""] Default is `""`
 */

List.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

//
// Exports
//

export default List;
