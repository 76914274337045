import React from "react";

import {
  upperFirst,
  isNil,
  flattenDeep,
  compact,
  isEmpty,
  floor,
} from "lodash";
import PropTypes from "prop-types";

import { List } from "../../../viewComponents/list";
import { InformationSegment } from "../InformationSegment";

import "./GeneralInformation.css";

class GeneralInformation extends React.PureComponent {
  /** Renders the basic information of the property as a simple list. */
  renderInformation = () => {
    const {
      property: {
        propertyType,
        bedrooms,
        beds,
        coordinates,
        distances,
        petsAllowed,
      },
    } = this.props;

    let informationArray = [
      propertyType ? upperFirst(propertyType) : null,
      bedrooms ? `${bedrooms} Bedrooms` : null,
      beds.adults ? `${beds.adults} Adults` : null,
      beds.children ? `${beds.children} Children` : null,
      petsAllowed ? "Pets allowed" : null,
    ];
    if (!coordinates && !isNil(distances)) {
      const distanceItems = distances.map((distance) => {
        return distance;
      });
      informationArray = [...informationArray, ...distanceItems];
    }
    const informationArrayCleaned = flattenDeep(compact(informationArray));
    return <List listItems={informationArrayCleaned} />;
  };

  /** Renders facilities of the property as a simple list. */
  renderFacilities = () => {
    const { facilities } = this.props.property;
    const facilitiesArray = facilities.map((facility) => facility.facility);
    return <List listItems={facilitiesArray} />;
  };

  renderEquipment = () => {
    const { equipmentIncluded } = this.props.property;
    if (isEmpty(equipmentIncluded)) return null;

    const equipmentArray = equipmentIncluded.map((e) => e.equipment);

    // Shows two columns of equipment if there are more than 8
    let equipmentsLists = [equipmentArray];
    if (equipmentArray.length > 8) {
      const cut = floor(equipmentArray.length / 2) + 1;
      equipmentsLists = [
        equipmentArray.slice(0, cut),
        equipmentArray.slice(cut),
      ];
    }
    return (
      <div className="equipmentContainer">
        {equipmentsLists.map((list) => (
          <List key={list[0]} listItems={list} />
        ))}
      </div>
    );
  };

  render() {
    return (
      <>
        <h3>General</h3>
        <div className="generalInformation container">
          <InformationSegment className="information" title="Information">
            {this.renderInformation()}
          </InformationSegment>
          {!isEmpty(this.props.property.facilities) && (
            <InformationSegment className="facilities" title="Facilities">
              {this.renderFacilities()}
            </InformationSegment>
          )}

          {!isEmpty(this.props.property.equipmentIncluded) && (
            <InformationSegment title="Equipment">
              {this.renderEquipment()}
            </InformationSegment>
          )}
        </div>
      </>
    );
  }
}

export { GeneralInformation };

//
// Prop Types
//

GeneralInformation.propTypes = {
  property: PropTypes.object.isRequired,
};
