/* eslint-disable react/prop-types */
import React from "react";

import { Form, Input } from "semantic-ui-react";

import { createInputChangeEventToUpdateHandler } from "../helpers/forms";

export const SecurityDepositInput = ({ value, onChange, currency }) => {
  return (
    <Form.Field>
      <label htmlFor="security-deposit-input">Security deposit</label>
      <Input
        id="security-deposit-input"
        label={currency || "No currency!"}
        value={value || ""}
        name="securityDeposit"
        labelPosition="right"
        onChange={createInputChangeEventToUpdateHandler(onChange)}
        type="number"
      />
    </Form.Field>
  );
};
