import React from "react";

import { Switch } from "react-router";

import { PrivateRoute } from "../authentication/PrivateRoute";
import { DashboardSwitch } from "../dashboard/pages/routes";
import { LandingPage } from "../pages/LandingPage";
import { MyAccountPage } from "../pages/MyAccountPage";
import { MyBusinessPage } from "../pages/MyBusinessPage";
import { MyBusinessSettingsPage } from "../pages/MyBusinessSettingsPage";
import { MyPropertiesPage } from "../pages/MyPropertiesPage";
import { PropertyAvailabilityPage } from "../pages/PropertyAvailabilitiesPage";
import { PropertyPage } from "../pages/PropertyPage";
import { PropertySettingsPage } from "../pages/PropertySettingsPage";

/**
 * This component is the router and thus the main entry point of the
 * OperatorAccess module.
 */
export const OperatorAccessRoutes = () => {
  return (
    <Switch>
      {/* ------- Property Pages ---------------- */}
      <PrivateRoute
        path="/myProperties/:propertyId/availabilities"
        component={PropertyAvailabilityPage}
      />
      <PrivateRoute
        path="/myProperties/:propertyId/edit"
        component={PropertySettingsPage}
      />
      <PrivateRoute path="/myProperties/:propertyId" component={PropertyPage} />
      <PrivateRoute path="/myProperties" component={MyPropertiesPage} />

      {/* ------- Business Pages ----------------- */}

      <PrivateRoute
        path="/myBusiness/settings"
        component={MyBusinessSettingsPage}
      />
      <PrivateRoute path="/myBusiness/dashboard" component={DashboardSwitch} />
      <PrivateRoute path="/myBusiness/myAccount" component={MyAccountPage} />
      <PrivateRoute path="/myBusiness" component={MyBusinessPage} />

      {/* ------- Fallback Page ------------------ */}
      <PrivateRoute component={LandingPage} />
    </Switch>
  );
};
