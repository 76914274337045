import React from "react";

import { Button } from "semantic-ui-react";

export const ChangeRequestResponseForm = () => {
  return (
    <>
      <p>There are change requests</p>
      <Button primary>Accept Changes</Button>
      <Button>Decline</Button>
    </>
  );
};
