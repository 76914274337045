import React from "react";

import { Container, Header } from "semantic-ui-react";

import { OperatorEditor } from "../OperatorEditor";

import "./MyBusinessSettingsPage.css";

export const MyBusinessSettingsPage = () => {
  return (
    <Container>
      <Header>Business Settings</Header>

      <OperatorEditor />
    </Container>
  );
};
