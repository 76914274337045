import React from "react";

import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

export const RequestedBookingResponseForm = ({
  onAcceptBookingClick,
  onDeclineBookingClick,
  loading,
}) => {
  return (
    <>
      <Button primary onClick={onAcceptBookingClick} loading={loading}>
        Accept Booking
      </Button>
      <Button onClick={onDeclineBookingClick} loading={loading}>
        Decline
      </Button>
    </>
  );
};

//
// Prop Types
//

RequestedBookingResponseForm.propTypes = {
  onAcceptBookingClick: PropTypes.func.isRequired,
  onDeclineBookingClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
