import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { AmexTabMenu } from "../viewComponents/tab";
import { AddressForm } from "./forms/AddressForm";
import { BankAccountForm } from "./forms/BankAccountForm";
import { ContactForm } from "./forms/ContactForm";
import { CustomerArrivalForm } from "./forms/CustomerArrivalForm/CustomerArrivalForm";
import { GeneralForm } from "./forms/GeneralForm";
import { RentalInformationForm } from "./forms/RentalInformationForm/RentalInformationForm";
import { TermsAndConditionsForm } from "./forms/TermsAndConditionsForm";

export const OperatorEditorView = ({
  onChange,
  onSubmit,
  values = {},
  error = {},
}) => {
  const panels = [
    {
      title: "General",
      content: (
        <GeneralForm onChange={onChange} values={values} error={error} />
      ),
    },
    {
      title: "Address",
      content: (
        <AddressForm
          onChange={onChange}
          values={values.address}
          error={error.address}
        />
      ),
    },
    {
      title: "Terms and Conditions",
      content: (
        <TermsAndConditionsForm
          onChange={onChange}
          value={values.termsAndConditions}
        />
      ),
    },
    {
      title: "Rental Information",
      content: (
        <RentalInformationForm
          values={values}
          onChange={onChange}
          error={error}
        />
      ),
    },
    {
      title: "Bank Account",
      content: (
        <BankAccountForm values={values.bankAccount} onChange={onChange} />
      ),
    },
    {
      title: "Contact",
      content: (
        <ContactForm values={values} onChange={onChange} error={error} />
      ),
    },
    {
      title: "Customer Arrival",
      content: (
        <CustomerArrivalForm
          values={values}
          onChange={onChange}
          error={error}
        />
      ),
    },
  ];

  return (
    <>
      <p>Dear {values.name || "user"}</p>
      <p>
        thank you for choosing Snowtrade Royale as a partner for your channel
        management. Below, you find settings necessary for a flawless workflow
        between the customers, Snowtrade Royale, and yourself. Please fill out
        the forms cautiously and keep them updated at all times.
      </p>
      <p>
        Some of the fields below (like the Contact or Customer Arrival sections)
        can be seen as default values. You will have the opportunity to set
        those values individually for each property on other pages.
      </p>
      <AmexTabMenu tabProps={panels} />

      <Form>
        <Form.Button primary onClick={onSubmit}>
          Submit
        </Form.Button>
      </Form>
    </>
  );
};

//
// Prop Types
//

OperatorEditorView.propTypes = {
  /** Gets called with `onChange({[name]: value})` */
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
  error: PropTypes.object,
};
