import React from "react";

import PropTypes from "prop-types";

import { OperatorContext } from "../OperatorContext/OperatorContext";
import { sendGetLeadsRequest } from "./api";

const INITIAL_STATE = {
  leads: [],
  hasFetchedLeads: false,
  isFetchingLeads: false,
  operatorId: null,
};

export const MyLeadsContext = React.createContext({
  ...INITIAL_STATE,
  fetchLeads: () => {},
  resetLeads: () => {},
});

//
// ContextProvider
//

/**
 * This component provides information about the leads the logged in operator
 * can manage. It will fetch leads from the backend on start but will also
 * provide a function to fetch leads individually when needed.
 */
export class MyLeadsContextProvider extends React.PureComponent {
  static contextType = OperatorContext;

  state = INITIAL_STATE;

  componentDidMount() {
    if (this.getOperatorId()) {
      this.fetchLeads();
    }
  }

  componentDidUpdate() {
    const { hasFetchedLeads, isFetchingLeads } = this.state;
    const operatorId = this.getOperatorId();

    // Return early if the component is already fetching leads or if the operator id is not set yet
    if (isFetchingLeads || !operatorId) return;

    const hasOperatorChanged = operatorId !== this.state.operatorId;
    if (!hasFetchedLeads || hasOperatorChanged) {
      this.fetchLeads();
    }
  }

  getOperatorId = () => this.context.operator._id;

  fetchLeads = async () => {
    this.setState({ isFetchingLeads: true });
    const operatorId = this.getOperatorId();
    const leads = await sendGetLeadsRequest(operatorId);
    this.setState({
      leads,
      isFetchingLeads: false,
      hasFetchedLeads: true,
      operatorId,
    });
  };

  resetLeads = () => this.setState(INITIAL_STATE);

  render() {
    const context = {
      ...this.state,
      fetchLeads: this.fetchLeads,
      resetLeads: this.resetLeads,
    };

    return (
      <MyLeadsContext.Provider value={context}>
        {this.props.children}
      </MyLeadsContext.Provider>
    );
  }
}

//
// Prop Types
//

MyLeadsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
