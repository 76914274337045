import React from "react";

import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";

import { leadStatus } from "../../../../context/MyLeadsContext";

import "./BookingStatusView.css";

/**
 * Renders "Confirmed", "Requested", or similar as `<span>`. The text color
 * matches the status.
 */
export const BookingStatusView = ({ booking }) => {
  const bookingStatus = get(booking, "property.status");
  const activeChangeRequests = get(booking, "property.activeChangeRequests");

  if (bookingStatus === leadStatus.CONFIRMED_BOOKING) {
    if (isEmpty(activeChangeRequests)) {
      return <span className="green">Confirmed</span>;
    } else {
      return (
        <span className="yellow">Confirmed but active change requests</span>
      );
    }
  } else if (bookingStatus === leadStatus.REQUESTED_BOOKING) {
    return <span className="yellow">Requested</span>;
  } else {
    return "Unknown";
  }
};

//
// Prop Types
//

BookingStatusView.propTypes = {
  booking: PropTypes.object,
};
