import React from "react";

import PropTypes from "prop-types";

const LazyTooltip = React.lazy(() => import("./Tooltip"));

export const Tooltip = (props) => (
  <React.Suspense fallback="">
    <LazyTooltip {...props} />
  </React.Suspense>
);

export * from "./DefaultTooltipIcon";

//
// Prop Types
//

Tooltip.propTypes = {
  /**
   * The node to display in the Tooltip.
   *
   * @type {React.ReactNode}
   */
  tooltip: PropTypes.node,

  /**
   * @type {  | "auto"
   *   | "auto-start"
   *   | "auto-end"
   *   | "top"
   *   | "top-start"
   *   | "top-end"
   *   | "bottom"
   *   | "bottom-start"
   *   | "bottom-end"
   *   | "right"
   *   | "right-start"
   *   | "right-end"
   *   | "left"
   *   | "left-start"
   *   | "left-end"}
   */
  placement: PropTypes.string,

  /**
   * Determines whether to show the tooltip or not.
   *
   * @type {boolean}
   */
  active: PropTypes.bool,
};

Tooltip.defaultProps = {
  placement: "auto",
};
