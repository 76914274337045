import { leadStatus, useMyLeadsContext } from "../../context/MyLeadsContext";

/**
 * Returns those leads from the `MyLeadsContext` that are either requested or
 * confirmed options. Expired options are not returned!
 */
export const useSelectMyInterests = () => {
  const { leads } = useMyLeadsContext();
  return leads.filter((lead) => lead.property.status === leadStatus.INTEREST);
};
