/* eslint-disable react/prop-types */
import React from "react";

import { Form } from "semantic-ui-react";

import { createInputChangeEventToUpdateHandler } from "../../helpers/forms";

/**
 * @param {{
 *   onChange: (update: object) => void;
 *   values: import("../../types/Operator").Operator;
 *   error: { [field: string]: string };
 * }} param0
 */
export const GeneralForm = ({ onChange, values = {}, error = {} }) => {
  const changeHandler = createInputChangeEventToUpdateHandler(onChange);

  return (
    <Form>
      <Form.Group widths="equal" style={{ marginBottom: "2em" }}>
        <Form.Input
          label="Your company"
          value={values.name || ""}
          name="name"
          onChange={changeHandler}
          readOnly
          error={error.name}
        />
        <Form.Input
          label="VAT ID"
          value={values.vatId || ""}
          onChange={changeHandler}
          name="vatId"
          error={error.vatId}
        />
      </Form.Group>
      <InvoiceTimingInput
        value={values.invoiceTiming}
        onChange={changeHandler}
      />
      <Form.Radio
        toggle
        label="Remind me to update availabilities when I wasn't logged in for a while"
        checked={values.shouldSendDataReminders}
        onChange={(e, { checked }) =>
          onChange({ shouldSendDataReminders: checked })
        }
      />
    </Form>
  );
};

const InvoiceTimingInput = ({ value, onChange }) => {
  return (
    <Form.Select
      label="Commission Invoice Timing"
      options={options}
      placeholder="When can we invoice you?"
      value={value || ""}
      onChange={(e, { value }) =>
        onChange({ target: { name: "invoiceTiming", value } })
      }
    />
  );
};

const options = [
  { text: "With booking", value: "withBooking" },
  { text: "With check in", value: "withCheckIn" },
  { text: "With check out", value: "withCheckOut" },
];
