import React from "react";

import { Icon } from "semantic-ui-react";

export const AvailabilitiesPageHelp = () => (
  <>
    <h3>Availabilities</h3>
    <p>You navigated to the availabilities page for a single property. </p>
    <p>
      <span style={{ fontStyle: "italic" }}>On mobile devices</span> you can set
      the availability by touching the radio button in the correct row and the
      system will save the edit automatically. Click the <Icon name="setting" />{" "}
      icon to access a more detailed form.
    </p>
    <p>
      <span style={{ fontStyle: "italic" }}>On desktop devices</span> you can
      set all details directly within the table. Make sure to click on the
      submit button below the table when you are done.
    </p>
  </>
);
