import React from "react";

import { Form } from "semantic-ui-react";

const CURRENCY_OPTIONS = [
  { key: "EUR", text: "EUR", value: "EUR" },
  { key: "CHF", text: "CHF", value: "CHF" },
  { key: "USD", text: "USD", value: "USD" },
];

export const CurrencyInput = ({
  value,
  onChange,
  name = "currency",
  label = "Currency",
  id,
}) => (
  <Form.Select
    id={id}
    label={label}
    name={name}
    value={value || ""}
    options={CURRENCY_OPTIONS}
    onChange={onChange}
  />
);
