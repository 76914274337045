import React from "react";

import { Redirect } from "react-router";

import { AuthenticationContext } from "../../context/AuthenticationContext";
import { userRoleTypes } from "../userRoleTypes";
import { LoginFormView } from "./LoginFormView";

const ALLOWED_USER_ROLES = [userRoleTypes.ADMIN, userRoleTypes.OPERATOR];

/**
 * This component renders the login form for the operatorAccess. The user can
 * fill in his username and password, click on "Login", and will be redirected
 * to the landing page.
 */
class LoginFormContainer extends React.PureComponent {
  static contextType = AuthenticationContext;
  state = { formValues: { username: "", password: "" }, isFetching: false };

  handleOnLoginClick = async () => {
    this.setState({ isFetching: true });
    const user = await this.context.loginUser(this.state.formValues);

    if (user && ALLOWED_USER_ROLES.includes(user.role)) {
      this.setState({ hasLoggedIn: true, isFetching: false });
    } else {
      this.setState({ isFetching: false });
    }
  };

  handleFormChange = (update) => {
    const formValuesBefore = this.state.formValues || {};
    const newFormValues = { ...formValuesBefore, ...update };
    this.setState({ formValues: newFormValues });
  };

  render() {
    if (this.state.hasLoggedIn) {
      return <Redirect to="/" />;
    }

    return (
      <LoginFormView
        values={this.state.formValues}
        onSubmit={this.handleOnLoginClick}
        onChange={this.handleFormChange}
        isFetching={this.state.isFetching}
      />
    );
  }
}

export default LoginFormContainer;
