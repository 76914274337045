import React from "react";

export const LandinPageHelp = () => (
  <>
    <h3>Landing Page</h3>
    <p>
      Thank you for choosing Snowtrade as your partner. You are now on the
      landing page of the website. Here you can find an overview about your
      properties.
    </p>
    <p>
      Click on the property cards to see their details. By using the shortcuts
      at the bottom of the cards, you can navigate directly to the availabilites
      editor or the property editor.
    </p>
  </>
);
