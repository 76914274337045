import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { createInputChangeEventToUpdateHandler } from "../../helpers/forms";

export const ChannelCommissionFormView = ({
  values = {},
  onChange,
  onResetToOperatorDefaultsClick,
}) => {
  const changeHandler = createInputChangeEventToUpdateHandler(onChange);

  return (
    <>
      <Form.Group widths="equal">
        <Form.Input
          name="commission"
          label="B2C Commission"
          value={values.commission || ""}
          onChange={changeHandler}
          type="number"
        />
        <Form.Input
          name="commissionB2b"
          label="B2B Commission"
          value={values.commissionB2b || ""}
          type="number"
          min={values.commission}
          max={50}
          onChange={changeHandler}
        />
      </Form.Group>
      <p>
        <span
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={onResetToOperatorDefaultsClick}
        >
          Click here
        </span>{" "}
        if you want to reset the commission levels to the operator-wide default.
      </p>
    </>
  );
};

//
// Prop Types
//

ChannelCommissionFormView.propTypes = {
  onChange: PropTypes.func.isRequired,
  onResetToOperatorDefaultsClick: PropTypes.func.isRequired,
  values: PropTypes.shape({
    commission: PropTypes.number,
    commissionB2b: PropTypes.number,
  }),
};
