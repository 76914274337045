import React from "react";

import { Button, Container, Header, Icon, Grid } from "semantic-ui-react";

import { useLogoutUserAndCleanUp } from "../context/AuthenticationContext";

export const MyAccountPage = () => {
  // const { logoutUser } = useContext(AuthenticationContext);
  const handleLogout = useLogoutUserAndCleanUp();

  return (
    <Container>
      <Grid centered style={{ height: "50vh", alignContent: "center" }} padded>
        <div>
          <Header as="h1">
            <Icon name="user" />
            My Account
          </Header>
          <Button primary onClick={handleLogout}>
            Log Out
          </Button>
        </div>
      </Grid>
    </Container>
  );
};
