import React from "react";

import PropTypes from "prop-types";
import { Container } from "semantic-ui-react";

import {
  DefaultTooltipIcon,
  Tooltip,
} from "../../../../viewComponents/tooltip";
import { PaymentTermRowView } from "./PaymentTermRowView";

const TOOLTIP = (
  <Container text>
    <p>
      You can use this input to set the payment terms which each consist of a
      deadline and the ratio the customer has to transfer until this date.
    </p>
    <p>
      <span style={{ fontStyle: "italic" }}>Example:</span> Assume the customer
      has to transfer 50% of the booking total not later than 12 weeks before
      the check in and the full balance is due not later than 2 weeks before
      check in. In this case you fill in 12 * 7 = 84 days and 50% into the first
      row. Then you fill in 14 days and 100% payment ratio into the second row.
    </p>
    <p>
      Any prepayments the customer has to transfer in order to secure a booking
      are not part of this form. Please use the prepayment input for that.
    </p>
  </Container>
);

export const PaymentTermsInputView = ({
  paymentTermsToRender = [],
  onChange,
}) => {
  return (
    <>
      <h3>
        Payment terms{" "}
        <Tooltip tooltip={TOOLTIP}>
          <DefaultTooltipIcon />
        </Tooltip>
      </h3>
      {paymentTermsToRender.map((term, index) => (
        <PaymentTermRowView
          paymentTerm={term}
          index={index}
          onChange={onChange}
          key={index}
        />
      ))}
    </>
  );
};

//
// Prop Types
//

PaymentTermsInputView.propTypes = {
  onChange: PropTypes.func.isRequired,
  paymentTermsToRender: PropTypes.array.isRequired,
};
