import React from "react";

import { Link } from "react-router-dom";

export const PropertySettingsPageHelp = () => (
  <>
    <h3>Property Rental Settings</h3>
    <p>
      On this page you find detailed settings for the rental process. Fill out
      those settings that differ from the operator-wide defaults. For example,
      if the commission levels for this property are equal to the defaults you
      have set{" "}
      <Link to="/myBusiness/settings" style={{ textDecoration: "underline" }}>
        here
      </Link>
      , than you do not have to fill them in here again. However, if the arrival
      contact differs from the operator-wide default, then you can set a
      dedicated arrival contact here that only applys to this specific property.
    </p>
  </>
);
