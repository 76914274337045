/**
 * @typedef {object} Operator
 * @property {boolean} shouldSendDataReminders
 * @property {string} name
 * @property {"owner" | "operator"} type
 * @property {string} vatId
 * @property {string} currency
 * @property {string} termsAndConditions
 * @property {number} commission
 * @property {number} commissionB2b
 * @property {number} creditCardFeeRatio
 * @property {number} prepaymentRatio
 * @property {string[]} acceptedPaymentMethods
 * @property {CreditCardTransfer} preferredCreditCardTransfer
 * @property {import("./PersonalContact").PersonalContact} arrivalContact
 * @property {import("./PersonalContact").PersonalContact} reservationContact
 * @property {import("./PersonalContact").PersonalContact} accountingContact
 * @property {import("./PersonalContact").PersonalContact} conciergeContact
 */

/**
 * @typedef {| "operatorReceivesCcDataByEmail"
 *   | "operatorEntersLinkOnEachBooking"
 *   | "operatorSendsLinkByEmail"} CreditCardTransfer
 */

export const CreditCardTransfer = {
  OPERATOR_RECEIVES_CC_DATA_BY_EMAIL: "operatorReceivesCcDataByEmail",
  OPERATOR_ENTERS_LINK_ON_EACH_BOOKING: "operatorEntersLinkOnEachBooking",
  OPERATOR_SENDS_LINK_BY_EMAIL: "operatorSendsLinkByEmail",
};
