// @ts-check
import React from "react";

import { toast } from "react-toastify";

/**
 * Returns true if the form state is valid. Issues a toast and returns false if not.
 *
 * @param {{ startDate; endDate }} formValues
 * @returns {boolean}
 */
export const validateCreateStaysInSeasonForm = (formValues) => {
  const { startDate, endDate } = formValues;
  // Guard that both values have been set
  if (!startDate || !endDate) {
    toast.error(
      <>
        Please enter both the start date and the end date of the season you want
        to create.
      </>
    );
    return false;
  }

  // Guard that availabilities start on weekend
  const startDateDay = new Date(startDate).getDay();
  if (![0, 6].includes(startDateDay)) {
    toast.error(
      <>
        Please select a day on the weekend for your start day. This day will be
        the change day of your property. If you wish to set the change day to
        another day in the week, please contact us.
      </>,
      { autoClose: 10000 }
    );
    return false;
  }

  // Guard that end date is after start date
  if (new Date(startDate) > new Date(endDate)) {
    toast.error("The end date has to be later than the start date");
    return false;
  }

  return true;
};
