import React from "react";

import { get } from "lodash";
import { Button, Modal } from "semantic-ui-react";

import { OptionPeriodAndClient } from "../../MyOptionsMonitor/OptionModal/OptionPeriodAndClient";
import { NegotiationSegment } from "./NegotiationSegment";

export const InterestModal = ({ interest, onClose }) => {
  if (!interest) return null;

  return (
    <Modal open onClose={onClose}>
      <Modal.Header>
        {get(interest, "property.propertyDetails.name")} &mdash; Interest
      </Modal.Header>
      <Modal.Content>
        <OptionPeriodAndClient option={interest} />
        <p>
          One of our partners is preparing to offer this property to a client.
          You will be notified, when he requests an option or booking.
        </p>
        <NegotiationSegment interest={interest} />
        <Button primary onClick={onClose}>
          Okay
        </Button>
      </Modal.Content>
    </Modal>
  );
};
