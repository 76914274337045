import React from "react";

import { Button } from "semantic-ui-react";

import { OptionPeriodAndClient } from "../OptionPeriodAndClient";

export const ConfirmedOptionForm = ({ option, onSubmit }) => {
  return (
    <>
      <OptionPeriodAndClient option={option} />
      <p>You have confirmed this option.</p>
      <Button primary onClick={onSubmit}>
        Okay
      </Button>
    </>
  );
};
