import React, { useContext } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { Button } from "semantic-ui-react";

import { MyLeadsContext } from "../../context/MyLeadsContext/MyLeadsContext";
import { toDateOnlyString } from "../../helpers/jsonDateConverter";
import { OptionExpiresCell } from "./OptionExpiresCell";
import { OptionStatusCell } from "./OptionStatusCell";
import { useSelectMyOptions } from "./selectOptionsHook";

/** This component renders a ReactTable displaying the user's options. */
export const MyOptionsMonitorTable = ({ onEditOptionClick }) => {
  const myOptions = useSelectMyOptions();
  const { isFetchingLeads } = useContext(MyLeadsContext);

  const columns = [
    {
      Cell: (row) => (
        <OptionStatusCell option={row.original} onClick={onEditOptionClick} />
      ),
      maxWidth: 38,
    },
    {
      Header: "Check In",
      Cell: (row) => {
        return toDateOnlyString(row.original.property.period.checkInDate);
      },
    },
    {
      Header: "Check Out",
      Cell: (row) => {
        return toDateOnlyString(row.original.property.period.checkOutDate);
      },
    },
    {
      Header: "Property",
      Cell: (row) => get(row, "original.property.propertyDetails.name") || null,
    },
    {
      Header: "Expires",
      Cell: (row) => <OptionExpiresCell option={row.original} />,
    },
    {
      Cell: (row) => (
        <Button
          icon="info"
          circular
          basic
          primary
          size="tiny"
          onClick={() => onEditOptionClick(row.original)}
        />
      ),
      maxWidth: 38,
    },
  ];

  return (
    <ReactTable
      data={myOptions}
      columns={columns}
      loading={isFetchingLeads}
      showPagination={myOptions.length >= 20}
      minRows={3}
    />
  );
};

//
// Prop Types
//

MyOptionsMonitorTable.propTypes = {
  onEditOptionClick: PropTypes.func.isRequired,
};
