import React from "react";

import { Container, Header } from "semantic-ui-react";

import { DashboardView } from "../dashboard/DashboardView";

export const MyBusinessPage = () => {
  return (
    <Container>
      <Header>My Enquiries</Header>
      <DashboardView />
    </Container>
  );
};
