import React from "react";

import PropTypes from "prop-types";

import { MobileAvailabilitiesTable } from "./MobileAvailabilitiesTable";
import { StayModal } from "./StayModal";

/** @augments {React.PureComponent<State, Props>} */
class MobileAvailabilitiesTableContainer extends React.PureComponent {
  state = { stayForModal: null };

  handleOnStayDetailsClick = (stay) => this.setState({ stayForModal: stay });

  closeStayModal = () => this.setState({ stayForModal: null });

  render() {
    return (
      <>
        <MobileAvailabilitiesTable
          onChange={this.props.patchStay}
          loading={this.props.loading}
          stays={this.props.stays}
          onStayDetailsClick={this.handleOnStayDetailsClick}
        />
        <StayModal
          stay={this.state.stayForModal}
          patchStay={this.props.patchStay}
          onClose={this.closeStayModal}
        />
      </>
    );
  }
}

export default MobileAvailabilitiesTableContainer;

//
// Prop Types
//

MobileAvailabilitiesTableContainer.propTypes = {
  patchStay: PropTypes.func.isRequired,
  stays: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};
