/* eslint-disable react/prop-types */
import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { Button, Icon, Menu } from "semantic-ui-react";

import { userRoleTypes } from "../../../authentication/userRoleTypes";
import { AuthenticationContext } from "../../../context/AuthenticationContext";
import { OperatorSelectionForAdmins } from "../../OperatorSelectionForAdmins";

export const UserItem = () => {
  const { user } = useContext(AuthenticationContext);

  return (
    <Menu.Item
      as={Link}
      to="/myBusiness/myAccount"
      style={{ position: "absolute", bottom: 0 }}
    >
      <div style={{ position: "relative" }}>
        {user.role === userRoleTypes.ADMIN ? (
          <AdminItem />
        ) : (
          <NormalUserItem user={user} />
        )}
      </div>
    </Menu.Item>
  );
};

const NormalUserItem = ({ user }) => (
  <Button>
    <Icon name="user" /> {user.username}
  </Button>
);

const AdminItem = () => (
  <>
    admin as <OperatorSelectionForAdmins />
  </>
);
