import React, { useContext, useEffect, useState } from "react";

import { isEmpty } from "lodash";
import { Select } from "semantic-ui-react";

import { snowtrade } from "../api";
import { OperatorContext } from "../context/OperatorContext/OperatorContext";

const fetchOperators = () =>
  snowtrade.get("/api/property-operators").then((response) => response.data);

export const OperatorSelectionForAdmins = () => {
  const [operators, setOperators] = useState([]);
  const { adminSetsOperatorId } = useContext(OperatorContext);

  const fetchAndUpdateOperators = async () =>
    setOperators((await fetchOperators()) || []);

  useEffect(() => {
    if (!isEmpty(operators)) return;
    fetchAndUpdateOperators();
  }, [operators]);

  const options = operators.map((operator) => ({
    key: operator._id,
    value: operator._id,
    text: operator.name,
  }));

  const onChange = (e, { value }) => {
    adminSetsOperatorId(value);
    e.preventDefault();
  };

  return (
    <Select
      onClick={(e) => e.preventDefault()}
      search
      onChange={onChange}
      options={options}
    />
  );
};
