import React from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

import { leadStatus } from "../../context/MyLeadsContext";

/** Renders an icon displaying the current status of the option. */
export const OptionStatusCell = ({ option, onClick }) => {
  if (
    option.property.status === leadStatus.CONFIRMED_OPTION &&
    isEmpty(option.property.activeChangeRequests)
  ) {
    return <Icon color="green" name="circle" />;
  }

  return (
    <Icon
      name="attention"
      onClick={() => onClick(option)}
      style={{ cursor: "pointer" }}
    />
  );
};

//
// Prop Types
//

OptionStatusCell.propTypes = {
  option: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
