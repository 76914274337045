import React, { useContext } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { Button } from "semantic-ui-react";

import { MyLeadsContext } from "../../context/MyLeadsContext/MyLeadsContext";
import { toDateOnlyString } from "../../helpers/jsonDateConverter";
import { BookingStatusCell } from "./BookingStatusCell";
import { useSelectMyBookings } from "./selectBookingsHook";

/**
 * This component renders a ReactTable displaying the user's bookings.
 *
 * @requires MyLeadsContext
 */
export const MyBookingsMonitorTable = ({ onBookingClick }) => {
  const myBookings = useSelectMyBookings();
  const { isFetchingLeads } = useContext(MyLeadsContext);

  const columns = [
    {
      Cell: (row) => (
        <BookingStatusCell booking={row.original} onClick={onBookingClick} />
      ),
      maxWidth: 38,
    },
    {
      Header: "Check In",
      Cell: (row) => {
        return toDateOnlyString(row.original.property.period.checkInDate);
      },
    },
    {
      Header: "Check Out",
      Cell: (row) => {
        return toDateOnlyString(row.original.property.period.checkOutDate);
      },
    },
    {
      Header: "Property",
      Cell: (row) => get(row, "original.property.propertyDetails.name"),
    },
    {
      Header: "Service Level",
      accessor: "property.serviceLevel",
    },
    {
      Cell: (row) => (
        <Button
          icon="info"
          circular
          basic
          primary
          size="tiny"
          onClick={() => onBookingClick(row.original)}
        />
      ),
      maxWidth: 38,
    },
  ];

  return (
    <ReactTable
      data={myBookings}
      columns={columns}
      loading={isFetchingLeads}
      showPagination={myBookings.length >= 20}
      minRows={3}
    />
  );
};

//
// Prop Types
//

MyBookingsMonitorTable.propTypes = {
  onBookingClick: PropTypes.func.isRequired,
};
