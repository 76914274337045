import React, { useState } from "react";

import { Button, Modal } from "semantic-ui-react";

export const DeleteStaysButton = ({ deleteStays }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleModal = () => setIsModalOpen((wasOpen) => !wasOpen);

  const onConfirmDeletionClick = async () => {
    setIsLoading(true);
    try {
      await deleteStays();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
    setIsModalOpen(false);
  };

  return (
    <>
      <Button onClick={toggleModal}>Delete Availabilities</Button>
      <Modal centered={false} open={isModalOpen}>
        <Modal.Header>Delete Stays</Modal.Header>
        <Modal.Content>
          <p>Do you really want to delete all stays in the current season?</p>
          <Button primary onClick={onConfirmDeletionClick} loading={isLoading}>
            Confirm
          </Button>
          <Button onClick={toggleModal} loading={isLoading}>
            No, go back
          </Button>
        </Modal.Content>
      </Modal>
    </>
  );
};
