/* eslint-disable react/prop-types */
import React from "react";

import { Container, Divider, Form } from "semantic-ui-react";

import { createInputChangeEventToGroupUpdateHandler } from "../../../helpers/forms";
import { DefaultTooltipIcon, Tooltip } from "../../../viewComponents/tooltip";
import { GenericContactFormGroup } from "./GenericContactFormGroup";
import { NonReservationContactForm } from "./NonReservationContactForm";

const RESERVATION_CONTACT_TOOLTIP = (
  <Container text>
    This is the default contact we send emails to when we receive new enquiries
    from our customers. If any of your properties has a different reservation
    contact, you can set that contact on the property&apos;s page.
  </Container>
);

const ACCOUNTING_CONTACT_TOOLTIP = (
  <Container text>
    You may fill out this contact form if you want to receive accounting details
    like invoices at a different email address.
  </Container>
);

const CONCIERGE_CONTACT_TOOLTIP = (
  <Container text>
    This is the default address we send emails to when we contact the
    conciergerie. You can set dedicated concierge contacts for each property on
    the corresponding property pages.
  </Container>
);

/** Renders form fields for the default reservation contact of this operator. */
const ReservationContactForm = ({ values = {}, onChange, error = {} }) => {
  const groupChangeHandler = createInputChangeEventToGroupUpdateHandler(
    onChange,
    "reservationContact",
    values
  );

  return (
    <>
      <h3>
        Reservation Contact{" "}
        <Tooltip tooltip={RESERVATION_CONTACT_TOOLTIP}>
          <DefaultTooltipIcon />
        </Tooltip>
      </h3>
      <GenericContactFormGroup
        values={values}
        onChange={groupChangeHandler}
        error={error}
      />
    </>
  );
};

const AccountingContactForm = ({ values, onChange, error }) => (
  <NonReservationContactForm
    onChange={onChange}
    values={values}
    label="Accounting Contact"
    name="accountingContact"
    tooltip={ACCOUNTING_CONTACT_TOOLTIP}
    error={error}
  />
);

const ConciergeContactForm = ({ values, onChange, error }) => (
  <NonReservationContactForm
    onChange={onChange}
    values={values}
    label="Concierge Contact"
    name="conciergeContact"
    tooltip={CONCIERGE_CONTACT_TOOLTIP}
    error={error}
  />
);

/**
 * Renders a form where the operator can edit the following contacts:
 *
 * - ReservationContact
 * - AccountingContact
 * - Concierge Contact
 *
 * The arrivalContact should be editable in a different form dedicated for the
 * arrival of customers.
 */
export const ContactForm = ({ values = {}, onChange, error = {} }) => {
  return (
    <Form>
      <ReservationContactForm
        values={values.reservationContact}
        onChange={onChange}
        error={error.reservationContact}
      />
      <Divider section />
      <AccountingContactForm
        values={values.accountingContact}
        onChange={onChange}
        error={error.accountingContact}
      />
      <Divider section />
      <ConciergeContactForm
        values={values.conciergeContact}
        onChange={onChange}
        error={error.conciergeContact}
      />
    </Form>
  );
};
