import React from "react";

import { findLast } from "lodash";
import { Segment } from "semantic-ui-react";

import { addSeparator } from "../../../../helpers/addSeparator";

/**
 * @param {{
 *   negotiation: import("../../negotiationTypes").PropertyNegotiation;
 * }} props
 * @returns
 */
export const ClosedNegotiationSegment = ({ negotiation }) => {
  const lastOffer = findLast(
    negotiation.events,
    (event) => event.type === "offer"
  );

  let lastOfferPriceString = "";
  if (lastOffer) {
    lastOfferPriceString = ` at ${addSeparator(lastOffer.value)}`;
  }

  return (
    <Segment>You have closed the negotiation{lastOfferPriceString}.</Segment>
  );
};
