import React from "react";

import PropTypes from "prop-types";
import { Container, Divider, Form } from "semantic-ui-react";

import {
  createInputChangeEventToGroupUpdateHandler,
  createInputChangeEventToUpdateHandler,
} from "../../../helpers/forms";
import { DefaultTooltipIcon, Tooltip } from "../../../viewComponents/tooltip";

const CHANGE_DAY_OPTIONS = [
  { key: "sat", value: 6, text: "Saturday" },
  { key: "sun", value: 0, text: "Sunday" },
];

const ARRIVAL_CONTACT_TOOLTIP = (
  <Container text>
    The arrival contact is someone the customer can reach out to in order to
    plan the arrival. The contact is also useful when the customer arrives later
    than expected. This contact can be set differently on all properties if
    needed.
  </Container>
);

/**
 * This component renders a Semantic Ui Form.Group containing a field for the
 * contact's phone number and one for the email address.
 */
const ArrivalContactFormGroup = ({ values = {}, onChange, error = {} }) => {
  const groupChangeHandler = createInputChangeEventToGroupUpdateHandler(
    onChange,
    "arrivalContact",
    values
  );
  return (
    <>
      <h3>
        Arrival Contact{" "}
        <Tooltip tooltip={ARRIVAL_CONTACT_TOOLTIP}>
          <DefaultTooltipIcon />
        </Tooltip>
      </h3>
      <Form.Group widths="equal">
        <Form.Input
          label="Phone Number"
          name="phone"
          value={values.phone || ""}
          onChange={groupChangeHandler}
          error={error.phone}
        />
        <Form.Input
          label="Email Address"
          name="emailAddress"
          value={values.emailAddress || ""}
          onChange={groupChangeHandler}
          error={error.emailAddress}
        />
      </Form.Group>
    </>
  );
};

/** This renders a form for everything related to the arrival of customers */
export const CustomerArrivalForm = ({ values = {}, onChange, error = {} }) => {
  const onChangeDayChange = (e, { value }) => onChange({ changeDay: value });
  const changeHandler = createInputChangeEventToUpdateHandler(onChange);
  return (
    <Form>
      <Form.Select
        label="Day of Change"
        options={CHANGE_DAY_OPTIONS}
        value={values.changeDay}
        onChange={onChangeDayChange}
      />
      <Form.Group widths="equal">
        <Form.Input
          label="Earliest arrival"
          name="earliestCustomerArrivalOnChangeDay"
          value={values.earliestCustomerArrivalOnChangeDay || ""}
          onChange={changeHandler}
          placeholder="4pm"
        />
        <Form.Input
          label="Latest departure"
          name="latestCustomerDepartureOnChangeDay"
          value={values.latestCustomerDepartureOnChangeDay || ""}
          onChange={changeHandler}
          placeholder="10am"
        />
      </Form.Group>
      <Divider section />
      <ArrivalContactFormGroup
        values={values.arrivalContact}
        onChange={onChange}
        error={error.arrivalContact}
      />
    </Form>
  );
};

//
// Prop Types
//

CustomerArrivalForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object,
  error: PropTypes.object,
};

ArrivalContactFormGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object,
  error: PropTypes.object,
};
