export const isIPad = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (/ipad/.test(userAgent)) return true;

  // Workaround for iPad Pro
  return (
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform)
  );
};

export const isIPhone = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone/.test(userAgent);
};

// Detects if device is in standalone mode
export const isInStandaloneMode = () =>
  "standalone" in window.navigator && !!window.navigator.standalone;
