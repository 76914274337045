import React from "react";

import PropTypes from "prop-types";
import { Segment } from "semantic-ui-react";

import "./DashboardSegment.css";

/**
 * Small component rendering a Semantic UI Segment containing the given title as
 * h3 and the children provided.
 */
export const DashboardSegment = ({ title, children }) => (
  <Segment className="dashboard">
    <h3>{title}</h3>
    {children}
  </Segment>
);

//
// Prop Types
//

DashboardSegment.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
