import React from "react";

import PropTypes from "prop-types";

import { OperatorContext } from "../../context/OperatorContext";
import { ChannelCommissionFormView } from "./ChannelCommissionFormView";
import { ChannelCommissionIsOperatorDefaultView } from "./ChannelCommissionIsOperatorDefaultView";

/** @augments {React.PureComponent<State, Props>} */
export default class ChannelCommissionFormContainer extends React.PureComponent {
  static contextType = OperatorContext;

  constructor(props) {
    super(props);

    const values = props.values || {};

    const hasChannelDifferentCommissions =
      values.commission || values.commissionB2b;

    this.state = { hasChannelDifferentCommissions };
  }

  handleOnSetChannelCommissionsClick = () => {
    this.setState({ hasChannelDifferentCommissions: true });
  };

  handleOnResetToOperatorDefaultsClick = () => {
    this.props.onChange({ commission: null, commissionB2b: null });
    this.setState({ hasChannelDifferentCommissions: false });
  };

  render() {
    if (this.state.hasChannelDifferentCommissions) {
      return (
        <ChannelCommissionFormView
          values={this.props.values}
          onChange={this.props.onChange}
          onResetToOperatorDefaultsClick={
            this.handleOnResetToOperatorDefaultsClick
          }
        />
      );
    }

    const operator = this.context.operator || {};
    return (
      <ChannelCommissionIsOperatorDefaultView
        commission={operator.commission}
        commissionB2b={operator.commissionB2b}
        onSetChannelCommissionClick={this.handleOnSetChannelCommissionsClick}
      />
    );
  }
}

//
// Prop Types
//

ChannelCommissionFormContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    commission: PropTypes.number,
    commissionB2b: PropTypes.number,
  }),
};
