import { snowtrade } from "../api";

export const sendGetPropertyStaysRequest = (propertyId) =>
  snowtrade
    .get(`/api/properties/${propertyId}/stays`)
    .then((response) => response.data);

export const sendPatchStayRequest = (stayId, newStay) =>
  snowtrade
    .patch(`/api/stays/${stayId}`, newStay)
    .then((response) => response.data);

export const sendPostNewStayRequest = (newStay) =>
  snowtrade.post("/api/stays", newStay).then((response) => response.data);

export const sendDeleteStayRequest = async (stay) => {
  if (!stay._id) return;
  await snowtrade.delete(`/api/stays/${stay._id}`);
};
