import React from "react";

import ReactImageGallery from "react-image-gallery";
import "./ImageGallery.css";

/**
 * Renders an ImageGallery.
 *
 * @param images - Array of image urls.
 * @param items - Array of image urls with { original, thumbnail }.
 * @param className - Additional class name.
 */
class ImageGallery extends React.PureComponent {
  render() {
    const images = this.props.images || [];
    const items = images.map((image) => ({
      original: image.url,
      thumbnail: image.url,
    }));

    return (
      <ReactImageGallery
        items={this.props.items || items}
        additionalClass={this.props.className}
        lazyLoad={true}
        {...this.props}
      />
    );
  }
}

export { ImageGallery };
