import React from "react";

import PropTypes from "prop-types";

import { CancellationTermsInputView } from "./CancellationTermsInputView";

/** @augments {React.PureComponent<Props, State>} */
class CancellationTermsInputContainer extends React.PureComponent {
  handleChange = (index, newCancellationTerm) => {
    const cancellationTermsBefore = this.props.cancellationTerms || [];
    let newCancellationTerms = [...cancellationTermsBefore];

    newCancellationTerms[index] = newCancellationTerm;

    // Filter empty fields from cancellation terms
    newCancellationTerms = newCancellationTerms.filter(
      (term) => term && (term.daysBeforeCheckIn || term.refundRatio)
    );

    this.props.onChange({ cancellationTerms: newCancellationTerms });
  };

  render() {
    const inputCancellationTerms = this.props.cancellationTerms || [];

    const cancellationTermsToRender = [
      ...inputCancellationTerms,
      {
        daysBeforeCheckIn: "",
        refundRatio: "",
      },
    ];

    return (
      <CancellationTermsInputView
        cancellationTermsToRender={cancellationTermsToRender}
        onChange={this.handleChange}
      />
    );
  }
}

export default CancellationTermsInputContainer;

//
// Prop Types
//

CancellationTermsInputContainer.propTypes = {
  cancellationTerms: PropTypes.arrayOf(
    PropTypes.shape({
      daysBeforeCheckIn: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      refundRatio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  onChange: PropTypes.func.isRequired,
};
