import React from "react";

import PropTypes from "prop-types";

class InformationSegment extends React.PureComponent {
  render() {
    const className = this.props.className ? this.props.className : "";
    return (
      <div className={"informationSegment " + className}>
        <span className="title" style={{ fontWeight: "bolder" }}>
          {this.props.title}
        </span>

        <div
          className="column separator"
          style={{ width: "80%", maxWidth: "20em" }}
        />
        {this.props.children}
      </div>
    );
  }
}

export { InformationSegment };

// ---------------PROP TYPES----------------------------
InformationSegment.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
};
