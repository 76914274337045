import React from "react";

import PropTypes from "prop-types";
import ReactTable from "react-table";
import { Button } from "semantic-ui-react";

import { toDateOnlyString } from "../../helpers/jsonDateConverter";
import { AvailableCell } from "./AvailableCell";
import { DeleteStaysButton } from "./DeleteStaysButton";
import { RateInputCell } from "./RateInputCell";

/** Renders a table displaying the given stays. */
export class DesktopAvailabilitiesTable extends React.PureComponent {
  renderRateInputCell = (row) => {
    const { serviceLevel } = row.columnProps.rest;
    return (
      <RateInputCell
        key={row.original._id}
        stay={row.original}
        onChange={this.props.onChange}
        serviceLevel={serviceLevel}
      />
    );
  };

  renderRateColumns = () => {
    const { serviceLevels = [] } = this.props;
    return serviceLevels.map((serviceLevel) => {
      return {
        Header: `${serviceLevel} Rate`,
        Cell: this.renderRateInputCell,
        getProps: () => ({ serviceLevel }),
      };
    });
  };

  render() {
    const {
      stays = [],
      loading,
      onChange,
      onSubmit,
      onDeleteStays,
      isPatching,
    } = this.props;

    let columns = [
      {
        Header: "Check In",
        Cell: (row) => {
          return toDateOnlyString(row.original.checkIn);
        },
      },
      {
        Header: "Check Out",
        Cell: (row) => {
          return toDateOnlyString(row.original.checkOut);
        },
      },
      {
        Header: "Available?",
        Cell: (row) => (
          <AvailableCell stay={row.original} onChange={onChange} />
        ),
      },
      ...this.renderRateColumns(),
    ];

    return (
      <>
        <ReactTable
          data={stays}
          columns={columns}
          showPagination={false}
          minRows={3}
          loading={loading}
          style={{ textAlign: "center" }}
          className="availabilities"
        />
        <Button
          primary
          onClick={onSubmit}
          loading={isPatching}
          style={{ margin: "1em 0" }}
        >
          Submit
        </Button>
        <DeleteStaysButton deleteStays={onDeleteStays} />
      </>
    );
  }
}

//
// Prop Types
//

DesktopAvailabilitiesTable.propTypes = {
  /** Will be called with (<stay._id>, newStay) */
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  stays: PropTypes.array.isRequired,
  serviceLevels: PropTypes.array,
  loading: PropTypes.bool,
  isPatching: PropTypes.bool,
};
