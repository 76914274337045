import React from "react";

import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import { Tooltip } from "../tooltip";
import { DropdownButtonItemType } from "./propTypes";

import "./DropdownMenuItem.css";

/**
 * Takes a ButtonItemOption and returns a key specifing this item. To be used
 * in the DropdownButton when rendering multiple options in the dropdown below
 * the main button.
 *
 * @param {import("./propTypes").ButtonOption} itemOption
 * @returns {String}
 */
export const getMenuItemKey = (itemOption = {}) =>
  itemOption.key || itemOption.id || itemOption.text;

/**
 * Renders a single item to be used in the DropdownButton below the main button.
 *
 * @param {{ itemOption: import("./propTypes").ButtonOption }} props
 */
export const DropdownMenuItem = ({ itemOption = {} }) => {
  // Strip properties from buttonOptions which should not be passed to the Semantic UI Dropdown Item
  const uiItemOptions = { ...itemOption };
  delete uiItemOptions.linkTo;
  delete uiItemOptions.tooltip;

  let item = <Dropdown.Item {...uiItemOptions} className="option" />;

  // Wrap item with link if given
  if (itemOption.linkTo) {
    item = (
      <Link to={itemOption.linkTo} className="item">
        {item}
      </Link>
    );
  }

  // Wrap item with tooltip if given
  if (itemOption.tooltip) {
    item = (
      <Tooltip tooltip={itemOption.tooltip} triggerClass="item" delayShow={500}>
        {item}
      </Tooltip>
    );
  }

  return item;
};

//
// Prop Types
//

DropdownMenuItem.propTypes = {
  itemOption: DropdownButtonItemType,
};
