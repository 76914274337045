/**
 * Succeeds the `createInputChangeEventHandler` because this handler calls the
 * change handler with an object detailing the status updates. This can be used
 * to update more fields on one change which can be useful when, for example,
 * one change should reset multiple fields.
 *
 * @param {any} changeHandler - A change handler taking the changes as an object
 *   like `{[name]: value}`
 * @returns
 */
export const createInputChangeEventToUpdateHandler = (changeHandler) => ({
  target: { name, value },
}) => changeHandler({ [name]: value });

/**
 * Works like the `createInputChangeEventToUpdateHandler` but additionally wraps
 * the changed value in the group values before forwarding the changes to the
 * changeHandler.
 *
 * @param {any} changeHandler - A change handler function tanking the changes as
 *   an object like `{[groupName]: newGroupValue}`
 * @param {string} groupName - The name of the value group.
 * @param {Object} groupValueBeforeChange - An object representing the values of
 *   the fields in the group before the change occured.
 */
export const createInputChangeEventToGroupUpdateHandler = (
  changeHandler,
  groupName,
  groupValueBeforeChange = {}
) => ({ target: { name, value } }) => {
  const newGroupValue = { ...groupValueBeforeChange, [name]: value };
  changeHandler({ [groupName]: newGroupValue });
};
