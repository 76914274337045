import React from "react";

import { get, last } from "lodash";
import { Segment } from "semantic-ui-react";

import { addSeparator } from "../../../../helpers/addSeparator";
import { ClosedNegotiationSegment } from "./ClosedNegotiationSegment";
import { OfferInputView } from "./OfferInputView";

/**
 * If there is an ongoing negotiation on this lead, this component renders
 * inputs for the operator to respond. When no negotiation is ongoing, nothing
 * is rendered.
 */
export const NegotiationSegment = ({ interest = {} }) => {
  const negotiation = get(interest, "property.negotiation");

  if (!negotiation) {
    return null;
  }

  if (negotiation.isClosedByOperator) {
    return <ClosedNegotiationSegment negotiation={negotiation} />;
  }

  const lastEvent = last(negotiation.events);
  const isLastEventAnOffer = lastEvent.type === "offer";

  if (isLastEventAnOffer) {
    return (
      <Segment>
        Your latest offer for this lead was{" "}
        {get(interest, "property.payments.currencyCode")}{" "}
        {addSeparator(lastEvent.value)}
      </Segment>
    );
  }

  return (
    <Segment>
      <OfferInputView interest={interest} />
    </Segment>
  );
};
