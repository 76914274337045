import React, { useEffect, useState } from "react";

import { get, isEmpty } from "lodash";
import ReactTable from "react-table";
import { Button } from "semantic-ui-react";

import { useMyLeadsContext } from "../../context/MyLeadsContext";
import { toDateOnlyString } from "../../helpers";
import { InterestModal } from "./InterestModal";
import { InterestStatusIcon } from "./InterestStatusIcon";
import { useSelectMyInterests } from "./selectInterestsHook";

export const MyInterestsMonitor = () => {
  const myInterests = useSelectMyInterests();
  const { isFetchingLeads } = useMyLeadsContext();

  const [interestForModal, setInterestForModal] = useState(null);

  useEffect(() => {
    if (interestForModal && !isEmpty(myInterests)) {
      const newInterestForModal = myInterests.find(
        (interest) => interest._id === interestForModal._id
      );
      setInterestForModal(newInterestForModal);
    }
  }, [myInterests]);

  const handleInfoButtonClick = (option) => setInterestForModal(option);
  const resetInterestForModal = () => setInterestForModal(null);

  const columns = makeColumns(handleInfoButtonClick);

  return (
    <>
      <ReactTable
        data={myInterests}
        columns={columns}
        loading={isFetchingLeads}
        showPagination={myInterests.length >= 20}
        minRows={3}
      />
      <InterestModal
        interest={interestForModal}
        onClose={resetInterestForModal}
      />
    </>
  );
};

//
// Columns
//

const makeColumns = (openInterestModal) => [
  {
    Cell: (row) => (
      <InterestStatusIcon
        interest={row.original}
        onClick={() => openInterestModal(row.original)}
      />
    ),
    maxWidth: 38,
  },
  {
    Header: "Check In",
    Cell: (row) => {
      return toDateOnlyString(row.original.property.period.checkInDate);
    },
  },
  {
    Header: "Check Out",
    Cell: (row) => {
      return toDateOnlyString(row.original.property.period.checkOutDate);
    },
  },
  {
    Header: "Property",
    Cell: (row) => get(row, "original.property.propertyDetails.name"),
  },

  {
    Cell: (row) => (
      <Button
        icon="info"
        circular
        basic
        primary
        size="tiny"
        onClick={() => openInterestModal(row.original)}
      />
    ),
    maxWidth: 38,
  },
];
