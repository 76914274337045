// @ts-check
import React, { useContext } from "react";

import { isEmpty } from "lodash";

import { OperatorContext } from "../../context/OperatorContext";
import { LazyMarkdown } from "../../viewComponents/Markdown";

/** Returns title and content for the sales conditions of the given property. */
export const getSalesConditionsTab = () => {
  return {
    title: "Sales conditions",
    content: <SalesConditions />,
    key: "salesConditionsTab",
  };
};

/**
 * Returns the sales conditions for the salesConditionsTab. It tries to display
 * the terms and conditions from the property contact. If that's not possible it
 * uses defaults.
 */
const SalesConditions = () => {
  const { operator } = useContext(OperatorContext);

  if (!isEmpty(operator.termsAndConditions)) {
    return <LazyMarkdown>{operator.termsAndConditions}</LazyMarkdown>;
  } else {
    return <p>Operator has not submitted terms and conditions yet.</p>;
  }
};
