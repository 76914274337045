import React, { useState } from "react";

import { Form } from "semantic-ui-react";

import { CurrencyInput } from "../../../../viewComponents/CurrencyInput";
import { PriceInput } from "../../../../viewComponents/PriceInput";

export const CimalpesPaymentLinkForm = ({ loading, onSubmit }) => {
  const [formState, setFormState] = useState({
    name: "",
    amount: "",
    currency: "",
    paymentFormHtml: "",
  });

  const updateFormState = (update) =>
    setFormState((oldFormState) => ({ ...oldFormState, ...update }));

  const handleChangeEvent = ({ target: { name, value } }) => {
    updateFormState({ [name]: value });
  };

  const onSubmitClick = () => onSubmit(formState);

  return (
    <Form>
      <Form.Input
        id="payment-link-name-input"
        label="Name"
        placeholder="e.g. Down payment 1"
        value={formState.name}
        name="name"
        onChange={handleChangeEvent}
      />
      <Form.Group widths="equal">
        <PriceInput
          id="payment-link-amount-input"
          label="Amount"
          value={formState.amount}
          onValueChange={({ value }) => updateFormState({ amount: value })}
          currency={formState.currency}
        />
        <CurrencyInput
          id="payment-link-currency-input"
          value={formState.currency}
          onChange={(e, { value }) => updateFormState({ currency: value })}
        />
      </Form.Group>
      <Form.TextArea
        id="payment-link-html-input"
        label="Payment form html"
        rows={20}
        value={formState.paymentFormHtml}
        name="paymentFormHtml"
        onChange={handleChangeEvent}
        placeholder={paymentFormHtmlPlaceholder}
      />
      <Form.Button primary loading={loading} onClick={onSubmitClick}>
        Submit new payment link
      </Form.Button>
    </Form>
  );
};

const paymentFormHtmlPlaceholder = `
Find the payment button in the Cimalpes login area and copy the surrounding HTML code like this:

<form
    id="PostForm"
    name="PostForm"
    action="https://tpeweb.paybox.com/cgi/MYchoix_pagepaiement.cgi"
    method="POST"
    target="_top"
  >
    <input type="hidden" name="PBX_SITE" value="5840403" />
    <input type="hidden" name="PBX_RANG" value="16" />
    <input type="hidden" name="PBX_IDENTIFIANT" value="5840403" />

    [...]

    <Button primary type="submit">
      Secured payment&nbsp;›&nbsp;€3,470.08
    </Button>
  </form>


`;
