import React from "react";

import { isEmpty } from "lodash";
import ImageGallery from "react-image-gallery";

import { useIsMobile } from "../../../helpers/useIsMobile";
import "./Floorplans.css";

const Floorplans = ({ floorplans }) => {
  const isMobile = useIsMobile();
  if (isEmpty(floorplans)) {
    return null;
  }

  const items = floorplans.map((plan) => {
    return {
      original: plan.url,
      thumbnail: plan.url,
    };
  });

  const gridTemplateColumns = isMobile ? "100%" : "75%";

  return (
    <>
      <h3>Floorplans</h3>
      <div className="floorplans container" style={{ gridTemplateColumns }}>
        <ImageGallery
          items={items}
          showPlayButton={false}
          style={{ maxWidth: "800px" }}
          lazyLoad={true}
        />
      </div>
    </>
  );
};

export { Floorplans };
