import React from "react";

import PropTypes from "prop-types";
import { toast } from "react-toastify";

import {
  fetchCurrentUser,
  sendLoginRequest,
  sendLogoutRequest,
} from "../../authentication/api";

//
// Context
//

const INITIAL_STATE = {
  user: {},
  isLoggedIn: false,
  isFetching: false,
  hasFailed: false,
};

export const AuthenticationContext = React.createContext({
  ...INITIAL_STATE,
  loginUser: () => {},
  logoutUser: () => {},
});

//
// Context Provider
//

export class AuthenticationContextProvider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, isFetching: true };
  }

  componentDidMount() {
    this.loginUserFromLocalStorage();
  }

  loginUser = async (userCredentials) => {
    this.setState({ isFetching: true });

    let user;
    try {
      const responseData = await sendLoginRequest(userCredentials);
      localStorage.setItem("auth", JSON.stringify(responseData));
      user = responseData.user;
      this.setState({ user, isFetching: false, isLoggedIn: true });
    } catch (error) {
      toast.error(error);
      this.setState({ hasFailed: true, isFetching: false, isLoggedIn: false });
    }
    return user;
  };

  /**
   * Use `useLogoutUserAndCleanUp` hook for resetting other contexts rather than
   * calling this method here directly!
   */
  logoutUser = async () => {
    await sendLogoutRequest();
    this.setState(INITIAL_STATE);
    localStorage.removeItem("auth");
  };

  loginUserFromLocalStorage = async () => {
    this.setState({ isFetching: true });
    const currentUser = await fetchCurrentUser();

    if (currentUser) {
      this.setState({ user: currentUser, isFetching: false, isLoggedIn: true });
    } else {
      this.setState(INITIAL_STATE);
    }
  };

  render() {
    const contextValue = {
      ...this.state,
      loginUser: this.loginUser,
      logoutUser: this.logoutUser,
    };

    return (
      <AuthenticationContext.Provider value={contextValue}>
        {this.props.children}
      </AuthenticationContext.Provider>
    );
  }
}

//
// Prop Types
//

AuthenticationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
