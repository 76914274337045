import React from "react";

import { isArray, isEmpty } from "lodash";
import PropTypes from "prop-types";
import withSizes from "react-sizes";
import { toast } from "react-toastify";

import { PropertiesContext } from "../context/PropertiesContext/PropertiesContext.js";
import {
  sendDeleteStayRequest,
  sendGetPropertyStaysRequest,
  sendPatchStayRequest,
  sendPostNewStayRequest,
} from "./api.js";
import { DesktopAvailabilitiesTable } from "./DesktopAvailabilitiesTable";
import { MobileAvailabilitiesTable } from "./MobileAvailabilitiesTable/index.js";
import { NoStaysExistingView } from "./NoStaysExistingView";
import { SeasonSelector } from "./SeasonSelector";

/**
 * This component is the entry point for the MobileAvailabilitiesEditor in the
 * OperatorAccess module. It manages the state and communicates changes.
 *
 * TODO: Find out whether to have a combined container for desktop AND mobile editor.
 */
class EditorContainer extends React.PureComponent {
  static contextType = PropertiesContext;
  state = {
    season: SeasonSelector.currentSeason,
    propertyStays: [],
    hasFetchedStays: false,
    isFetchingStays: true,
  };

  componentDidMount() {
    this.fetchPropertyStays();
  }

  /** Fetches property stays from the backend and updates the component's state accordingly */
  fetchPropertyStays = async () => {
    this.setState({ isFetchingStays: true });
    const propertyStays = await sendGetPropertyStaysRequest(
      this.props.propertyId
    );
    this.setState({
      propertyStays,
      isFetchingStays: false,
      hasFetchedStays: true,
    });
  };

  handleSeasonChange = (update) => {
    this.setState(update);
  };

  patchStay = async (stayId, newStay, shouldShowToast) => {
    // Find the old stay in the propertyStays array
    const propertyStaysBefore = this.state.propertyStays || [];
    const stayIndex = propertyStaysBefore.findIndex((s) => s._id === stayId);
    if (stayIndex < 0) return;

    const stayBefore = propertyStaysBefore[stayIndex];

    // Construct new stay
    const stayToInsert = { ...stayBefore, ...newStay, isPatching: true };

    // Insert new stay into property stays
    let newPropertyStays = [...propertyStaysBefore];
    newPropertyStays.splice(stayIndex, 1, stayToInsert);

    this.setState({ propertyStays: newPropertyStays });

    const patchedStay = await sendPatchStayRequest(stayId, newStay);

    // Remove isPatching flag by injecting the new stay
    newPropertyStays.splice(stayIndex, 1, patchedStay);
    this.setState({ propertyStays: [...newPropertyStays] });

    if (shouldShowToast) {
      toast.success("Saved stay successfully");
    }
  };

  createNewStays = async (stays) => {
    await Promise.all(stays.map(sendPostNewStayRequest));
    this.fetchPropertyStays();
  };

  deleteStays = async (stays) => {
    await Promise.all(stays.map(sendDeleteStayRequest));
    toast.info(`We deleted ${stays.length} stays for you.`);
    this.fetchPropertyStays();
  };

  //
  // Render
  //

  render() {
    const propertyStays = this.state.propertyStays;
    const hasExistingStays = isArray(propertyStays) && !isEmpty(propertyStays);

    const staysInSeason = hasExistingStays
      ? SeasonSelector.filterStaysInSeason(this.state.season, propertyStays)
      : [];

    const { isFetchingStays } = this.state;

    const availabilitiesTable = this.props.isMobile ? (
      <MobileAvailabilitiesTable
        key={this.state.season?.begin}
        stays={staysInSeason}
        loading={isFetchingStays}
        patchStay={this.patchStay}
      />
    ) : (
      <DesktopAvailabilitiesTable
        key={this.state.season?.begin}
        loading={isFetchingStays}
        patchStay={this.patchStay}
        stays={staysInSeason}
        deleteStays={this.deleteStays}
      />
    );

    return (
      <div className="availabilitiesEditor">
        <SeasonSelector
          value={this.state.season}
          onChange={this.handleSeasonChange}
          name="season"
        />
        {!isEmpty(staysInSeason) || isFetchingStays ? (
          availabilitiesTable
        ) : (
          <NoStaysExistingView
            season={this.state.season}
            propertyId={this.props.propertyId}
            onCreateNewStays={this.createNewStays}
          />
        )}
      </div>
    );
  }
}

//
// With Sizes
//

const mapSizeToProps = ({ width }) => ({ isMobile: width < 600 });

export default withSizes(mapSizeToProps)(EditorContainer);

//
// Prop Types
//

EditorContainer.propTypes = {
  propertyId: PropTypes.string.isRequired,

  // From react-sizes
  isMobile: PropTypes.bool.isRequired,
};
