/* eslint-disable no-underscore-dangle */
import { snowtrade } from "../../api";

export const fetchPropertyOperator = async (operatorId) => {
  const url = operatorId
    ? `/api/property-operators/${operatorId}`
    : "/api/property-operators/my-operator";
  const response = await snowtrade.get(url);
  return response.data;
};

export const patchPropertyOperator = async (operatorId, newOperator) => {
  const url = `/api/property-operators/${operatorId}`;

  const reducedNewOperator = { ...newOperator };
  delete reducedNewOperator.rentalChannels;
  delete reducedNewOperator.__v;

  const response = await snowtrade.patch(url, reducedNewOperator);
  return response.data;
};

export const patchPropertyChannel = async (channelId, newChannel) => {
  const url = `/api/property-rental-channels/${channelId}`;
  const response = await snowtrade.patch(url, newChannel);
  return response.data;
};
