// @ts-check
import React from "react";

import { compact, isEmpty } from "lodash";

import { List } from "../../../viewComponents/list";
import { Tab } from "../../../viewComponents/tab";

/**
 * Creates a list of services the property has included.
 *
 * @param {Object} property - The property to list the services of.
 */
export const getServices = (property) => {
  const { servicesIncluded, serviceLevels } = property;
  if (isEmpty(servicesIncluded)) {
    return null;
  }
  let servicesIncludedProps = serviceLevels.map((level) => {
    if (servicesIncluded[level] && !isEmpty(servicesIncluded[level])) {
      const services = servicesIncluded[level].map(
        (service) => service.service
      );
      return { title: level, content: <List listItems={services} /> };
    }
    return undefined;
  });
  servicesIncludedProps = compact(servicesIncludedProps);
  const serviceTabs = <Tab tabProps={servicesIncludedProps} />;
  return {
    title: "Services",
    content: serviceTabs,
    key: "servicesTab",
  };
};
