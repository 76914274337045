import React from "react";

import { Form } from "semantic-ui-react";

import { PaymentMethod } from "../../../../types/PaymentMethods";

export const AcceptedPaymentMethodsDropdown = ({
  acceptedPaymentMethods,
  onChange,
}) => {
  const handleSelect = (e, { value }) =>
    onChange({ acceptedPaymentMethods: value });

  return (
    <Form.Select
      multiple
      label="Accepted Payment Methods"
      options={options}
      value={acceptedPaymentMethods}
      onChange={handleSelect}
    />
  );
};

const options = [
  {
    key: PaymentMethod.BANK_TRANSFER,
    value: PaymentMethod.BANK_TRANSFER,
    text: "Bank Transfer",
  },
  {
    key: PaymentMethod.CREDIT_CARD,
    value: PaymentMethod.CREDIT_CARD,
    text: "Credit Card",
  },
];
