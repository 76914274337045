import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { createInputChangeEventToGroupUpdateHandler } from "../../helpers/forms";

export const BankAccountForm = ({ onChange, values = {} }) => {
  const groupChangeHandler = createInputChangeEventToGroupUpdateHandler(
    onChange,
    "bankAccount",
    values
  );

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Input
          label="Account Name"
          value={values.accountHolderName || ""}
          name="accountHolderName"
          onChange={groupChangeHandler}
        />
        <Form.Input
          label="Account Bank"
          value={values.bankName || ""}
          name="bankName"
          onChange={groupChangeHandler}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          label="IBAN"
          value={values.iban || ""}
          name="iban"
          onChange={groupChangeHandler}
        />
        <Form.Input
          label="BIC"
          value={values.bic || ""}
          name="bic"
          onChange={groupChangeHandler}
        />
      </Form.Group>
    </Form>
  );
};

//
// Prop Types
//

BankAccountForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object,
};
