import { isEmpty, orderBy } from "lodash";

/**
 * Filters those stays that are within the season and returns them as a new array.
 *
 * @param {{ begin: string; end: string }} season
 * @param {{ checkIn; checkOut }[]} stays
 * @returns {Object[]}
 * @todo This could be memoized... but the benefit is propably not big enough.
 */
export const filterStaysInSeason = (season, stays = []) => {
  if (!season || isEmpty(stays)) return [];

  const filteredStays = stays.filter(
    (stay) =>
      new Date(stay.checkIn) >= new Date(season.begin) &&
      new Date(stay.checkIn) < new Date(season.end)
  );

  return orderBy(filteredStays, "checkIn");
};
