import React from "react";

import PropTypes from "prop-types";

/**
 * One element in the accordion. Consists of title component to be always
 * displayed in the accordion and a content container which is only visible
 * when set active. The styling is done by relying on the semantic ui css.
 */
const AccordionElement = ({ title, content, isActive, onTitleClick, id }) => {
  const titleClassName = `title ${isActive ? "active" : ""}`;
  const contentClassName = `content ${isActive ? "active" : ""}`;

  return (
    <>
      <div className={titleClassName} onClick={onTitleClick} id={id}>
        <i className="dropdown icon"></i>
        {title}
      </div>
      <div className={contentClassName}>
        <div className="transition visible">{content}</div>
      </div>
    </>
  );
};

//
// PropTypes
//

AccordionElement.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
  onTitleClick: PropTypes.func.isRequired,
  id: PropTypes.string,
};

//
// Exports
//

export default AccordionElement;
