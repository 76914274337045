import React from "react";

import { isEmpty } from "lodash";
import { Loader, Message } from "semantic-ui-react";

import { PropertiesContext } from "../context/PropertiesContext";
import { PropertyGridView } from "./PropertyGridView";

/**
 * This container wraps the PropertyGrid view and manages the access to the redux store.
 *
 * @augments {React.PureComponent<State, Props>}
 */
class PropertyGridContainer extends React.PureComponent {
  static contextType = PropertiesContext;
  state = { properties: [] };

  render() {
    if (this.context.isFetching) {
      return <Loader active inline />;
    }

    if (this.context.hasFetched && isEmpty(this.context.properties)) {
      return <Message>There are no properties connected to your user.</Message>;
    }

    const propertyArray = Object.values(this.context.properties);

    return <PropertyGridView properties={propertyArray} />;
  }
}

export default PropertyGridContainer;
