import React from "react";

import { Icon } from "semantic-ui-react";

import { toDateOnlyString } from "../../../helpers";

export const OptionPeriodAndClient = ({ option }) => {
  const checkInString = toDateOnlyString(option.property.period.checkInDate);
  const checkOutString = toDateOnlyString(option.property.period.checkOutDate);
  const period = (
    <>
      {checkInString} <Icon name="arrow right" size="small" /> {checkOutString}
    </>
  );

  const clientString =
    option.client.firstName && option.client.lastName
      ? `${option.client.lastName}, ${option.client.firstName}`
      : option.client.leadClient;

  return (
    <p>
      {" "}
      <strong>Period:</strong> {period}
      <br />
      <strong>Lead Guest:</strong> {clientString}
    </p>
  );
};
