import React from "react";

import { Container, Header } from "semantic-ui-react";

import { PropertyGrid } from "../PropertyGrid";

/** Show this component, when the user navigated to the root of the OperatorAccess module. */
export const LandingPage = () => {
  return (
    <Container>
      <Header>My Properties</Header>
      <PropertyGrid />
    </Container>
  );
};
