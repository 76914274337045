import React, { useContext } from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Container, Header, Icon } from "semantic-ui-react";

import { AvailabilitiesEditor } from "../AvailabilitiesEditor";
import { PropertiesContext } from "../context/PropertiesContext";

const BackToMyPropertiesButton = () => (
  <Button icon as={Link} to="/myProperties">
    <Icon name="angle left" />
    Back to my Properties
  </Button>
);

export const PropertyAvailabilityPage = ({ match }) => {
  const { properties } = useContext(PropertiesContext);
  const { propertyId } = match.params;
  const property = properties[propertyId] || [];

  return (
    <Container>
      <BackToMyPropertiesButton />
      <Header>{property.name} - Availabilities</Header>

      <AvailabilitiesEditor propertyId={propertyId} />
    </Container>
  );
};

//
// Prop Types
//

PropertyAvailabilityPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      propertyId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
