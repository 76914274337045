import { get } from "lodash";

import { toDateOnlyString } from "../../../../helpers";
import { addSeparator } from "../../../../helpers/addSeparator";

export const getBookingCheckInString = (booking) =>
  toDateOnlyString(get(booking, "property.period.checkInDate"));

export const getBookingCheckOutString = (booking) =>
  toDateOnlyString(get(booking, "property.period.checkOutDate"));

/** Returns `last, first` or `leadClient` */
export const getBookingClientString = (booking = {}) => {
  const { client = {} } = booking;
  let clientString;
  if (client.lastName && client.firstName) {
    clientString = `${client.lastName}, ${client.firstName}`;
  } else if (client.leadClient) {
    clientString = client.leadClient;
  }

  return clientString;
};

/** Returns `pax` or `pax (adults, children)` */
export const getBookingPaxString = (booking) => {
  const occupancy = get(booking, "property.occupancy") || {};
  const { pax, adults, children } = occupancy;
  let paxString = `${pax}`;
  if (adults) {
    paxString += ` (${adults} adults, ${children || 0} children)`;
  }
  return paxString;
};

/** Returns `subTotal` and `currencyCode` */
export const getBookingPriceString = (booking) => {
  const payments = get(booking, "property.payments") || {};
  const { subTotal, currencyCode } = payments;
  return `${addSeparator(subTotal)} ${currencyCode}`;
};

export const getIsBookingPaidByCreditCard = (booking) =>
  get(booking, "property.payments.isPaidByCreditCard");

export const getBookingCreditCardFeeTotalString = (booking) => {
  const payments = get(booking, "property.payments") || {};
  const { creditCardFeeTotal, currencyCode } = payments;
  return `${addSeparator(creditCardFeeTotal)} ${currencyCode}`;
};

export const getBookingSecurityDepositString = (booking) => {
  const payments = get(booking, "property.payments") || {};
  const { securityDeposit, currencyCode } = payments;
  return `${addSeparator(securityDeposit || 0)} ${currencyCode}`;
};
