import React from "react";

import { DashboardSegment } from "./DashboardSegment";
import { MyBookingsMonitor } from "./MyBookingsMonitor";
import { MyInterestsMonitor } from "./MyInterestsMonitor";
import { MyOptionsMonitor } from "./MyOptionsMonitor";

export const DashboardView = () => {
  return (
    <>
      <DashboardSegment title="My Interests">
        <MyInterestsMonitor />
      </DashboardSegment>
      <DashboardSegment title="My Options">
        <MyOptionsMonitor />
      </DashboardSegment>
      <DashboardSegment title="My Bookings">
        <MyBookingsMonitor />
      </DashboardSegment>
    </>
  );
};
