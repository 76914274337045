import { useState } from "react";

import { isFinite, toNumber } from "lodash";
import { toast } from "react-toastify";

import { snowtrade } from "../../../../api";
import { useMyLeadsContext } from "../../../../context/MyLeadsContext";

export const useNegotiationResponse = (leadId) => {
  const { fetchLeads } = useMyLeadsContext();

  const [isPosting, setIsPosting] = useState(false);

  const offerNewPrice = (price) => {
    if (!price) {
      toast.error("Please enter an offer price");
      return;
    }

    const offerNumber = toNumber(price);
    if (!isFinite(offerNumber)) {
      toast.error("Offer is not a number!");
      return;
    }

    setIsPosting(true);
    return postNegotiationOperatorResponse(leadId, { offer: offerNumber })
      .then(fetchLeads)
      .finally(() => setIsPosting(false));
  };

  const closeNegotiation = () => {
    setIsPosting(true);
    return postNegotiationOperatorResponse(leadId, { closeNegotiation: true })
      .then(fetchLeads)
      .finally(() => setIsPosting(false));
  };

  return { offerNewPrice, closeNegotiation, isPosting };
};

const postNegotiationOperatorResponse = (leadId, operatorResponse) =>
  snowtrade.post(
    `/api/sales/leads/${leadId}/negotiation/offers`,
    operatorResponse
  );
