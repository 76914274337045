import { useContext } from "react";

import { get, isEmpty } from "lodash";

import { leadStatus, MyLeadsContext } from "../context/MyLeadsContext";

/** Returns a list of leads considered open from the MyLeadsContext. */
export const useGetOpenEnquiries = () => {
  const { leads } = useContext(MyLeadsContext);

  return leads.filter((lead) => {
    const status = get(lead, "property.status");

    // Requested options or bookings are considered open
    if (
      [leadStatus.REQUESTED_BOOKING, leadStatus.REQUESTED_OPTION].includes(
        status
      )
    ) {
      return true;
    }

    // Confirmed leads with active change requests are considered open
    if (
      [leadStatus.CONFIRMED_BOOKING, leadStatus.CONFIRMED_OPTION].includes(
        status
      ) &&
      !isEmpty(get(lead, "property.activeChangeRequests"))
    ) {
      return true;
    }

    return false;
  });
};

/** Returns true if there are leads considered open in MyLeadsContext */
export const useHasOpenEnquiries = () => useGetOpenEnquiries().length > 0;
