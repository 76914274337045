import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";

import { userRoleTypes } from "../../authentication/userRoleTypes";
import { AuthenticationContext } from "../../context/AuthenticationContext";
import { OperatorSelectionForAdmins } from "../OperatorSelectionForAdmins";

export const UserItem = () => {
  const { user } = useContext(AuthenticationContext);

  return (
    <Menu.Item position="right" as={Link} to="/myBusiness/myAccount">
      <Icon name="user" />
      {user.username}
      {user.role === userRoleTypes.ADMIN && (
        <>
          {" "}
          as <OperatorSelectionForAdmins />
        </>
      )}
    </Menu.Item>
  );
};
