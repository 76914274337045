import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

/**
 * Solely renders a Semantic Ui Form.Group containing three fields:
 *
 * - Last Name
 * - First Name
 * - Email Address
 *
 * Use this component to bootstrap forms for dedicated contacts like the
 * reservationContact or the accountingContact.
 */
export const GenericContactFormGroup = ({
  values = {},
  onChange,
  disabled,
  error = {},
}) => (
  <Form.Group widths="equal">
    <Form.Input
      label="Last Name"
      name="lastName"
      value={values.lastName || ""}
      onChange={onChange}
      disabled={disabled}
      error={error.lastName}
    />
    <Form.Input
      label="First Name"
      name="firstName"
      value={values.firstName || ""}
      onChange={onChange}
      disabled={disabled}
      error={error.firstName}
    />
    <Form.Input
      label="Email"
      name="emailAddress"
      value={values.emailAddress || ""}
      onChange={onChange}
      disabled={disabled}
      error={error.emailAddress}
    />
  </Form.Group>
);

//
// Prop Types
//

GenericContactFormGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.object,
};
