import React, { useContext } from "react";

import { upperFirst } from "lodash";

import { PropertyContext } from "../PropertyContext";

const DetailsIconRow = () => {
  const { property } = useContext(PropertyContext);
  if (!property || !property.beds) {
    return null;
  }

  return (
    <div className="ui large horizontal list">
      <div className="item">
        <i className="home icon" style={{ paddingRight: "2rem" }} />
        {upperFirst(property.propertyType)}
      </div>
      <div className="item">
        <i className="bed icon" style={{ paddingRight: "2rem" }} />
        {upperFirst(property.bedrooms)}
      </div>
      <div className="item">
        <i className="user icon" style={{ paddingRight: "2rem" }} />
        {upperFirst(property.beds.adults)}
      </div>
      <div className="item">
        <i className="child icon" style={{ paddingRight: "2rem" }} />
        {upperFirst(property.beds.children)}
      </div>
    </div>
  );
};

export { DetailsIconRow };
