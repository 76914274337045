import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Menu, Sidebar, Icon } from "semantic-ui-react";

import { useHasOpenEnquiries } from "../../hooks";
import { MyPropertiesMenuItem } from "./MyPropertiesMenuItem";
import { UserItem } from "./UserItem";

import "./MenuSidebar.css";

/**
 * This component renders the sidebar for the mobile navigation menu within the
 * OperatorAccess.
 */
export const MenuSidebar = ({ isVisible, doHide, onAnimationFinished }) => {
  const hasOpenEnquiries = useHasOpenEnquiries();
  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      onHide={doHide}
      onHidden={onAnimationFinished}
      onVisible={onAnimationFinished}
      vertical
      visible={isVisible}
      onClick={doHide}
    >
      <div className="closeButtonContainer">
        <Button icon="close" basic onClick={doHide} />
      </div>
      <Menu.Item as={Link} to="/">
        Home
      </Menu.Item>

      <MyPropertiesMenuItem />

      <Menu.Item as={Link} to="/myBusiness">
        My Business
        {hasOpenEnquiries && <Icon name="circle" color="yellow" size="small" />}
      </Menu.Item>
      <UserItem />
    </Sidebar>
  );
};

//
// Prop Types
//

MenuSidebar.propTypes = {
  /** @type {boolean} - If set, the sidebar will be displayed. */
  isVisible: PropTypes.bool.isRequired,

  /** Called, when the sidebar wants to hide. */
  doHide: PropTypes.func.isRequired,

  /** Called, when the sidebar finished its current animation - either hiding or appearing. */
  onAnimationFinished: PropTypes.func.isRequired,
};
