import React from "react";

import { toNumber } from "lodash";
import PropTypes from "prop-types";
import { Input } from "semantic-ui-react";

import { useSelectPropertyFromContextById } from "../../context/PropertiesContext";

/**
 * Renders a Semantic UI Input allowing the user to fill in the price for the
 * given stay and the given serviceLevel.
 */
export const RateInputCell = ({ stay = {}, onChange, serviceLevel }) => {
  const property = useSelectPropertyFromContextById(stay._property);

  // Convert change event to parent change handler
  const changeHandler = ({ target: { value } }) => {
    const ratesBefore = stay.rates || {};
    const newRates = { ...ratesBefore, [serviceLevel]: toNumber(value) };
    const newStay = { ...stay, rates: newRates };
    onChange(newStay._id, newStay);
  };

  return (
    <Input
      key={stay._id}
      fluid
      type="number"
      value={(stay.rates && stay.rates[serviceLevel]) || ""}
      onChange={changeHandler}
      placeholder={`${serviceLevel} rate`}
      label={property && property.currency}
      labelPosition="right"
    />
  );
};

//
// Prop Types
//

RateInputCell.propTypes = {
  onChange: PropTypes.func.isRequired,
  stay: PropTypes.object.isRequired,
  serviceLevel: PropTypes.string.isRequired,
};
