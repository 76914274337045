import React from "react";

import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { userRoleTypes } from "../../authentication/userRoleTypes";
import { AuthenticationContext } from "../AuthenticationContext";
import {
  fetchPropertyOperator,
  patchPropertyChannel,
  patchPropertyOperator,
} from "./api";

const INITIAL_STATE = {
  operatorIdSetByAdmin: null,
  operator: {},
  hasFetchedOperator: false,
  isFetchingOperator: false,
  hasOperatorUpdateFailed: false,

  channels: [],
};

export const OperatorContext = React.createContext({
  ...INITIAL_STATE,
  fetchOperator: () => {},
  patchOperator: () => {},
  patchChannel: () => {},
  resetOperator: () => {},
  adminSetsOperatorId: () => {},
});

export class OperatorContextProvider extends React.PureComponent {
  static contextType = AuthenticationContext;

  state = INITIAL_STATE;

  componentDidMount() {
    if (this.context.isLoggedIn) {
      this.updateOperator();
    }
  }

  componentDidUpdate() {
    const { hasFetchedOperator, isFetchingOperator } = this.state;
    if (!hasFetchedOperator && !isFetchingOperator && this.context.isLoggedIn) {
      this.updateOperator();
    }
  }

  updateOperator = async () => {
    // If an admin logged in but has not decided which operator to display yet, do not fetch any operators
    const isAdminLoggedIn = this.context.user.role === userRoleTypes.ADMIN;
    const hasAdminSetOperatorId = this.state.operatorIdSetByAdmin;
    if (isAdminLoggedIn && !hasAdminSetOperatorId) {
      return;
    }

    this.setState({ isFetchingOperator: true });

    try {
      const { operatorIdSetByAdmin } = this.state;
      const operator = await fetchPropertyOperator(operatorIdSetByAdmin);
      if (!operator) {
        throw new Error();
      }

      const channels = operator.rentalChannels || [];

      this.setState({
        operator,
        isFetchingOperator: false,
        hasFetchedOperator: true,
        hasOperatorUpdateFailed: false,
        channels,
      });
    } catch (e) {
      this.setState({
        operator: {},
        isFetchingOperator: false,
        hasFetchedOperator: true,
        hasOperatorUpdateFailed: true,
        channels: [],
      });
      toast.error(
        "We could not fetch details about your agency. Please contact Snowtrade to solve this.",
        { autoClose: false }
      );
    }
  };

  patchOperator = async (newOperatorState) => {
    this.setState({ isFetchingOperator: true });

    try {
      const newOperator = await patchPropertyOperator(
        this.state.operator._id,
        newOperatorState
      );
      this.setState({
        operator: newOperator,
        isFetchingOperator: false,
        hasOperatorUpdateFailed: false,
      });
      toast.success(
        `Successfully saved your changes to ${
          newOperator.name || "your operator"
        }`
      );
    } catch (error) {
      console.log("failed");
      this.setState({
        isFetchingOperator: false,
        hasOperatorUpdateFailed: true,
      });
    }
  };

  patchChannel = async (channelId, newChannelState) => {
    const newChannel = await patchPropertyChannel(channelId, newChannelState);

    const operatorChannelsBefore = this.state.channels;
    const indexOfChangedChannel = operatorChannelsBefore.findIndex(
      (channel) => channel._id === channelId
    );
    const newOperatorChannels = [...operatorChannelsBefore];
    newOperatorChannels.splice(indexOfChangedChannel, 1, newChannel);
    this.setState({ channels: newOperatorChannels });
    toast.success(
      `Successfully saved your changes to your sales channel for this property.`
    );
  };

  adminSetsOperatorId = (operatorId) =>
    this.setState({ ...INITIAL_STATE, operatorIdSetByAdmin: operatorId });

  resetOperator = () => this.setState(INITIAL_STATE);

  render() {
    const context = {
      // Operator
      operator: this.state.operator,
      hasFetchedOperator: this.state.hasFetchedOperator,
      isFetchingOperator: this.state.isFetchingOperator,
      hasOperatorUpdateFailed: this.state.hasOperatorUpdateFailed,
      patchOperator: this.patchOperator,
      fetchOperator: this.updateOperator,
      resetOperator: this.resetOperator,
      adminSetsOperatorId: this.adminSetsOperatorId,

      // Channels
      channels: this.state.channels,
      patchChannel: this.patchChannel,
    };
    return (
      <OperatorContext.Provider value={context}>
        {this.props.children}
      </OperatorContext.Provider>
    );
  }
}

//
// Prop Types
//

OperatorContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
