import React from "react";

import PropTypes from "prop-types";

import { AuthenticationContextProvider } from "./AuthenticationContext";
import { MyLeadsContextProvider } from "./MyLeadsContext";
import { OperatorContextProvider } from "./OperatorContext";
import { PropertiesContextProvider } from "./PropertiesContext";

/**
 * This component merges providers for the AuthenticationContext, the
 * PropertiesContext, and the OperatorContext.
 */
export const CombinedContextProvider = ({ children }) => (
  <AuthenticationContextProvider>
    <OperatorContextProvider>
      <PropertiesContextProvider>
        <MyLeadsContextProvider>{children}</MyLeadsContextProvider>
      </PropertiesContextProvider>
    </OperatorContextProvider>
  </AuthenticationContextProvider>
);

//
// Prop Types
//

CombinedContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
