import React from "react";

import { Route, Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { CombinedContextProvider } from "../context/CombinedContextProvider";
import { history } from "../helpers";
import { Menu } from "../Menu";
import { LoginPage } from "../pages/LoginPage";
import { ApplePwaHint } from "../viewComponents/ApplePwaHint";
import { OperatorAccessRoutes } from "./routes";

import "react-toastify/dist/ReactToastify.css";
import "react-table/react-table.css";
import "./baseStyle.css";
import "./SemanticUiOverride.css";

export default () => (
  <CombinedContextProvider>
    <Router history={history}>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route>
          <Menu />
          <OperatorAccessRoutes />
        </Route>
      </Switch>
      <ApplePwaHint />
      <ToastContainer autoClose={3000} position="bottom-right" />
    </Router>
  </CombinedContextProvider>
);
