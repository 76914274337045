import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { Image, Menu } from "semantic-ui-react";

import { PropertiesContext } from "../../../context/PropertiesContext";

const MAX_PROPERTIES_TO_DISPLAY = 10;

/**
 * Renders a Semantic UI Menu.Item having a "My Properties" header and subitems
 * displaying shortcuts to the properties.
 */
export const MyPropertiesMenuItem = () => {
  const { properties: keyedProperties } = useContext(PropertiesContext);
  const propertyArray = Object.values(keyedProperties);

  const mainItem = (
    <Menu.Header as={Link} to="/myProperties">
      My Properties
    </Menu.Header>
  );

  // Do not show properties if there are too many to display
  const hasTooManyPropertiesToDisplay =
    propertyArray.length > MAX_PROPERTIES_TO_DISPLAY;
  const chaletItems = !hasTooManyPropertiesToDisplay && (
    <Menu.Menu>
      {propertyArray.map((p) => (
        <Menu.Item
          key={p._id}
          className="property"
          as={Link}
          to={`/myProperties/${p._id}`}
        >
          <Image size="mini" circular src={p.thumbnail} />
          {p.name}
        </Menu.Item>
      ))}
    </Menu.Menu>
  );

  return (
    <Menu.Item>
      {mainItem}
      {chaletItems}
    </Menu.Item>
  );
};
