import React, { useContext } from "react";

import { flatten, compact } from "lodash";

import { Accordion } from "../../../viewComponents/accordion";
import { PropertyContext } from "../../PropertyContext";
import { getDescriptionMarkdown } from "../DescriptionMarkdown";
import { getEquipment } from "../Equipment";
import { getFacilities } from "../Facilities";
import { getFloorplans } from "../Floorplans";
import { getInfoAndDistances } from "../InfoAndDistances";
import { getSalesConditionsTab } from "../SalesConditions";
import { getServices } from "../Services";

import "./PropertyAccordion.css";

/** Renders the details of the given property in an accordion style. */
const PropertyAccordion = () => {
  const { property } = useContext(PropertyContext);

  // Determine the components to show in the accordion
  let accordionProps = [
    getInfoAndDistances(property),
    getFacilities(property),
    getServices(property),
    getEquipment(property),
    getFloorplans(property),
    getSalesConditionsTab(property),
    getDescriptionMarkdown(property),
  ];

  // flatten the accordionProps because some components might return an array of subComponents
  // compact the accordionProps because some components might return null
  accordionProps = compact(flatten(accordionProps));

  return (
    <Accordion accordionProps={accordionProps} id="accordionPropertyDetails" />
  );
};

//
// Exports
//

export { PropertyAccordion };
