import React from "react";

import { BookingModal } from "./BookingModal";
import { MyBookingsMonitorTable } from "./MyBookingsMonitorTable";

/**
 * This component contains the BookingsMonitorTable as well as the BookingModal
 * which opens when the user has click on one of the bookings displayed in the table.
 */
class MyBookingsMonitorContainer extends React.PureComponent {
  state = { hasErrorInTree: false, bookingForModal: null };

  static getDerivedStateFromError() {
    return { hasErrorInTree: true };
  }

  handleOnBookingClick = (bookingForModal) =>
    this.setState({ bookingForModal });

  closeBookingModal = () => this.setState({ bookingForModal: null });

  render() {
    if (this.state.hasErrorInTree) {
      return <span>Unfortunately, an error occured</span>;
    }

    return (
      <>
        <MyBookingsMonitorTable onBookingClick={this.handleOnBookingClick} />
        <BookingModal
          booking={this.state.bookingForModal}
          onClose={this.closeBookingModal}
        />
      </>
    );
  }
}

export default MyBookingsMonitorContainer;
