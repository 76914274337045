import React, { useState } from "react";

import { get } from "lodash";
import { Button, Input } from "semantic-ui-react";

import { addSeparator } from "../../../../helpers/addSeparator";
import { useNegotiationResponse } from "./useNegotiationResponse";

export const OfferInputView = ({ interest, afterAction }) => {
  const [offerValue, setOfferValue] = useState("");
  const { closeNegotiation, offerNewPrice, isPosting } = useNegotiationResponse(
    interest._id
  );

  const handleOnOfferClick = () => offerNewPrice(offerValue).then(afterAction);
  const handleOnCloseNegotiationClick = () =>
    closeNegotiation().then(afterAction);

  const payments = get(interest, "property.payments") || {};

  return (
    <>
      <p>
        Our partner has requested a price discount. The price our system
        calculated is {payments.currencyCode} {addSeparator(payments.subTotal)}.
        Do you want to enter a new offer?
      </p>
      <Input
        value={offerValue}
        onChange={({ target: { value } }) => setOfferValue(value)}
        labelPosition="right"
        label={payments.currencyCode}
        style={{ marginRight: "0.25em" }}
      />
      <Button primary onClick={handleOnOfferClick} loading={isPosting}>
        Offer
      </Button>
      <Button onClick={handleOnCloseNegotiationClick} loading={isPosting}>
        Close Negotiation
      </Button>
    </>
  );
};
