import React from "react";

import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Container, Grid, Icon } from "semantic-ui-react";

import { useSelectPropertyFromContextById } from "../context/PropertiesContext";
import { PropertyDetails } from "../PropertyDetails";

/**
 * Renders a row with two buttons. One lets the user navigate back to his
 * properties. The other one lets him navigate to the availabilities form.
 */
const NavigationButtons = ({ propertyId }) => (
  <Grid>
    <Grid.Column width={8}>
      <Link to="/myProperties">
        <Button icon>
          <Icon name="angle left" /> Back to my Properties
        </Button>
      </Link>
    </Grid.Column>
    <Grid.Column width={8} textAlign="right">
      <Link to={`/myProperties/${propertyId}/edit`}>
        <Button icon>
          <Icon name="setting" /> Edit Property
        </Button>
      </Link>
      <Link to={`/myProperties/${propertyId}/availabilities`}>
        <Button icon>
          <Icon name="calendar alternate outline" /> Edit Availabilities
        </Button>
      </Link>
    </Grid.Column>
  </Grid>
);

export const PropertyPage = ({ match }) => {
  const propertyId = get(match, "params.propertyId");

  const property = useSelectPropertyFromContextById(propertyId);

  if (!propertyId || isEmpty(property)) {
    return null;
  }

  return (
    <Container>
      <NavigationButtons propertyId={propertyId} />

      <PropertyDetails property={property} />
    </Container>
  );
};

//
// Prop Types
//

NavigationButtons.propTypes = {
  propertyId: PropTypes.string.isRequired,
};

PropertyPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      propertyId: PropTypes.string.isRequired,
    }),
  }),
};
