import React from "react";

import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { Loader, Message } from "semantic-ui-react";

import { OperatorContext } from "../context/OperatorContext";
import { OperatorEditorView } from "./OperatorEditorView";
import { validateFormValues } from "./validateFormValues";

export default class OperatorEditorContainer extends React.PureComponent {
  static contextType = OperatorContext;
  state = { formValues: {}, error: {} };

  componentDidMount() {
    this.setInitialFormValues();
  }

  componentDidUpdate() {
    const { formValues } = this.state;
    const hasOperatorChanged = formValues._id !== this.context.operator._id;
    if (isEmpty(formValues) || hasOperatorChanged) {
      this.setInitialFormValues();
    }
  }

  setInitialFormValues() {
    const { operator, hasFetchedOperator, isFetchingOperator } = this.context;
    if (!hasFetchedOperator || isFetchingOperator) return;

    this.setState({ formValues: operator });
  }

  handleFormValuesChange = (update) => {
    const formValuesBefore = this.state.formValues || {};
    const newFormValues = { ...formValuesBefore, ...update };
    console.log(newFormValues);
    this.setState({ formValues: newFormValues });
  };

  handleOnSubmitClick = () => {
    const error = validateFormValues(this.state.formValues);

    this.setState({ error });

    if (!isEmpty(error)) {
      toast.error("Please see the error messages under some input fields.");
      return;
    }

    this.context.patchOperator(this.state.formValues);
  };

  render() {
    const {
      hasFetchedOperator,
      isFetchingOperator,
      hasOperatorUpdateFailed,
    } = this.context;

    if (hasOperatorUpdateFailed) {
      return (
        <Message error>
          Unfortunately, the update failed.{" "}
          <span
            onClick={() => {
              this.context.fetchOperator();
              this.setState({ formValues: {} });
            }}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Click here
          </span>{" "}
          to try again!
        </Message>
      );
    }

    if (
      !hasFetchedOperator ||
      isFetchingOperator ||
      isEmpty(this.state.formValues)
    ) {
      return <Loader active inline="centered" />;
    }

    return (
      <OperatorEditorView
        values={this.state.formValues}
        onChange={this.handleFormValuesChange}
        onSubmit={this.handleOnSubmitClick}
        error={this.state.error}
      />
    );
  }
}
