/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";

import { Button } from "semantic-ui-react";

import { useOperatorContext } from "../../../context/OperatorContext";
import { AddCimalpesPaymentLinkButton } from "./AddCimalpesPaymentLinkButton/AddCimalpesPaymentLinkButton";
import { CimalpesPaymentLinksSegment } from "./CimalpesPaymentLinksSegment";

/**
 * @param {{
 *   booking: import("../../../types/PropertyLead").PropertyLead;
 *   onOkayClick: Function;
 * }} props
 * @returns {import("react").ReactNode}
 */
export const ConfirmedBookingResponseForm = ({ onOkayClick, booking }) => {
  const { operator } = useOperatorContext();

  const [linksQuantity, setLinksQuantity] = useState(0);

  const isCimalpesBooking =
    operator?.name?.match(/cimalpes/i) &&
    booking?.property?._operator === operator._id;

  const handleCreatedLink = () => setLinksQuantity((qty) => qty + 1);

  return (
    <>
      {isCimalpesBooking && (
        <CimalpesPaymentLinksSegment
          booking={booking}
          refetchCounter={linksQuantity}
        />
      )}
      <Button primary onClick={onOkayClick}>
        Okay
      </Button>
      {isCimalpesBooking && (
        <AddCimalpesPaymentLinkButton
          booking={booking}
          onCreated={handleCreatedLink}
        />
      )}
    </>
  );
};
