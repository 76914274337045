import React from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import { OperatorContext } from "../../context/OperatorContext";
import { ArrivalContactForm } from "./ArrivalContactForm";
import { ArrivalContactIsOperatorDefaultView } from "./ArrivalContactIsOperatorDefaultView";
import { arrivalContactShape } from "./types";

/**
 * This component renders a Semantic UI Form.Group (must be wrapped in a Form!)
 * which allows the user to set a dedicated arrivalContact for a property. If no
 * values are given, the component assumes that the operator-wide defaults are
 * to be used for this property. Then, those defaults are displayed next to a
 * button which enables the user to switch to the input form.
 *
 * @augments {React.PureComponent<State, Props>}
 */
export default class ArrivalContactFormContainer extends React.PureComponent {
  static contextType = OperatorContext;

  constructor(props) {
    super(props);

    const values = props.values || {};

    const isArrivalContactDifferentThanOperatorDefault = !isEmpty(values);

    const isArrivalContactADedicatedPerson =
      values.lastName || values.firstName;

    this.state = {
      isArrivalContactDifferentThanOperatorDefault,
      isArrivalContactADedicatedPerson,
    };
  }

  onSetContactForPropertyClick = () => {
    this.setState({ isArrivalContactDifferentThanOperatorDefault: true });
  };

  onIsDedicatedPersonToggle = () => {
    // If contact had a name before, delete it before toggling the value
    if (this.state.isArrivalContactADedicatedPerson) {
      const arrivalContactBefore = this.props.values || {};
      const newArrivalContact = { ...arrivalContactBefore };
      delete newArrivalContact.lastName;
      delete newArrivalContact.firstName;
      this.props.onChange({ arrivalContact: newArrivalContact });
    }

    // Toggle the `isArrivalContactADedicatedPerson` flag
    this.setState({
      isArrivalContactADedicatedPerson: !this.state
        .isArrivalContactADedicatedPerson,
    });
  };

  onSetToOperatorDefaultClick = () => {
    this.props.onChange({ arrivalContact: {} });
    this.setState({ isArrivalContactDifferentThanOperatorDefault: false });
  };

  onFormChange = (update) => {
    const arrivalContactBefore = this.props.values || {};
    const newArrivalContact = { ...arrivalContactBefore, ...update };
    this.props.onChange({ arrivalContact: newArrivalContact });
  };

  render() {
    const { values } = this.props;
    if (!this.state.isArrivalContactDifferentThanOperatorDefault) {
      return (
        <ArrivalContactIsOperatorDefaultView
          onSetArrivalContactForPropertyClick={
            this.onSetContactForPropertyClick
          }
        />
      );
    }

    return (
      <ArrivalContactForm
        values={values}
        onChange={this.onFormChange}
        onIsDedicatedPersonClick={this.onIsDedicatedPersonToggle}
        onSetToOperatorDefaultClick={this.onSetToOperatorDefaultClick}
        isNameDisabled={!this.state.isArrivalContactADedicatedPerson}
      />
    );
  }
}

//
// Prop Types
//

ArrivalContactFormContainer.propTypes = {
  /** Called with {"arrivalContact": <update>} on changes. */
  onChange: PropTypes.func.isRequired,

  /** The arrivalContact values. */
  values: arrivalContactShape,
};
