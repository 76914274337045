import React from "react";

import { Route, Switch } from "react-router";

import { AvailabilitiesPageHelp } from "./views/AvailabilitiesPageHelp";
import { DefaultView } from "./views/DefaultView";
import { LandinPageHelp } from "./views/LandingPageHelp";
import { OperatorSettingsHelp } from "./views/OperatorSettingsHelp";
import { PropertyPageHelp } from "./views/PropertyPageHelp";
import { PropertySettingsPageHelp } from "./views/PropertySettingsPageHelp";

/**
 * Renders a react-router `Switch` to be placed in the `HelpSidebar` component.
 * Make sure that you make every route in here `exact`.
 */
export const RouterSwitch = () => (
  <Switch>
    <Route
      path="/myProperties/:id/availabilities"
      exact
      component={AvailabilitiesPageHelp}
    />
    <Route
      path="/myProperties/:id/edit"
      exact
      component={PropertySettingsPageHelp}
    />
    <Route path="/myProperties/:id" exact component={PropertyPageHelp} />
    <Route path="/myBusiness/settings" exact component={OperatorSettingsHelp} />
    <Route path="/" exact component={LandinPageHelp} />
    <Route component={DefaultView} />
  </Switch>
);
