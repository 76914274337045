import React from "react";

export const PropertyPageHelp = () => (
  <>
    <h3>Property Page</h3>
    <p>
      You navigated to the property page. It displays detailed information about
      this property that is managed by yourself.
    </p>
  </>
);
