import React from "react";

import { Form } from "semantic-ui-react";

import { CreditCardTransfer } from "../../../../types/Operator";

export const PreferredCreditCardTransferDropdown = ({
  preferredCreditCardTransfer,
  onChange,
  disabled,
}) => {
  const handleSelect = (e, { value }) =>
    onChange({ preferredCreditCardTransfer: value });

  return (
    <Form.Select
      label="Preferred Credit Card Transfer"
      options={options}
      value={preferredCreditCardTransfer}
      onChange={handleSelect}
      disabled={disabled}
    />
  );
};

const options = [
  {
    value: CreditCardTransfer.OPERATOR_RECEIVES_CC_DATA_BY_EMAIL,
    text: "Receive by Email",
    description: "You will be sent the cc information directly",
  },
  {
    value: CreditCardTransfer.OPERATOR_ENTERS_LINK_ON_EACH_BOOKING,
    text: "Payment Link dedicated to Booking",
    description: "You enter a payment link after booking",
  },
];
