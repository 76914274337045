import { useContext } from "react";

import { MyLeadsContext } from "../../context/MyLeadsContext";

/**
 * Returns those leads from the `MyLeadsContext` that are either requested or
 * confirmed options. Expired options are not returned!
 */
export const useSelectMyBookings = () => {
  const { leads } = useContext(MyLeadsContext);

  const validStatus = ["requestedBooking", "confirmedBooking"];
  return leads.filter((lead) => validStatus.includes(lead.property.status));
};
