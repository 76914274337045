export const leadStatus = {
  INTEREST: "interest",
  REQUESTED_OPTION: "requestedOption",
  CONFIRMED_OPTION: "confirmedOption",
  DECLINED_OPTION: "declinedOption",
  CANCELLED_OPTION: "cancelledOption",
  EXPIRED_OPTION: "expiredOption",
  REQUESTED_BOOKING: "requestedBooking",
  CONFIRMED_BOOKING: "confirmedBooking",
  DECLINED_BOOKING: "declinedBooking",
  CANCELLED_BOOKING: "cancelledBooking",
};
