import React from "react";

import { Segment } from "semantic-ui-react";

import { LoginForm } from "../authentication";

import "./LoginPage.css";

export const LoginPage = () => {
  return (
    <div id="loginPage">
      <Segment>
        <LoginForm />
      </Segment>
    </div>
  );
};
