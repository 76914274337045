import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

const SecurityDepositTypeOptions = [
  {
    key: "cashedIn",
    value: "cashedIn",
    text: "Cashed in from Credit Card",
    description:
      "You withdraw the security deposit from the credit card and send it back after the customer's stay.",
  },
  {
    key: "blocked",
    value: "blocked",
    text: "Blocked on Credit Card",
    description:
      "You block the amount on the credit card and release it after the customer's stay",
  },
];

/**
 * Renders a Semantic UI Form.Select input displaying the two options
 *
 * - CashedIn
 * - Blocked on credit card
 */
export const SecurityDepositTypeInput = ({ value, onChange, error }) => {
  const changeHandler = (event, { value }) =>
    onChange({ securityDepositType: value });

  return (
    <Form.Select
      label="Security Deposit Type"
      options={SecurityDepositTypeOptions}
      value={value}
      onChange={changeHandler}
      name="securityDepositType"
      error={error}
    />
  );
};

//
// Prop Types
//

SecurityDepositTypeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
};
