import React from "react";

import PropTypes from "prop-types";

import { makeOccupancyString } from "../../../../helpers/makeOccupancyString";
import { BookingStatusView } from "./BookingStatusView";
import {
  getBookingCheckInString,
  getBookingCheckOutString,
  getBookingClientString,
  getBookingCreditCardFeeTotalString,
  getBookingPriceString,
  getBookingSecurityDepositString,
  getIsBookingPaidByCreditCard,
} from "./data";

/**
 * This component renders information about the booking as strings divided by `<br />`s.
 *
 * @param {{
 *   booking: import("../../../../types/PropertyLead").PropertyLead;
 * }} props
 */
export const BookingDetailsView = ({ booking = {} }) => {
  const { occupancy } = booking.property;
  return (
    <>
      <p>
        Check in: {getBookingCheckInString(booking)}
        <br />
        Check out: {getBookingCheckOutString(booking)}
        <br />
        Client: {getBookingClientString(booking)}
        <br />
        Pax:{" "}
        {makeOccupancyString(
          occupancy?.adults,
          occupancy?.children,
          occupancy?.infants
        )}
      </p>
      <p>
        Booking Price: {getBookingPriceString(booking)}
        <br />
        {getIsBookingPaidByCreditCard(booking) && (
          <>
            Credit Card Fee: {getBookingCreditCardFeeTotalString(booking)}{" "}
            <br />
          </>
        )}
        Security Deposit: {getBookingSecurityDepositString(booking)}
      </p>
      <p>
        Status: <BookingStatusView booking={booking} />
      </p>
    </>
  );
};

//
// Prop Types
//

BookingDetailsView.propTypes = {
  booking: PropTypes.object.isRequired,
};
