import React, { useEffect, useState } from "react";

import { isEmpty } from "lodash";
import NumberFormat from "react-number-format";
import { Card, Header, Segment } from "semantic-ui-react";

import { snowtrade } from "../../../api";

/**
 * @param {object} props
 * @param {import("../../../types/PropertyLead").PropertyLead} props.booking
 * @param {number} props.refetchCounter Increment this to force the component to
 *   refetch the payment links
 */
export const CimalpesPaymentLinksSegment = ({ booking, refetchCounter }) => {
  const [paymentLinks, setPaymentLinks] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    setIsFetching(true);
    snowtrade
      .get(`/api/sales/leads/${booking._id}/cimalpes-payment-links`)
      .then((response) => setPaymentLinks(response.data))
      .finally(() => setIsFetching(false));
  }, [refetchCounter]);

  return (
    <Segment loading={isFetching}>
      <Header>Payment links for this booking</Header>
      {isEmpty(paymentLinks) && "None"}
      {paymentLinks.map((linkObject) => (
        <Card key={linkObject._id}>
          <Card.Content>
            {linkObject.displayName}:{" "}
            <NumberFormat
              displayType="text"
              value={linkObject.amount}
              prefix={`${linkObject.currency} `}
              thousandSeparator
            />
          </Card.Content>
        </Card>
      ))}
    </Segment>
  );
};
