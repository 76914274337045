import React from "react";

import PropTypes from "prop-types";

import { MyLeadsContext } from "../../../../context/MyLeadsContext";
import {
  sendAcceptChangeRequestsRequest,
  sendDeclineChangeRequestsRequest,
} from "./api";
import { ChangeRequestResponseForm } from "./ChangeRequestResponseForm";

/**
 * Show this view to operators when he already confirmed an option but the agent
 * has submitted a change request. It will render buttons to allow the operator
 * to accept or decline the changes.
 */
export class ChangeRequestResponseContainer extends React.PureComponent {
  static contextType = MyLeadsContext;
  state = { isLoading: false };

  /**
   * Higher-order function to manage the loading state while the API request is
   * sent to the backend after the operator has chosen to either accept or
   * decline the changes. It also calls the context to refetch the leads to
   * display the latest changes.
   */
  withLoadingAndRefetching = (func) => async () => {
    this.setState({ isLoading: true });
    await func();
    this.setState({ isLoading: false });
    this.context.fetchLeads();
    this.props.onSubmit();
  };

  onAcceptChangesClick = this.withLoadingAndRefetching(() =>
    sendAcceptChangeRequestsRequest(this.props.option._id)
  );

  onDeclineChangesClick = this.withLoadingAndRefetching(() =>
    sendDeclineChangeRequestsRequest(this.props.option._id)
  );

  render() {
    return (
      <ChangeRequestResponseForm
        option={this.props.option}
        loading={this.state.isLoading}
        onAcceptChangesClick={this.onAcceptChangesClick}
        onDeclineChangesClick={this.onDeclineChangesClick}
      />
    );
  }
}

//
// Prop Types
//

ChangeRequestResponseContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
};
