import React, { useState } from "react";

import PropTypes from "prop-types";

import AccordionSection from "./AccordionSection";

/**
 * This component renders an accordion inspired by Semantic UI React. We
 * created our own accordion here because the Semantic accordion can only
 * display text and no further html nodes.
 *
 * @param {AccordionPropTypes} props
 */
const Accordion = ({
  accordionProps,
  defaultActive = 0,
  className = "",
  id,
}) => {
  const [activeKey, setActive] = useState(defaultActive);

  /**
   * Call this method when user has clicked on one of the sections. It will set
   * this section active. If it was active before, all sections become inactive.
   *
   * @param {number} index - The index of the clicked section.
   */
  const onTitleClick = (index) => {
    const keyOfClickedSection = accordionProps[index].key || index;
    const isAlreadyActive = activeKey === keyOfClickedSection;
    const newActiveKey = isAlreadyActive ? null : keyOfClickedSection;
    setActive(newActiveKey);
  };

  return (
    <div className={"ui styled fluid accordion " + className} id={id}>
      {accordionProps.map((sectionProps, index) => {
        const key = sectionProps.key || index;
        const onClick = () => {
          onTitleClick(index);
        };
        return (
          <AccordionSection
            {...sectionProps}
            key={key}
            onTitleClick={onClick}
            isActive={key === activeKey}
          />
        );
      })}
    </div>
  );
};

//
// Prop Types
//

/**
 * @typedef AccordionSectionDetails
 * @property {string} key
 * @property {React.ReactNode} title
 * @property {React.ReactNode} content
 * @property {string} [id]
 */

/**
 * @typedef {Object} AccordionPropTypes
 * @property {AccordionSectionDetails[]} accordionProps
 * @property {number | string} [defaultActive]
 * @property {string} [className]
 * @property {string} [id]
 */

Accordion.propTypes = {
  accordionProps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.node.isRequired,
      content: PropTypes.node.isRequired,
      id: PropTypes.string,
    })
  ),

  defaultActive: PropTypes.number,
  className: PropTypes.string,
  id: PropTypes.string,
};

//
// Exports
//

export default Accordion;
