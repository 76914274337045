import { isFinite, toNumber } from "lodash";

import { PrepaymentRatioInput } from "./PrepaymentRatioInput";

export const validateRentalInformationForm = (values = {}) => {
  let error = {};

  const { creditCardFeeRatio } = values;
  if (creditCardFeeRatio) {
    const creditCardFeeRatioNumber = toNumber(creditCardFeeRatio);
    if (!isFinite(creditCardFeeRatioNumber)) {
      error.creditCardFeeRatio = "Please enter a number here";
    } else if (creditCardFeeRatioNumber < 0) {
      error.creditCardFeeRatio = "The credit card fee ratio has to be positive";
    } else if (creditCardFeeRatioNumber > 10) {
      error.creditCardFeeRatio =
        "The customers will not accept a fee of more than 10%";
    }
  }

  const { commission } = values;
  const commissionNumber = toNumber(commission);
  if (commission) {
    if (!isFinite(commissionNumber)) {
      error.commission = "Please enter a number here";
    } else if (commissionNumber < 5) {
      error.commission = "We cannot accept such small commission levels";
    } else if (commission > 50) {
      error.commission = "Are you sure you want to do this?";
    }
  }

  const { commissionB2b } = values;
  if (commissionB2b) {
    const commissionB2bNumber = toNumber(commissionB2b);
    if (!isFinite(commissionB2bNumber)) {
      error.commissionB2b = "Please enter a number";
    } else if (commissionB2bNumber < commissionNumber) {
      error.commissionB2b =
        "The b2b commission must be higher than the b2c commission";
    } else if (commissionB2bNumber > 50) {
      error.commissionB2b = "Are you sure you want to do this?";
    }
  }

  // Validate prepaymentRatio
  error = {
    ...error,
    ...PrepaymentRatioInput.validate(values.prepaymentRatio),
  };

  return error;
};
