import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { createInputChangeEventToUpdateHandler } from "../../helpers/forms";
import { arrivalContactShape } from "./types";

/**
 * Renders Semantic UI Form.Groups allowing the user to fill in the
 * arrivalContact for this property. The user can choose whether to reset the
 * arrivalContact to the operator-wide defaults, or to only set email and phone,
 * or to set email and phone and the contact name.
 */
export const ArrivalContactForm = ({
  values = {},
  onChange,
  isNameDisabled = false,
  onSetToOperatorDefaultClick,
  onIsDedicatedPersonClick,
}) => {
  const changeHandler = createInputChangeEventToUpdateHandler(onChange);

  return (
    <>
      <Form.Group widths="equal">
        <Form.Input
          name="emailAddress"
          value={values.emailAddress || ""}
          label="Email address"
          onChange={changeHandler}
        />
        <Form.Input
          name="phone"
          value={values.phone || ""}
          label="Phone"
          onChange={changeHandler}
        />
      </Form.Group>

      <Form.Checkbox
        label="Arrival contact is a dedicated person"
        checked={!isNameDisabled}
        onChange={onIsDedicatedPersonClick}
      />

      <Form.Group widths="equal">
        <Form.Input
          name="lastName"
          value={values.lastName || ""}
          label="Last name"
          onChange={changeHandler}
          disabled={isNameDisabled}
        />
        <Form.Input
          name="firstName"
          value={values.firstName || ""}
          label="First name"
          onChange={changeHandler}
          disabled={isNameDisabled}
        />
      </Form.Group>
      <p>
        <span
          onClick={onSetToOperatorDefaultClick}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          Click here
        </span>{" "}
        to reset the arrival contact to the operator-wide default.
      </p>
    </>
  );
};

ArrivalContactForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: arrivalContactShape.isRequired,

  /**
   * Called when the user desires to reset the arrivalContact to the
   * operator-wide defaults.
   */
  onSetToOperatorDefaultClick: PropTypes.func.isRequired,

  /**
   * Called when the user toggles the checkbox responsible for enabling the
   * arrivalContact's name input.
   */
  onIsDedicatedPersonClick: PropTypes.func.isRequired,

  /**
   * If set, the user cannot enter the contact's name. The
   * `isDedicatedPerson`-checkbox is unchecked then.
   */
  isNameDisabled: PropTypes.bool,
};
