import React from "react";

import { MyOptionsMonitorTable } from "./MyOptionsMonitorTable";
import { OptionModal } from "./OptionModal";

/**
 * This is the entry point for the `MyOptionsMonitor` component tree. Its task
 * is to show the user the requested and the confirmed options that aim at one
 * of the properties managed by the user.
 */
class MyOptionsMonitorContainer extends React.PureComponent {
  state = { optionToEditInModal: null, hasErrorInTree: false };

  static getDerivedStateFromError() {
    return { hasErrorInTree: true };
  }

  closeOptionModal = () => this.setState({ optionToEditInModal: null });

  handleOnEditOptionClick = (optionToEditInModal) =>
    this.setState({ optionToEditInModal });

  render() {
    if (this.state.hasErrorInTree) {
      return <span>Unfortunately, an error occured</span>;
    }

    return (
      <>
        <MyOptionsMonitorTable
          onEditOptionClick={this.handleOnEditOptionClick}
        />
        <OptionModal
          option={this.state.optionToEditInModal}
          onClose={this.closeOptionModal}
        />
      </>
    );
  }
}

export default MyOptionsMonitorContainer;
