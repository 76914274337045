import React from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

import { toDateOnlyString } from "../../../../helpers";

export const ChangeRequestResponseForm = ({
  option,
  loading,
  onAcceptChangesClick,
  onDeclineChangesClick,
}) => {
  const { activeChangeRequests } = option.property;

  return (
    <div className="changeRequestResponseForm">
      <p>
        You accepted a lead request earlier. However, the agent requested a
        change:
      </p>
      <p>
        {" "}
        Check in:{" "}
        {get(activeChangeRequests, "period.checkInDate") ? (
          <>
            <span style={{ textDecoration: "line-through" }}>
              {toDateOnlyString(option.property.period.checkInDate)}
            </span>{" "}
            &gt; {toDateOnlyString(activeChangeRequests.period.checkInDate)}
          </>
        ) : (
          toDateOnlyString(option.property.period.checkInDate)
        )}
        <br />
        Check out:{" "}
        {get(activeChangeRequests, "period.checkOutDate") ? (
          <>
            <span style={{ textDecoration: "line-through" }}>
              {toDateOnlyString(option.property.period.checkOutDate)}
            </span>{" "}
            &gt; {toDateOnlyString(activeChangeRequests.period.checkOutDate)}
          </>
        ) : (
          toDateOnlyString(option.property.period.checkOutDate)
        )}
      </p>

      <Button primary loading={loading} onClick={onAcceptChangesClick}>
        Accept changes
      </Button>
      <Button loading={loading} onClick={onDeclineChangesClick}>
        Decline
      </Button>
    </div>
  );
};

//
// Prop Types
//

ChangeRequestResponseForm.propTypes = {
  option: PropTypes.shape({
    property: PropTypes.shape({
      activeChangeRequests: PropTypes.object.isRequired,
      period: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  onAcceptChangesClick: PropTypes.func.isRequired,
  onDeclineChangesClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
