import React from "react";

import PropTypes from "prop-types";

import { StayFormView } from "./StayFormView";

/** @augments {React.PureComponent<State, Props>} */
class StayFormContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      formValues: props.stay,
    };
  }

  handleChange = (update) => {
    const stayBefore = this.state.formValues || {};
    const stayNow = { ...stayBefore, ...update };
    this.setState({ formValues: stayNow });
  };

  handleSubmit = () => {
    const stay = this.state.formValues;
    this.props.onSubmit(stay._id, stay, true);
  };

  render() {
    return (
      <StayFormView
        stay={this.state.formValues}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default StayFormContainer;

//
// Prop Types
//

StayFormContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  stay: PropTypes.object.isRequired,
};
