import React from "react";

import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";

import { leadStatus } from "../../../context/MyLeadsContext";
import { BookingDetailsView } from "./BookingDetailsView";
import { ChangeRequestResponseForm } from "./ChangeRequestResponseForm";
import { ConfirmedBookingResponseForm } from "./ConfirmedBookingResponseForm";
import { RequestedBookingResponseForm } from "./RequestedBookingResponseForm";

/**
 * Renders a Semantic UI Modal containing a form allowing the user to see
 * details of the booking and to act on it.
 *
 * @param {{
 *   booking: import("../../../types/PropertyLead").PropertyLead;
 *   onClose: Function;
 * }}
 */
export const BookingModal = ({ booking, onClose }) => {
  if (!booking) return null;

  const hasActiveChangeRequests = !isEmpty(
    booking.property.activeChangeRequests
  );

  const isConfirmedBooking =
    booking.property.status === leadStatus.CONFIRMED_BOOKING;

  const propertyName = get(booking, "property.propertyDetails.name");
  const statusTitle = isConfirmedBooking
    ? "Confirmed Booking"
    : "Booking Request";

  return (
    <Modal open onClose={onClose} closeIcon id="bookingModal">
      <Modal.Header>
        {propertyName} &mdash; {statusTitle}
      </Modal.Header>
      <Modal.Content>
        {hasActiveChangeRequests ? (
          <ChangeRequestResponseForm />
        ) : (
          <>
            <BookingDetailsView booking={booking} />
            {isConfirmedBooking ? (
              <ConfirmedBookingResponseForm
                onOkayClick={onClose}
                booking={booking}
              />
            ) : (
              <RequestedBookingResponseForm
                booking={booking}
                onSubmit={onClose}
              />
            )}
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};

//
// Prop Types
//

BookingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  booking: PropTypes.object,
};
