// @ts-check
import React from "react";

import { isEmpty } from "lodash";

import { List } from "../../viewComponents/list";

/**
 * Creates a list of equipment the property has.
 *
 * @param {Object} property
 */
export const getEquipment = (property) => {
  const { equipmentIncluded } = property;
  if (isEmpty(equipmentIncluded)) {
    return null;
  }
  const equipmentArray = equipmentIncluded.map(
    (equipment) => equipment.equipment
  );
  const equipmentList = <List listItems={equipmentArray} />;
  return {
    title: "Equipment",
    content: equipmentList,
    key: "equipmentTab",
  };
};
