// @ts-check
import React from "react";

import { compact, flattenDeep, isNil, upperFirst } from "lodash";

import { List } from "../../viewComponents/list";

/**
 * Creates a List of the property information.
 *
 * @param {object} property
 */
export const getInfoAndDistances = (property) => {
  const {
    propertyType,
    bedrooms,
    beds,
    coordinates,
    distances,
    petsAllowed,
  } = property;

  // Create general information about the property
  let informationArray = [
    propertyType ? upperFirst(propertyType) : null,
    bedrooms ? `${bedrooms} Bedrooms` : null,
    beds.adults ? `${beds.adults} Adults` : null,
    beds.children ? `${beds.children} Children` : null,
    petsAllowed ? "Pets allowed" : null,
  ];

  // Add distance details to the list
  if (!coordinates && !isNil(distances)) {
    const distanceItems = distances.map((distance) => {
      return distance;
    });
    informationArray = [...informationArray, ...distanceItems];
  }

  // Render the information as a list
  const informationArrayCleaned = flattenDeep(compact(informationArray));
  const informationList = <List listItems={informationArrayCleaned} />;
  return {
    title: "Information",
    content: informationList,
    key: "infoAndDistances",
  };
};
