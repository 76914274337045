import React from "react";

import PropTypes from "prop-types";
import { Radio } from "semantic-ui-react";

/**
 * Cell for the ReactTable to display in the availability column. Renders a
 * RadioToggle element which enables the user to the the availability of the given stay.
 */
export const StayAvailableRadio = ({ available, onChange }) => {
  const onAvailableChange = (e, { checked }) => {
    onChange({ available: checked });
  };

  return <Radio toggle onChange={onAvailableChange} checked={available} />;
};

//
// Prop Types
//

StayAvailableRadio.propTypes = {
  onChange: PropTypes.func.isRequired,
  available: PropTypes.bool,
};
