import React from "react";

import { GeneralFloorplansServicesAndLocation } from "./GeneralFloorplansServicesAndLocation";

export const getGeneralFloorplansServicesAndLocationTab = () => {
  return {
    title: "General",
    content: <GeneralFloorplansServicesAndLocation />,
    key: "general",
  };
};
