import React from "react";

import { Container } from "semantic-ui-react";

import { PropertyGrid } from "../PropertyGrid";

export const MyPropertiesPage = () => {
  return (
    <Container>
      <PropertyGrid />
    </Container>
  );
};
