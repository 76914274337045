// @ts-check
import React from "react";

import { isEmpty } from "lodash";

import { List } from "../../viewComponents/list";

/**
 * Creates a list of facilities the property has.
 *
 * @param {Object} property - The property to show the facilities of.
 */
export const getFacilities = (property) => {
  const { facilities } = property;
  if (isEmpty(facilities)) {
    return null;
  }
  const facilitiesArray = facilities.map((facility) => facility.facility);
  const facilitiesList = <List listItems={facilitiesArray} />;
  return {
    title: "Facilities",
    content: facilitiesList,
    key: "facilitiesTab",
  };
};
