import React from "react";

import PropTypes from "prop-types";
import { Container } from "semantic-ui-react";

import {
  DefaultTooltipIcon,
  Tooltip,
} from "../../../../viewComponents/tooltip";
import { CancellationTermRowView } from "./CancellationTermRowView";

const TOOLTIP = (
  <Container text>
    <p>Please enter the cancellation deadlines and possible refunds here.</p>
    <p>
      <span style={{ fontStyle: "italic" }}>Example:</span> Assume that your
      cancellation policy allows the customer to cancel their booking up to 12
      weeks before check in with a refund ratio of 75%. If they cancel up to 10
      days prior to the check in the refund is only 25%. If they cancel even
      later then there is no refund possible.
      <br />
      In this case you fill in 12 * 7 = 84 days and 75% into the first row. The
      second row gets 10 days with a refund of 25%. You do not have to fill in a
      time intervall where the refund is zero. Every time interval that does not
      have a dedicated refund information means zero refund for the customer by
      default. This also means that the last row will always be empty in this
      form.
    </p>
  </Container>
);

export const CancellationTermsInputView = ({
  cancellationTermsToRender = [],
  onChange,
}) => (
  <>
    <h3>
      Cancellation Terms{" "}
      <Tooltip tooltip={TOOLTIP}>
        <DefaultTooltipIcon />
      </Tooltip>{" "}
    </h3>
    {cancellationTermsToRender.map((term, index) => (
      <CancellationTermRowView
        cancellationTerm={term}
        index={index}
        onChange={onChange}
        key={index}
      />
    ))}
  </>
);

//
// Prop Types
//

CancellationTermsInputView.propTypes = {
  onChange: PropTypes.func.isRequired,
  cancellationTermsToRender: PropTypes.array.isRequired,
};
