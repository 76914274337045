import React from "react";

import PropTypes from "prop-types";

import { PaymentTermsInputView } from "./PaymentTermsInputView";

/**
 * Renders input fields to edit the payment terms of the operator.
 *
 * @augments {React.PureComponent<Props, State>}
 */
export class PaymentTermsInput extends React.PureComponent {
  /**
   * Takes the edited payment term and forwards the change to the parent.
   * Deletes the term from the list if null.
   *
   * @param {number} index - The index of the payment term to edit.
   * @param {object} newPaymentTerm - The new state of the payment term.
   */
  handleChange = (index, newPaymentTerm) => {
    const paymentTermsBefore = this.props.paymentTerms || [];
    let newPaymentTerms = [...paymentTermsBefore];

    // Apply change to newPayment terms array
    newPaymentTerms[index] = newPaymentTerm;

    // Remove non-complete payment term items
    newPaymentTerms = newPaymentTerms.filter(
      (term) => term && (term.daysBeforeCheckIn || term.paymentRatio)
    );

    this.props.onChange({ paymentTerms: newPaymentTerms });
  };

  render() {
    const inputPaymentTerms = this.props.paymentTerms || [];
    const lastPaymentTerm = inputPaymentTerms[inputPaymentTerms.length - 1];

    // Determine whether to append another input row
    // If the last row already represents the final payment, there is no need to add another one
    const isFullyDefined =
      lastPaymentTerm &&
      lastPaymentTerm.paymentRatio &&
      lastPaymentTerm.paymentRatio.toString() === "100";

    const paymentTermsToRender = [...inputPaymentTerms];

    // Append a row if useful
    if (!isFullyDefined) {
      paymentTermsToRender.push({ daysBeforeCheckIn: "", paymentRatio: "" });
    }

    return (
      <PaymentTermsInputView
        paymentTermsToRender={paymentTermsToRender}
        onChange={this.handleChange}
      />
    );
  }
}

//
// Prop Types
//

PaymentTermsInput.propTypes = {
  paymentTerms: PropTypes.arrayOf(
    PropTypes.shape({
      daysBeforeCheckIn: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      paymentRatio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  onChange: PropTypes.func.isRequired,
};
