import React, { useState } from "react";

import { isEmpty, lowerCase, upperFirst } from "lodash";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { createInputChangeEventToGroupUpdateHandler } from "../../../helpers/forms";
import { DefaultTooltipIcon, Tooltip } from "../../../viewComponents/tooltip";
import { GenericContactFormGroup } from "./GenericContactFormGroup";

/**
 * Renders a `GenericContactFormGroup` but with a checkbox which indicates
 * whether this contact differs from the reservation contact (which we consider
 * the main contact).
 *
 * @example
 *   const AccountingContactForm = ({ values, onChange }) => (
 *     <NonReservationContactForm
 *       onChange={onChange}
 *       values={values}
 *       label="Accounting contact"
 *       name="accountingContact"
 *     />
 *   );
 */
export const NonReservationContactForm = ({
  values = {},
  error = {},
  onChange,
  name,
  label,
  tooltip,
}) => {
  const [
    isDifferentFormReservationContact,
    setIsDifferentFromReservationContact,
  ] = useState(!isEmpty(values));

  const groupChangeHandler = createInputChangeEventToGroupUpdateHandler(
    onChange,
    name,
    values
  );

  const onDifferentContactCheckboxClick = () => {
    if (!isDifferentFormReservationContact) {
      setIsDifferentFromReservationContact(true);
      return;
    }

    onChange({ [name]: undefined });
    setIsDifferentFromReservationContact(false);
  };

  return (
    <>
      <h3>
        {label}{" "}
        <Tooltip tooltip={tooltip}>
          <DefaultTooltipIcon />
        </Tooltip>
      </h3>
      <Form.Checkbox
        label={`${upperFirst(
          lowerCase(label)
        )} differs from reservation contact`}
        checked={isDifferentFormReservationContact}
        onChange={onDifferentContactCheckboxClick}
      />
      <GenericContactFormGroup
        values={values}
        onChange={groupChangeHandler}
        disabled={!isDifferentFormReservationContact}
        error={error}
      />
    </>
  );
};

//
// Prop Types
//

NonReservationContactForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.node.isRequired,
  values: PropTypes.object,
  error: PropTypes.object,
};
