import React from "react";

import PropTypes from "prop-types";
import { Button, Container, Sidebar } from "semantic-ui-react";

import { RouterSwitch } from "./RouterSwitch";

import "./style.css";

/** Renders a Semantic UI Sidebar which shows help depending on the current url path. */
export const HelpSidebar = ({ isVisible, onHide }) => {
  return (
    <Sidebar
      visible={isVisible}
      animation="overlay"
      direction="right"
      className="help"
    >
      <div className="closeButtonContainer">
        <Button icon="close" basic onClick={onHide} />
      </div>

      <Container text fluid>
        <RouterSwitch />
      </Container>
    </Sidebar>
  );
};

//
// Prop Types
//

HelpSidebar.propTypes = {
  onHide: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
};
