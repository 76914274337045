import { useContext } from "react";

import { MyLeadsContext } from "../MyLeadsContext/MyLeadsContext";
import { OperatorContext } from "../OperatorContext/OperatorContext";
import { PropertiesContext } from "../PropertiesContext/PropertiesContext";
import { AuthenticationContext } from "./AuthenticationContext";

/**
 * Returns a function which logs out the user when called. Additionally it
 * resets the other contexts like the `PropertiesContext` or the `MyLeadsContext`.
 */
export const useLogoutUserAndCleanUp = () => {
  const { logoutUser } = useContext(AuthenticationContext);
  const { resetLeads } = useContext(MyLeadsContext);
  const { resetOperator } = useContext(OperatorContext);
  const { resetProperties } = useContext(PropertiesContext);

  const logoutUserAndCleanUp = async () => {
    await logoutUser();
    resetLeads();
    resetOperator();
    resetProperties();
  };

  return logoutUserAndCleanUp;
};
