import { useContext } from "react";

import { OperatorContext } from "./OperatorContext";

/**
 * @callback PatchOperator
 * @param {any} newOperatorState
 */

/** @callback FetchOperator */

/** @callback ResetOperator */

/**
 * @callback AdminSetsOperatorId
 * @param {string} operatorId
 */

/**
 * @callback PatchChannel
 * @param {any} patch
 */

/**
 * @returns {{
 *   operator: import("../../types/Operator").Operator;
 *   hasFetchedOperator: boolean;
 *   isFetchingOperator: boolean;
 *   hasOperatorUpdateFailed: boolean;
 *   patchOperator: PatchOperator;
 *   fetchOperator: FetchOperator;
 *   resetOperator: ResetOperator;
 *   adminSetsOperatorId: AdminSetsOperatorId;
 *   channels: any[];
 *   patchChannel: PatchChannel;
 * }}
 */
export const useOperatorContext = () => useContext(OperatorContext);
