import React from "react";

import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";

import { leadStatus } from "../../../context/MyLeadsContext";
import { ChangeRequestResponseForm } from "./ChangeRequestResponseForm";
import { ConfirmedOptionForm } from "./ConfirmedOptionForm";
import { RequestedOptionResponseForm } from "./RequestedOptionResponseForm";

/**
 * Renders a Semantic UI Modal containing a form allowing the user to act on the
 * option request.
 */
export const OptionModal = ({ option, onClose }) => {
  if (!option) return null;

  const ModalContent = getModalContent(option);

  return (
    <Modal open onClose={onClose} closeIcon id="optionModal">
      <Modal.Header>
        {get(option, "property.propertyDetails.name")} &mdash; Option Request
      </Modal.Header>
      <Modal.Content>
        <ModalContent option={option} onSubmit={onClose} />
      </Modal.Content>
    </Modal>
  );
};

//
// Helpers
//

const getModalContent = (option) => {
  const hasActiveChangeRequests = !isEmpty(
    option.property.activeChangeRequests
  );
  const isConfirmedOption =
    option.property.status === leadStatus.CONFIRMED_OPTION;

  if (hasActiveChangeRequests) {
    return ChangeRequestResponseForm;
  } else if (isConfirmedOption) {
    return ConfirmedOptionForm;
  } else {
    return RequestedOptionResponseForm;
  }
};

//
// Prop Types
//

OptionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  option: PropTypes.object,
};
