import React from "react";

import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { StayAvailableRadio } from "./StayAvailableRadio";
import { StayRatesInput } from "./StayRatesInput";

export const StayFormView = ({ stay, onChange, onSubmit }) => {
  return (
    <Form>
      <Form.Field>
        Available:{" "}
        <StayAvailableRadio available={stay.available} onChange={onChange} />
      </Form.Field>
      <StayRatesInput stay={stay} onChange={onChange} />
      <Form.Button primary onClick={onSubmit}>
        Submit
      </Form.Button>
    </Form>
  );
};

//
// Prop Types
//

StayFormView.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  stay: PropTypes.shape({
    available: PropTypes.bool,
    rates: PropTypes.object,
  }).isRequired,
};
