import React, { useContext } from "react";

import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { Dropdown, Image } from "semantic-ui-react";

import { PropertiesContext } from "../../context/PropertiesContext";

/**
 * Renders a single item within the MyPropertiesDropdown consisting of a
 * property image and its name.
 */
const PropertyItem = ({ property = {} }) => (
  <Dropdown.Item as={Link} to={"/myProperties/" + property._id}>
    <Image rounded size="huge" src={property.thumbnail} />

    {property.name}
  </Dropdown.Item>
);

/**
 * Renders a menu item that should be used in the top menu bar. On hover, it
 * opens a dropdown displaying the editable properties.
 */
export const MyPropertiesDropdown = () => {
  const { properties: keyedProperties } = useContext(PropertiesContext);
  const propertyArray = Object.values(keyedProperties);

  return (
    <>
      <NavLink
        className="ui item simple dropdown myProperties"
        to="/myProperties"
      >
        My Properties
        <Dropdown.Menu>
          {propertyArray.map((p) => (
            <PropertyItem property={p} key={p._id} />
          ))}
        </Dropdown.Menu>
      </NavLink>
    </>
  );
};

//
// Prop Types
//
PropertyItem.propTypes = {
  property: PropTypes.shape({
    name: PropTypes.string,
    thumbnail: PropTypes.string,
  }).isRequired,
};
