import { leadStatus } from "../../context/MyLeadsContext";
import { toDateOnlyString } from "../../helpers";

/** Returns a string like 'Tomorrow 05:24 AM' */
export const OptionExpiresCell = ({ option }) => {
  const dateString = option.property.optionExpires;
  if (!dateString) {
    return "";
  }
  if (
    ![leadStatus.REQUESTED_OPTION, leadStatus.CONFIRMED_OPTION].includes(
      option.property.status
    )
  ) {
    return "-";
  }

  const expireDate = new Date(dateString);
  const todayDate = new Date();

  let dayString;
  if (expireDate.getDate() === todayDate.getDate()) {
    dayString = "Today";
  } else if (expireDate.getDate() === todayDate.getDate() + 1) {
    dayString = "Tomorrow";
  } else {
    dayString = toDateOnlyString(dateString);
  }

  const timeString = expireDate.toLocaleTimeString();

  return dayString + " " + timeString;
};
