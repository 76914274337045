import React from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";

import { List } from "../../../viewComponents/list";
import { InformationSegment } from "../InformationSegment";

import "./Services.css";

/**
 * Renders the given ServicesIncluded with dropbox to choose the service level.
 * The default serviceLevel is determined by the last search filter.
 */
class Services extends React.Component {
  constructor(props) {
    super(props);

    const options = this.makeOptions();
    const selectedServiceLevel = this.makeDefaultSelectedServiceLevel(options);
    this.state = {
      options,
      selectedServiceLevel,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.servicesIncluded !== this.props.servicesIncluded) {
      const options = this.makeOptions();
      const selectedServiceLevel = this.makeDefaultSelectedServiceLevel(
        options
      );
      this.setState({ options, selectedServiceLevel });
    }
  }

  /**
   * Converts the provided ServicesIncluded into an options array, that can be
   * used by the dropdown.
   */
  makeOptions = () => {
    const { servicesIncluded = {} } = this.props;
    const serviceLevels = Object.keys(servicesIncluded);
    return serviceLevels.map((level) => ({
      key: level,
      text: level,
      value: level,
    }));
  };

  /**
   * Returns a serviceLevel from the options array. It is either the
   * serviceLevel from the searchFilter, the first value in the options array,
   * or a blank string.
   *
   * @param {Array} options - The options for the dropdown.
   */
  makeDefaultSelectedServiceLevel = (options = []) => {
    if (!isEmpty(options)) {
      return options[0].value;
    }

    return "";
  };

  serviceLevelChangeHandler = (_e, { value }) => {
    this.setState({ selectedServiceLevel: value });
  };

  render() {
    const { servicesIncluded } = this.props;

    if (isEmpty(servicesIncluded)) return null;

    const serviceObjects =
      servicesIncluded[this.state.selectedServiceLevel] || [];
    const services = serviceObjects.map((obj) => obj.service);

    return (
      <>
        <h3 style={{ display: "inline-block" }}>Services Included</h3>
        {this.state.options.length > 1 && (
          <Dropdown
            className="serviceLevelDropdown"
            selection
            options={this.state.options}
            value={this.state.selectedServiceLevel}
            onChange={this.serviceLevelChangeHandler}
          />
        )}

        <div className="services container">
          <InformationSegment title={this.state.selectedServiceLevel}>
            <List listItems={services} />
          </InformationSegment>
        </div>
      </>
    );
  }
}

export default Services;

//
// Prop Types
//

Services.propTypes = {
  servicesIncluded: PropTypes.object,
};
