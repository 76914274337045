import { useContext } from "react";

import { MyLeadsContext } from "./MyLeadsContext";

/**
 * Fetch the leads again and set the context state accordingly
 *
 * @callback FetchLeads
 */

/**
 * Reset the context state to the Initial State
 *
 * @callback ResetLeads
 */

/**
 * @returns {{
 *   leads: any[];
 *   hasFetchedLeads: boolean;
 *   isFetchingLeads: boolean;
 *   operatorId: string;
 *   fetchLeads: FetchLeads;
 *   resetLeads: ResetLeads;
 * }}
 */
export const useMyLeadsContext = () => useContext(MyLeadsContext);
