import React from "react";

import ReactDOM from "react-dom";

import { App } from "./app";

ReactDOM.render(<App />, document.querySelector("#root"));

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/service-worker.js");
}
