import React, { useState } from "react";

import { Label, Dimmer } from "semantic-ui-react";

import { isInStandaloneMode, isIPad, isIPhone } from "./helpers";

import "./ApplePwaHint.css";

export const ApplePwaHint = () => {
  const isHintShownOnStart = !isInStandaloneMode() && (isIPad() || isIPhone());

  const [isHintVisible, setIsHintVisible] = useState(isHintShownOnStart);

  if (!isHintVisible) return null;

  return (
    <>
      <Dimmer active page onClickOutside={() => setIsHintVisible(false)}>
        <Label
          pointing={isIPad() ? "above" : "below"}
          id={`applePwaHint${isIPad() ? "IPad" : "IPhone"}`}
          className="applePwaHint"
          basic
        >
          Install this webapp on your {isIPad() ? "iPad" : "iPhone"}: Tap
          <img src="/images/share-icon.png" alt="share icon" /> and then
          &quot;Add to Homescreen&quot;
        </Label>
      </Dimmer>
    </>
  );
};
