import { isNil, toNumber, isFinite, isNumber, isString } from "lodash";

/**
 * Takes a numberish input and formats it as a string with separators for
 * better readability.
 *
 * @param {string | number} input - The input to add separators to.
 * @returns {null | string}
 */
export const addSeparator = (input) => {
  if (isNil(input)) {
    return null;
  }

  // Convert input into number
  let inputAsNumber;
  if (isNumber(input) && isFinite(input)) {
    inputAsNumber = input;
  } else if (isString(input)) {
    inputAsNumber = toNumber(input.replace(/ /g, "").replace(",", "."));
  } else {
    return null;
  }

  // Allow valid numbers only
  if (!isFinite(inputAsNumber)) return null;

  // Format result
  const formatter = new Intl.NumberFormat("en");
  let resultString = formatter.format(inputAsNumber);
  resultString = resultString.replace(/,/g, " ").replace(".", ",");

  return resultString;
};
