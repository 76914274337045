import React from "react";

import PropTypes from "prop-types";
import { Divider, Form } from "semantic-ui-react";

import { createInputChangeEventToUpdateHandler } from "../../../helpers/forms";
import { CancellationTermsInput } from "./CancellationTermsInput";
import { PaymentMethodsFormGroup } from "./PaymentMethodsFormGroup";
import { PaymentTermsInput } from "./PaymentTermsInput";
import { PrepaymentRatioInput } from "./PrepaymentRatioInput";
import { SecurityDepositTypeInput } from "./SecurityDepositTypeInput";

/**
 * Renders a form containing
 *
 * - Credit card fee in %
 * - Security deposit type
 * - B2c default commission
 * - B2b default commission
 */
export const RentalInformationForm = ({
  values = {},
  onChange,
  error = {},
}) => {
  const changeHandler = createInputChangeEventToUpdateHandler(onChange);

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Input
          label="Credit Card Fee in %"
          type="number"
          value={values.creditCardFeeRatio || ""}
          onChange={changeHandler}
          name="creditCardFeeRatio"
          min={0}
          max={10}
          error={error.creditCardFeeRatio}
        />
        <SecurityDepositTypeInput
          value={values.securityDepositType}
          error={error.securityDepositType}
          onChange={onChange}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          label="B2C Default Commission in %"
          value={values.commission || ""}
          onChange={changeHandler}
          name="commission"
          type="number"
          error={error.commission}
        />
        <Form.Input
          label="B2B Default Commission in %"
          value={values.commissionB2b || ""}
          onChange={changeHandler}
          name="commissionB2b"
          type="number"
          min={values.commission}
          error={error.commissionB2b}
        />
      </Form.Group>
      <PaymentMethodsFormGroup onChange={onChange} values={values} />
      <Form.Group widths="2">
        <PrepaymentRatioInput
          value={values.prepaymentRatio}
          onChange={onChange}
          error={error.prepaymentRatio}
        />
      </Form.Group>

      <Divider section />

      <PaymentTermsInput
        onChange={onChange}
        paymentTerms={values.paymentTerms}
      />
      <Divider section />

      <CancellationTermsInput
        onChange={onChange}
        cancellationTerms={values.cancellationTerms}
      />
    </Form>
  );
};

//
// Prop Types
//

RentalInformationForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object,
  error: PropTypes.object,
};
